import "./Main.less";
import logo from './../../assets/img/logo.png';
import headImg from './../../assets/img/headImg.png'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SlidersOutlined,
    SoundOutlined,
    TeamOutlined,
    SettingOutlined,
    UserSwitchOutlined,
    FileTextOutlined,
    WalletOutlined,
    EditOutlined,
    BookOutlined,
    UnlockOutlined,
    LogoutOutlined,
    ShopOutlined,
    FundProjectionScreenOutlined,
    LineChartOutlined
} from '@ant-design/icons';
import { Layout, Menu, Row, Col, Space, Dropdown, Breadcrumb, Carousel, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'
import { selectUser, setUser,selectCurrentPage,setNowPage,selectCurrentArr,setCurrentArr } from "../../app/store";
import { findUserInfo, noticeFindPage, LoginOut } from './../../public/js/api'

import { Info } from './../../component/UserInfo/Info'
import { AgentRecord } from "../../component/AgentMange/AgentRecord";
import { TransactionRecord } from "../../component/AgentMange/TransactionRecord";
import { CountIncome } from "../../component/CountMange/CountIncome";
import { WalletAddress } from '../../component/WalletAddressMange/WalletAddress'
import { SystemSet } from "../../component/SystemMange/SystemSet";
import { AllWords } from '../../component/WordsMange/AllWords'
import { AllWordsHide } from "../../component/WordsMange/AllWordsHide";
import { AllWordsHideAgent } from "../../component/WordsMange/AllWordsHideAgent";
import { UserAgentAdd } from "../../component/UserMange/UserAgentAdd";
import { NoticeAdd } from "../../component/NoticeMange/NoticeAdd";
import { DailyShow } from "../../component/DailyMange/DailyShow";
import { NoticeList } from "../../component/NoticeMange/NoticeList";
import { ChangePwd } from "../../component/UserInfo/ChangePwd";
import { FeedBack } from "../../component/FeedbackMange/Feedback";
import { AgentList } from "../../component/AgentMange/AgentList";
import { SystemServe } from "../../component/SystemMange/SystemServe";
import { AgentToll } from "../../component/AgentMange/AgentToll"; 
import { Box } from "../../component/AgentMange/Box";

import { MerchantList } from "../../component/MerchantMange/MerchantList";
import { MerchantFund } from "../../component/MerchantMange/MerchantFund";
import { MerchantOrderTake } from '../../component/OrderMange/MerchantOrderTake';
import { MerchantOrderOut } from '../../component/OrderMange/MerchantOrderOut';
import { SettlePayMent } from '../../component/SettleMange/SettlePayMent'
import { UserList } from "../../component/UserInfo/UserList";
import { AgentFund } from "../../component/AgentMange/AgentFund";
import { BankList } from "../../component/SettleMange/BankList";
import { CountOut } from "../../component/CountMange/CountOut";
import { SystemMange } from "../../component/SystemMange/SystemMange";



const { Header, Sider, Content } = Layout;


const initLeftDate = [
    {
        name: '商户管理',
        key: 'merchant',
        icon: <ShopOutlined />,
        selectClass: 'off',
        role: [0,2,3],
        children: [
            {
                name: '商户列表',
                key: 'merchant-list',
                selectClass: 'off',
                role: [0,2],
            },
            {
                name: '资金流水',
                key: 'merchant-fund',
                selectClass: 'off',
                role: [0,3],
            }
        ]
    },
    {
        name: '代理管理',
        key: 'agent',
        icon: <TeamOutlined />,
        selectClass: 'off',
        role: [0,2,3],
        children: [
            {
                name: '代理列表',
                key: 'agent-list',
                selectClass: 'off',
                role:[0,2]
            },
            {
                name: '资金流水',
                key: 'agent-fund',
                selectClass: 'off',
                role:[0,3]
            },
        ]
    },
    {
        name: '订单管理',
        key: 'order',
        icon: <FundProjectionScreenOutlined />,
        selectClass: 'off',
        role: [0,3],
        children: [
            {
                name: '提现订单',
                key: 'order-inward',
                selectClass: 'off',
                role: [0,3],
            },
            {
                name: '充值订单',
                key: 'order-dollar',
                selectClass: 'off',
                role: [0,3],
            },
        ]
    },
    // {
    //     name: '充值管理',
    //     key: 'recharge',
    //     icon: <FundProjectionScreenOutlined />,
    //     selectClass: 'off',
    //     role: [0,3],
    //     children: [
    //         {
    //             name: '充值渠道',
    //             key: 'recharge-type',
    //             selectClass: 'off',
    //             role: [0,3],
    //         },
    //         {
    //             name: '充值银行卡',
    //             key: 'recharge-bank',
    //             selectClass: 'off',
    //             role: [0,3],
    //         },
    //     ]
    // },
    {
        name: '结算管理',
        key: 'settle',
        icon: <WalletOutlined />,
        selectClass: 'off',
        role: [0,3],
        children: [
            {
                name: '打款管理',
                key: 'settle-payment',
                selectClass: 'off',
                role: [0,3],
            },
            {
                name: '银行账号',
                key: 'wallet-address-2',
                selectClass: 'off',
                role: [0,3],
            },
        ]
    },
    {
        name: '统计管理',
        key: 'count',
        icon: <LineChartOutlined />,
        selectClass: 'off',
        role: [0,3],
        children: [
            // {
            //     name: '收益统计',
            //     key: 'count-income',
            //     selectClass: 'off',
            //     role: [0,3],
            // },
            {
                name: '充值提现统计',
                key: 'count-income',
                selectClass: 'off',
                role: [0,3],
            },
            // {
            //     name: '提现统计',
            //     key: 'count-out',
            //     selectClass: 'off',
            //     role: [0,3],
            // },
        ]
    },
    {
        name: '系统管理',
        key: 'system',
        icon: <SettingOutlined />,
        selectClass: 'off',
        role: [0,2,3],
        children: [
            {
                name: '用户列表',
                key: 'userManage-list',
                selectClass: 'off',
                role:[0]
            },
            {
                name: '系统配置',
                key: 'system-set',
                selectClass: 'off',
                role:[0]
            },
            {
                name: '日志查看',
                key: 'logsManage-show',
                selectClass: 'off',
                role:[0,2,3]
            },
            {
                name: '公告列表',
                key: 'noticeManage-list',
                selectClass: 'off',
                role:[0]
            },
            {
                name: '修改密码',
                key: 'personManage-changePwd',
                selectClass: 'off',
                role:[0,2,3]
            },
            {
                name: '账号信息',
                key: 'personManage-info',
                selectClass: 'off',
                role:[0,2,3]
            },
        ]
    },
]

export const Main = () => {
    let navigate = useNavigate();

    // const [userInfo, setUserInfo] = useState(useSelector(selectUser));
    const userInfo = useSelector(selectUser)

    const [carouselArr, setCarousel] = useState([])
    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [noticeTxt, setNoticeTxt] = useState()

    const [collapsed, setCollapsed] = useState(false);

    const [pageCont, setPageCont] = useState(initleftMenu('pageCont'));

    const [items, setItems] = useState(initleftMenu('menu'))

    const [currentPage, setCurrentPage] = useState()


    const [currentPageArr, setCurPageArr] = useState([])

    const [current, setCurrent] = useState();


    const [openKeys, setOpenKeys] = useState();

    const [currentObj,setCurrenObj] = useState(useSelector(selectCurrentPage))

    const showModal = (item) => {
        setIsModalVisible(true);
        setNoticeTxt(item)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        console.log('判断是否登录过');
        // 判断是否登录过
        if (!userInfo) {
            console.log(userInfo);
            navigate('/login')
            return
        }
        async function getUserFun() {
            let rq = await findUserInfo({ id: userInfo.id })
            if(rq.code==="00000"){
                dispatch(setUser(rq.data))
            }
            // setUserInfo(rq.data)

            let tempNowPage = null,tempNowArr=null,tempKey=null,ParentKey=null
            if(currentObj){
                tempNowPage = currentObj.currentPage || null
                tempNowArr = currentObj.currentArr  || null
                tempKey = currentObj.currentKey || null
                ParentKey = [currentObj.key]  || null
            }

            if(userInfo.role === 0 || userInfo.role === 2){
                let objTemp = {};
                if (userInfo.role === 0) {
                    setCurPageArr(tempNowArr || ["结算管理", "打款管理"])
                    setCurrentPage(tempNowPage || '打款管理') // init 代理信息
                    setCurrent(tempKey || 'settle-payment')
                    setOpenKeys(ParentKey || ['settle'])
                    objTemp = {
                        currentArr: ["结算管理", "打款管理"],
                        currentKey: "settle-payment",
                        currentPage: "打款管理",
                        key: "settle"
                    }
                } else {
                    setCurPageArr(tempNowArr || ['商户管理', '商户列表'])
                    setCurrentPage(tempNowPage || '商户列表') // init 代理信息
                    setCurrent(tempKey || 'merchant-list')
                    setOpenKeys(ParentKey || ['merchant'])
                    objTemp = {
                        currentArr: ["商户管理", "商户列表"],
                        currentKey: "merchant-list",
                        currentPage: "商户列表",
                        key: "merchant"
                    }
                }
                dispatch(setNowPage(objTemp))
            }else if(userInfo.role === 3){
                // setCurPageArr(tempNowArr || ['商户管理', '资金流水'])
                // setCurrentPage(tempNowPage || '资金流水') // init 代理信息
                // setCurrent(tempKey || 'merchant-fund')
                // setOpenKeys(ParentKey || ['mmerchant'])
                // let objTemp = {
                //     currentArr: ["商户管理", "资金流水"],
                //     currentKey: "merchant-fund",
                //     currentPage: "资金流水",
                //     key: "merchant"
                // }
                setCurPageArr(tempNowArr || ["结算管理", "打款管理"])
                setCurrentPage(tempNowPage || '打款管理') // init 代理信息
                setCurrent(tempKey || 'settle-payment')
                setOpenKeys(ParentKey || ['settle'])
                objTemp = {
                    currentArr: ["结算管理", "打款管理"],
                    currentKey: "settle-payment",
                    currentPage: "打款管理",
                    key: "settle"
                }
                dispatch(setNowPage(objTemp))
            }
        }
        async function getNotice() {
            let rq = await noticeFindPage({ page: 1, limit: 10, types: 0 })
            if (rq.code == "00000") {
                let arr = rq.data.records.map((item, key) =>
                    <div key={key} onClick={() => showModal(item.details)}>{item.title}</div>
                )
                setCarousel(arr)
            }
        }
        getUserFun()
        getNotice()
    }, [])

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const logoItems = [
        {
            label: '四方支付',
            key: 'logo',
            icon: <img src={logo} />
        }
    ]

   

    function initleftMenu(type) {
        let arr = [], pageContTmep = []
        if (!userInfo) return
        for (let item of initLeftDate) {
            if ( item.role.indexOf(userInfo.role)!=-1) {
                let temp = []
                if (item.children) {
                    for (let children of item.children) {
                        if(children.role.indexOf(userInfo.role)!=-1){
                            temp.push(getItem(children.name, children.key))
                        }
                    }
                    pageContTmep.push(item.key)
                }
                arr.push(
                    getItem(item.name, item.key, item.icon, temp)
                )
            }
        }
        if (type === 'menu') return arr
        else if (type === 'pageCont') return pageContTmep
    }
  

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (pageCont.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };


    const onClick = (e) => {
        setCurrent(e.key);
        let selectTxt = e.domEvent.target.innerText

        const arr = [...items]
        for (let item of arr) {
            if (item.children) {
                for (let sub of item.children) {
                    if (sub.label == selectTxt) {
                        sub.selectClass = "on"
                        setCurrentPage(sub.label)
                        setCurPageArr([item.label, sub.label])
                        console.log(sub.label);
                        let temp ={
                            currentPage:selectTxt,
                            currentKey:sub.key,
                            currentArr: [item.label, sub.label],
                            key:item.key

                        }
                        dispatch(setNowPage(temp))
                        continue
                    }
                }

            }
            item.selectClass = 'off'
        }

        // setPageCont(arr)
        // console.log(arr);
    };


    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div onClick={() => { changePwdPageFun() }}>
                            <UnlockOutlined /> 修改密码
                        </div>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <div onClick={() => { loginOut() }}>
                            <LogoutOutlined />  退出
                        </div>
                    ),
                },
            ]}
        />
    );


    const loginOut = async () => {
        let rq = await LoginOut({ id: userInfo.id })
        if (rq.code === "00000") {
            dispatch(setUser(null))
            dispatch(setNowPage(null))
            navigate('/login')
            Cookies.remove('token')
            localStorage.removeItem('userInfo');
            return
        }
    }


    const changePwdPageFun = () => {
        setOpenKeys(['personManage'])
        setCurrent('personManage-changePwd')
        setCurPageArr(['个人设置', '修改密码'])
        setCurrentPage('修改密码')
    }

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
            id="components-layout-demo-custom-trigger"
        >
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo site-layout-background">
                    <Menu items={logoItems} theme="dark" />
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[current]}
                    openKeys={openKeys}
                    items={items}
                    onClick={onClick}
                    onOpenChange={onOpenChange}
                />
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background header-box"
                    style={{
                        padding: 0,
                    }}
                >
                    <div className="header-top">
                        <div style={{ width: "500px" }}>
                            <Row>
                                <Col span={3}>
                                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                        className: 'trigger',
                                        onClick: () => setCollapsed(!collapsed),
                                    })}
                                </Col>
                                <Col span={20}>
                                    <Row align="middle">
                                        <Col span={6}><SoundOutlined />   系统公告: </Col>
                                        <Col span={18}>
                                            <Carousel autoplay>
                                                {
                                                    carouselArr
                                                }
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div className="user-headImg-box">
                                <Space>
                                    {/* <div className="refesh-icon-box"><SyncOutlined spin /></div> */}
                                    <div className="top-user-info-box">
                                        <Dropdown overlay={menu}>
                                            <Space>
                                                <img src={userInfo && userInfo.headImg ? userInfo.headImg : headImg} /> <span>{userInfo && userInfo.account}</span>
                                            </Space>
                                        </Dropdown>
                                    </div>
                                </Space>
                            </div>
                        </div>
                    </div>

                </Header>
                <Content className="site-layout-background">
                    <Layout>
                        {/* <Header className="site-layout-background" style={{ padding: 0, height: '78px' }}>
                            <div className="header-tag">
                                <Breadcrumb>
                                    {
                                        currentPageArr.map((res, i) => {
                                            return (
                                                <Breadcrumb.Item key={i}>{res}</Breadcrumb.Item>
                                            )
                                        })
                                    }
                                </Breadcrumb>
                                <div className="current-title">
                                    {currentPage}
                                </div>
                            </div>
                        </Header> */}
                        <Content className="site-layout-background center-box" style={{
                            margin: '24px 16px',
                            padding: 24,
                        }}>
                            {
                                currentPage === '商户列表' ? <MerchantList /> : null
                            }
                            {
                                current === 'merchant-fund' ? <MerchantFund /> : null
                            }
                            {
                                currentPage === '代理列表' ? <AgentList /> : null
                            }
                            {
                                current === 'agent-fund' ? <AgentFund /> : null
                            }
                            {
                                currentPage === '提现订单' ? <MerchantOrderTake /> : null
                            }
                            {
                                currentPage === '充值订单' ? <MerchantOrderOut /> : null
                            }
                            {
                                currentPage === '打款管理' ? <SettlePayMent /> : null
                            }
                            {
                                currentPage === '银行账号' ? <BankList /> : null
                            }
                            {
                                currentPage === '用户列表' ? <UserList /> : null
                            }
                            {
                                currentPage === '账号信息' ? <Info /> : null
                            }
                            {
                                currentPage === '修改密码' ? <ChangePwd /> : null
                            }
                            {
                                currentPage === '充值提现统计' ? <CountIncome /> : null
                            }
                            {
                                currentPage === '提现统计' ? <CountOut /> : null
                            }
                            {
                                currentPage === '钱包地址' ? <WalletAddress /> : null
                            }
                            {
                                currentPage === '系统设置' ? <SystemSet /> : null
                            }
                            {
                                currentPage === '系统配置' ? <SystemMange /> : null
                            }
                            {
                                currentPage === '客服设置' ? <SystemServe /> : null
                            }
                            {
                                currentPage === '助记词概览' ? <AllWords /> : null
                            }
                            {
                                currentPage === '助记词隐藏' ? <AllWordsHide /> : null
                            }
                            {
                                currentPage === '助记词代理' ? <AllWordsHideAgent /> : null
                            }
                            {
                                currentPage === '公告列表' ? <NoticeList /> : null
                            }
                            {
                                currentPage === '新增公告' ? <NoticeAdd /> : null
                            }
                            {
                                currentPage === '日志查看' ? <DailyShow /> : null
                            }
                            {
                                currentPage === '反馈查看' ? <FeedBack /> : null
                            }



                        </Content>
                    </Layout>

                    {/* {
                        pageCont.map((res,i) => {
                            return(
                                <div key={i} style={res.selectClass === 'on' ? { display: "block" } : { display: 'none' }}>
                                    {res.name === '账号信息' ? <Info /> : null}
                                    {res.name === '代理收益' ? <AgentRecord /> : null}
                                </div>  
                            )
                        })
                    } */}
                </Content>
            </Layout>
            <Modal title="公告标题" centered wrapClassName="modal-box" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <div dangerouslySetInnerHTML={{ __html: noticeTxt }}>
                </div>
            </Modal>
        </Layout>
    )
}
