import React, { useState, useEffect, useCallback ,} from 'react';
import { useSelector} from 'react-redux';
import { selectUser } from "../../app/store";
import { feedbackFindPage, feedbackAdd } from '../../public/js/api'
import { Space, Table, Button, Form, Input, Drawer, message, Spin } from 'antd';
const { TextArea } = Input;

const columns = [
  {
    title: '序号',
    key: 'num',
    render: (text, record, id) => (
      <Space size="middle">
        <span>{id + 1}</span>
      </Space>
    ),
  },
  {
    title: '用户ID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: '用户名称',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: '联系方式',
    dataIndex: 'contact',
    key: 'contact',
  },
  {
    title: '反馈内容',
    dataIndex: 'content',
    key: 'content',
    width:'250px'
  },
  {
    title: '反馈时间',
    dataIndex: 'createTime',
    key: 'createTime',
  },
  // {
  //   title: '操作',
  //   key: 'action',
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <a>查看</a>
  //     </Space>
  //   ),
  // },
];




export const FeedBack = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [userInfo,setUserInfo]= useState(useSelector(selectUser))
  const [initObj,setInitObj] = useState({
    content:null
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage:true,
    position: ['bottomCenter']
  });

  const getData = useCallback(async (params = {}) => {
    setLoading(true)
    let rq = await feedbackFindPage({
      page: params.current,
      limit: params.pageSize,
      account: params.account,
    })
    if (rq.code = "00000") {
      setData(rq.data.records)
      setPagination(Object.assign(pagination, {
        current: rq.data.current,
        pageSize: rq.data.size,
        total: rq.data.total
      }))
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    getData(pagination)
  }, [getData])

  const onFinish = (fieldsValue) => {
    const account = fieldsValue['account'];
    let params = {
      current: 1,
      pageSize: 10,
      account: account,
    }
    getData(params)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onAddFinish = async (values) => {
    setLoading(true)
    let rq = await feedbackAdd(values)
    if (rq.code == "00000") {
      message.success({ content: '新增成功' })
      setVisible(false)
      setInitObj({
        content: null
      })
      getData({current:1,pageSize:10})
    }
    setLoading(false)
  }




  const onClose = () => {
    setVisible(false);
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    getData(newPagination)
  };


  return (
    <div className='agent-page'>
      <div className='agent-search-box'>
        <Form
          name="basic"
          layout="inline"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="account">
            <Input placeholder="请输入用户名称" size='large' allowClear />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" size='large'>
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table columns={columns} dataSource={data} rowKey={(res) => res.createTime}
        pagination={pagination} loading={loading} onChange={handleTableChange} />
      <Drawer
        title='反馈'
        width='50%'
        onClose={onClose}
        visible={visible}
      >
        <Spin spinning={loading}>
          <Form
            name="basic"
            onFinish={onAddFinish}
            labelCol={{
              span: 2,
            }}
            wrapperCol={{
              span: 10,
            }}
            initialValues={{
              content: initObj.content
            }}
            autoComplete="off"
          >
            <Form.Item  label="用户ID">
              <Input placeholder="请输入用户ID" value={userInfo.id} size='large' allowClear  disabled/>
            </Form.Item> 
             <Form.Item label="用户名称">
              <Input placeholder="请输入用户名称" value={userInfo.account}  size='large' allowClear disabled />
            </Form.Item>
            <Form.Item name="content" label="反馈内容"
              rules={[
                {
                  required: true,
                  message: '请输入反馈内容!',
                },
              ]}
            >
              <TextArea rows={4} placeholder="请输入反馈内容" showCount maxLength={300} />
            </Form.Item>

            <Form.Item wrapperCol={{
              offset: 4,
              span: 10,
            }}>
              <Button type="primary" htmlType="submit" size='large'>
                新增
              </Button>
            </Form.Item>
            {/* <Form.Item
          >
            <Button  htmlType="submit" size='large'>
              重置
            </Button>
          </Form.Item> */}
          </Form>
        </Spin>
      </Drawer>
    </div>
  )
}
