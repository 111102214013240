import './systemSet.css'
import React, { useState, useEffect, useImperativeHandle, useCallback } from 'react';

import { FindTraPage, findSysContact, sysContactAdd, sysContactUpdate } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, Drawer, Modal, Radio, message, Row, Col, Divider, Spin } from 'antd';

// formRef = React.createRef();






export const SystemServe = () => {
    const [form] = Form.useForm();
    const [highform] = Form.useForm()
    const [loading, setLoading] = useState(false);

    const [normalType, setNormalType] = useState({
        name: null,
        contact: null,
    })

    const [highType, setHighType] = useState({
        name: null,
        contact: null,
    })



    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await findSysContact({ page: 1, limit: 10 })
        if (rq.code == '00000') {
            let tempArr = rq.data
            for (var item of tempArr) {
                if (item.types === 1) {
                    let temoObj = Object.assign(normalType, item)
                    setNormalType(temoObj)
                    form.setFieldsValue({ name: item.name })
                    form.setFieldsValue({ contact: item.contact })
                } else if (item.types === 2) {
                    setHighType(item)
                    highform.setFieldsValue({ name: item.name })
                    highform.setFieldsValue({ contact: item.contact })
                }
            }
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData()
    }, [getData])



    const onFinishNorFn = (values) => {
        let temoObj = Object.assign(normalType, values)
        if (temoObj) {
            if (temoObj.id > -1) {
                onFinish(temoObj, 'update')
            } else {
                temoObj.types = 1
                onFinish(temoObj, 'add')
            }
        }

    }
    const onFinishHighFn = (values) => {
        let temoObj = Object.assign(highType, values)
        if (temoObj) {
            if (temoObj.id > -1) {
                onFinish(temoObj, 'update')
            } else {
                temoObj.types = 2
                onFinish(temoObj, 'add')
            }
        }

    }

    const onFinish = async (values, flag) => {
        let tempApi = sysContactAdd
        if (flag === 'update') {
            tempApi = sysContactUpdate
        }

        let rq = await tempApi(values)
        if (rq.code === '00000') {
            message.success({ content: "修改成功" })
            // getData({ current: 1 })
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className='agent-page'>
            <div style={{ width: 800 }}>
                <Spin spinning={loading}>
                    <div>
                        <div className='s-text-left s4'><Tag color="#108ee9">账号相关:</Tag></div>
                        <div>
                            <div className='s5'>
                                <Form
                                    name="basic"
                                    layout="inline"
                                    initialValues={{
                                        name: normalType.name,
                                        contact: normalType.contact
                                    }}
                                    form={form}
                                    requiredMark={false}
                                    onFinish={onFinishNorFn}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="联系人"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入联系人!',
                                            },
                                        ]}
                                    >
                                        <Input size='large' placeholder='请输入名称' />
                                    </Form.Item>

                                    <Form.Item
                                        label="联系方式"
                                        name="contact"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入联系方式!',
                                            },
                                        ]}
                                    >
                                        <Input size='large' placeholder='请输入TG账号' />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size='large'>
                                            修改
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div>
                        <div className='s-text-left s4'><Tag color="#108ee9">财务相关:</Tag></div>
                        <div>
                            <Form
                                name="basic"
                                layout="inline"
                                initialValues={{
                                    name: highType.name,
                                    contact: highType.contact
                                }}
                                form={highform}
                                requiredMark={false}
                                onFinish={onFinishHighFn}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="联系人"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入联系人!',
                                        },
                                    ]}
                                >
                                    <Input size='large' placeholder='请输入名称' />
                                </Form.Item>

                                <Form.Item
                                    label="联系方式"
                                    name="contact"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入联系方式!',
                                        },
                                    ]}
                                >
                                    <Input size='large' placeholder='请输入TG账号' />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" size='large'>
                                        修改
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

