import { findAgentInfoPage } from '../public/js/api'
import { Select } from 'antd';
import React, { useState,useEffect, useCallback } from 'react';
const { Option } = Select;

let timeout;
let currentValue;

const fetch = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;

  const fake = async () => {
    let rq = await findAgentInfoPage({ agentName: value })
    if (rq.code == '00000') {
        const data = rq.data.records.map((item) => ({
            value: item.id,
            text: item.name,
        }));
        callback(data);
    }
  };

  timeout = setTimeout(fake, 300);
};

const SearchInput = (props) => {
  const [data, setData] = useState([]);
   
  const valueTemp =  props.value ? `${props.value}` : null

  const [value, setValue] = useState(valueTemp);

  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };

  const getData = useCallback(async (params = {}) => {
    let rq = await findAgentInfoPage({ page:1,limit:50 })
    if (rq.code == '00000') {
      const temp = rq.data.records.filter(el => el.status === 0)
      const data = temp.map((el) => ({
        text: el.name,
        value: el.userId,
      }))
       setData(data)
    }
}, [])

useEffect(() => {
    getData()
}, [getData])

  const handleChange = (newValue,options) => {
    debugger
    setValue(newValue);
    props.onChange(Number(newValue), options ? options.children : null)
  };

  const options = data.map((d) => <Option key={d.value}>{d.text}</Option>);
  return (
    <Select
      showSearch
      value={value}
      allowClear
      placeholder={props.placeholder}
      style={props.style}
      onSearch={handleSearch}
      onChange={handleChange}
    >
      {options}
    </Select>
  );
};

export default SearchInput