import React, { useState, useEffect, useCallback } from 'react';
import { agentDel, payPlatformFindPage, agentAdd, agentUpdateInfo, sysUserReset, 
    findAgentInfoPage, resetFundPassword, resettingGoogleKey, insertAgentSubAccount, 
    findAgentSubAccountPage, changeFundPassword, changePassword
} from '../../public/js/api'

import { Space, Table, Button, Form, Input, Drawer, Select, Switch, 
    message, Radio, Row, Col, Tag, InputNumber, Divider, Checkbox, Modal, Dropdown, Menu } from 'antd';
import md5 from 'md5';
import { EyeInvisibleOutlined, EyeTwoTone, DownOutlined } from '@ant-design/icons';






export const AgentList = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [visible, setVisible] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
        total: 0
    });

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState();
    const [flag, setFlag] = useState(false)

    const [agentId, setAgentId] = useState()

    const [searchObj, setSearchObj] = useState({
        name: null,
    })


    const [RainBowPayIndia, setRainBowPayIndia] = useState(null)
    const [FastPayVietnam2, setFastPayVietnam2] = useState(null)
    const [FastPayVietnam1, setFastPayVietnam1] = useState(null)
    const [FastPayIndia, setFastPayIndia] = useState(null)

    const [GoPayIndia,setGoPayIndia] = useState(null)








    const [collect, setCollect] = useState([])
    const [payment, setPayment] = useState([])

    /// 修改密码
    // 下拉菜单
    const itemsDrop = (record) => {
        return <Menu>
            <Menu.Item key='fundPsd' onClick={() => { 
                setId(record.id)
                setPswModal(true)
            }}>修改登录密码</Menu.Item>
            { 
                record.sysUser.role === 4 ?
                <>
                    <Menu.Item key='psd' onClick={() => { 
                        setId(record.id)
                        setFundPswModal(true)
                    }}>修改资金密码</Menu.Item>
                </> : null
            }
            <Menu.Item key='googleKey' onClick={() => { resetGoogleKey(record?.id) }}>重置谷歌验证码</Menu.Item>
            
        </Menu>
    }
    const [openDropId, setOpenDropId] = useState(undefined);
    const [fundPswModal, setFundPswModal] = useState(false);
    const [pswModal, setPswModal] = useState(false);
    const [fundPsdForm] = Form.useForm();
    const [psdForm] = Form.useForm();
    const onFundPsdFinish = async () => {
        await fundPsdForm.validateFields()
        const values = fundPsdForm.getFieldsValue()
        let params = {
            newPassword: md5(values.newFundPassword),
            userId: currentId
        }
        // params.usedPassword = md5(values.usedFundPassword)
        params.usedPassword = md5(values.newFundPassword)
        const hide = message.loading('密码修改中')
        setFundPswModal(false)
        fundPsdForm.resetFields()
        const rq = await changeFundPassword(params)
        hide()
        if (rq.code == "00000") {
            message.success({ content: '密码修改成功!' })
        } else {
            message.error(rq.msg)
        }
    };
    const onPsdFinish = async () => {
        await psdForm.validateFields()
        const values = psdForm.getFieldsValue()
        let params = {
            newPassword: md5(values.newPassword),
            userId: currentId
        }
        // params.usedPassword = md5(values.usedPassword)
        params.usedPassword = md5(values.newPassword)
        const hide = message.loading('密码修改中')
        setPswModal(false)
        psdForm.resetFields()
        const rq = await changePassword(params)
        hide()
        if (rq.code == "00000") {
            message.success({ content: '密码修改成功!' })
        } else {
            message.error(rq.msg)
        }
    };

    const columns = [
        {
            title: '代理ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: '代理名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'USDT余额',
            dataIndex: 'indiaBalance',
            key: 'indiaBalance',
        },
        {
            title: '人民币余额',
            dataIndex: 'vietnamBalance',
            key: 'vietnamBalance',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '是否绑定谷歌',
            dataIndex: 'googleKey',
            key: 'googleKey',
            render: (text, record, index) => (
                <span>{record.sysUser.googleKey ? '是' : '否'}</span>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                <Space size="middle">
                    <Switch checked={record.status === 0 ? true : false} onChange={() => changeStatesFun(record, record.status)} />
                </Space>
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Space size="middle">
                    <a onClick={() => editFun(record)}>编辑</a>
                    <a onClick={() => {
                        setId(record.id)
                        setIsModalOpen(true)
                    }}>删除</a>
                    <a onClick={() => resetFun(record.userId)}>重置登录密码</a>
                    {/* <a onClick={() => resetFunPwd(record.userId)}>重置资金密码</a>
                    <a onClick={() => resetGoogleKey(record.userId)}>重置谷歌验证码</a> */}
                    
                    {/* <Dropdown
                        overlay={() => {
                            return itemsDrop(record)
                        }}
                        onOpenChange={(flag) => {
                            flag ? setOpenDropId(record.userId) : setOpenDropId(undefined)
                        }}
                        open={openDropId == record.userId}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                权限
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown> */}
                    <a onClick={() => {
                        setId(record.userId)
                        agentForm.setFieldsValue({
                            account: null,
                            password: null,
                            contact: null,
                            name: null,
                            currencyRatePayment: null,
                            currencyRateCollection: null,
                            singleTransactionMaxWithd: 0,
                            singleTransactionMinWithd: 0,
                            singleTransactionDayWithd: 0,
                            withdrawalExpense: 0,
                            commission: 0
                        })
                        setTitle('新增')
                        setAgentVisible(true)
                    }}>新增后台用户</a>
                    <a onClick={async () => {
                        setChildAgentId(record.userId)
                        await getAgentChildData(record.userId)
                        setAgentModal(true)
                    }}>查看后台用户列表</a>
                </Space>
            ),
        },
    ];


    const showDrawer = async () => {
        await payFn()
        form.setFieldsValue({
            account: null,
            password: null,
            contact: null,
            name: null,
            currencyRatePayment: null,
            currencyRateCollection: null,
            singleTransactionMaxWithd: 0,
            singleTransactionMinWithd: 0,
            singleTransactionDayWithd: 0,
            withdrawalExpense: 0,
            commission: 0
        })
        setCollect([])
        setPayment([])
        setTitle('新增')
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };




    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await findAgentInfoPage({
            page: params.current,
            limit: params.pageSize,
            agentName: params.name,
            status: params.status,
        })
        if (rq.code === "00000") {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                total: rq.data.total,
                pageSize: rq.data.size
            }))
        }
        setLoading(false)
    }, [])

    const [childAgentId, setChildAgentId] = useState()
    const [agentData, setAgentData] = useState([])
    const [pagination1, setPagination1] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
        total: 0
    });
    const [agentForm] = Form.useForm();
    const [agentVisible, setAgentVisible] = useState(false);
    const onAgentClose = () => {
        setAgentVisible(false);
    };
    const handleTableChange1 = (newPagination, filters, sorter) => {
        let temp = Object.assign(newPagination, searchObj)
        // getAgentChildData(temp)
    };
    /// 获取子代理列表
    const getAgentChildData = async (valueId) => {
        let params = {
            page: pagination1.current,
            limit: pagination1.pageSize,
            agentName: pagination1.name,
            status: pagination1.status,
            agentId: valueId ?? childAgentId,
        }
        console.log(params);
        setLoading(true)
        let res = await findAgentSubAccountPage(params)
        setLoading(false)
        if (res.code === "00000") {
            setAgentData(res.data.records)
            setPagination1(Object.assign(pagination1, {
                current: res.data.current,
                total: res.data.total,
                pageSize: res.data.size
            }))
        }
    }
    const columns1 = [
        {
            title: '代理ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: '代理名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'USDT余额',
            dataIndex: 'indiaBalance',
            key: 'indiaBalance',
        },
        {
            title: '人民币余额',
            dataIndex: 'vietnamBalance',
            key: 'vietnamBalance',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '是否绑定谷歌',
            dataIndex: 'googleKey',
            key: 'googleKey',
            render: (text, record, index) => (
                <span>{record.sysUser.googleKey ? '是' : '否'}</span>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                <Space size="middle">
                    <Switch checked={record.status === 0 ? true : false} onChange={() => changeStatesFun(record, record.status, true)} />
                </Space>
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Space size="middle">
                    <a onClick={() => editAgentFun(record)}>编辑</a>
                    <a onClick={() => {
                        setId(record.id)
                        setIsModalOpen(true)
                    }}>删除</a>
                    <a onClick={() => resetFun(record.userId)}>重置登录密码</a>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getData(pagination)
    }, [getData])


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    const delFun = async (id) => {
        setLoading(true)
        let rq = await agentDel({ id: id })
        if (rq.code == "00000") {
            if (!agentModal) {
                getData({ current: 1, pageSize: 10 })
            } else {
                getAgentChildData()
            }
            message.success('删除成功')
        } else {
            message.error(rq.msg ?? '删除失败')
        }
    }

    const resetFun = async (id) => {
        setLoading(true)
        let rq = await sysUserReset({ id: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '密码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }
    const insertAgentSubAccountFn = async (values) => {
        values.parentUserId = currentId
        setLoading(true)
        let rq = await insertAgentSubAccount(values, {
            content: '操作: 新增后台用户'
        })
        setLoading(false)
        return rq
    }
    const resetFunPwd = async (id) => {
        setLoading(true)
        let rq = await resetFundPassword({ id: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '密码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }
    const resetGoogleKey = async (id) => {
        setLoading(true)
        let rq = await resettingGoogleKey({ userId: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '谷歌验证码重置成功' })
            if (agentModal) {
                getAgentChildData()
            } else {
                getData(pagination)
            }
        } else {
            message.error({ content: rq.msg })
        }
    }


    const editFun = async (params) => {
        setLoading(true)
        await payFn()
        setLoading(false)
        setAgentId(params.id)
        try {

            let collectCurrencyRate = JSON.parse(params.currencyRateCollection)
            let paymentCurrencyRate = JSON.parse(params.currencyRatePayment)

            for (let item of collectCurrencyRate) {
                let name1 = Object.keys(item)[0]
                if (name1 === 'RainBowPayIndia') {
                    form.setFieldsValue({
                        "rcp": item.RainBowPayIndia.percentage,
                    })
                    collect.push('RainBowPayIndia')
                    setCollect(() => {
                        return [
                            ...collect
                        ]
                    })
                }
                else if (name1 === 'FastPayVietnam2') {
                    form.setFieldsValue({
                        "vcg": item.FastPayVietnam2.gateway,
                        "vcm": item.FastPayVietnam2.momo,
                    })
                    collect.push('FastPayVietnam2')
                    setCollect(() => {
                        return [
                            ...collect
                        ]
                    })
                    if (item.FastPayVietnam2.gateway && item.FastPayVietnam2.momo) {
                        setValue4('gateway_momo')
                    } else if (item.FastPayVietnam2.gateway) {
                        setValue4('gateway')
                    } else if (item.FastPayVietnam2.momo) {
                        setValue4('momo')
                    }
                }
                else if (name1 === 'FastPayVietnam1') {
                    form.setFieldsValue({
                        "f1cg": item.FastPayVietnam1.gateway,
                        "f1cm": item.FastPayVietnam1.momo,
                    })
                    collect.push('FastPayVietnam1')
                    setCollect(() => {
                        return [
                            ...collect
                        ]
                    })
                    if (item.FastPayVietnam1.gateway && item.FastPayVietnam1.momo) {
                        setValue3('gateway_momo')
                    } else if (item.FastPayVietnam1.gateway) {
                        setValue3('gateway')
                    } else if (item.FastPayVietnam1.momo) {
                        setValue3('momo')
                    }
                }
                else if (name1 === 'FastPayIndia') {
                    form.setFieldsValue({
                        "fcp": item.FastPayIndia.percentage,
                    })
                    collect.push('FastPayIndia')
                    setCollect(() => {
                        return [
                            ...collect
                        ]
                    })
                }
            }


            for (let item of paymentCurrencyRate) {
                let name1 = Object.keys(item)[0]

                if (name1 === 'RainBowPayIndia') {
                    form.setFieldsValue({
                        'rpp': item.RainBowPayIndia.percentage,
                        "rpa": item.RainBowPayIndia.amount
                    })
                    payment.push('RainBowPayIndia')
                    setPayment(() => {
                        return [
                            ...payment
                        ]
                    })
                }
                else if (name1 === 'FastPayVietnam2') {
                    form.setFieldsValue({
                        'vpp': item.FastPayVietnam2.percentage,
                    })
                    payment.push('FastPayVietnam2')
                    setPayment(() => {
                        return [
                            ...payment
                        ]
                    })
                }
                else if (name1 === 'FastPayVietnam1') {
                    form.setFieldsValue({
                        "f1pa": item.FastPayVietnam1.amount
                    })
                    payment.push('FastPayVietnam1')
                    setPayment(() => {
                        return [
                            ...payment
                        ]
                    })
                }
                else if (name1 === 'FastPayIndia') {
                    form.setFieldsValue({
                        'fpp': item.FastPayIndia.percentage,
                        "fpa": item.FastPayIndia.amount
                    })
                    payment.push('FastPayIndia')
                    setPayment(() => {
                        return [
                            ...payment
                        ]
                    })
                }
            }

        } catch (error) {

        }

        form.setFieldsValue({
            name: params.name,
            account: params.sysUser.account,
            singleTransactionMaxWithd: params.singleTransactionMaxWithd,
            singleTransactionMinWithd: params.singleTransactionMinWithd,
            singleTransactionDayWithd: params.singleTransactionDayWithd,
            withdrawalExpense: params.withdrawalExpense,
            commission: params.commission,
            withdReviewMax: params.withdReviewMax,
            withdReviewMin: params.withdReviewMin,
            rechargeReviewMax: params.rechargeReviewMax,
            rechargeReviewMin: params.rechargeReviewMin,
        })
        setTitle('修改')
        setVisible(true);
    }
    const editAgentFun = async (params) => {
        setAgentId(params.id)
        agentForm.setFieldsValue({
            name: params.name,
            account: params.sysUser.account,
            singleTransactionMaxWithd: params.singleTransactionMaxWithd,
            singleTransactionMinWithd: params.singleTransactionMinWithd,
            singleTransactionDayWithd: params.singleTransactionDayWithd,
            withdrawalExpense: params.withdrawalExpense,
            commission: params.commission,
            withdReviewMax: params.withdReviewMax,
            withdReviewMin: params.withdReviewMin,
            rechargeReviewMax: params.rechargeReviewMax,
            rechargeReviewMin: params.rechargeReviewMin,
        })
        setTitle('修改')
        setAgentVisible(true)
    }

    const findSysUser = async (query) => {
        getData({
            current: 1,
            pageSize: 10,
            name: query.name,
            status: query.status,
        })
    }

    const payFn = async () => {
        let rq = await payPlatformFindPage()
        if (rq.code == "00000") {
            for (let item of rq.data.records) {
                item.collectCurrencyRate = JSON.parse(item.collectCurrencyRate)
                item.paymentCurrencyRate = JSON.parse(item.paymentCurrencyRate)
                if (item.name === 'RainBowPayIndia') {
                    setRainBowPayIndia(() => {
                        return {
                            ...item
                        }
                    })
                    form.setFieldsValue({
                        "rcp": item.collectCurrencyRate.percentage,
                        'rpp': item.paymentCurrencyRate.percentage,
                        "rpa": item.paymentCurrencyRate.amount
                    })
                }
                else if (item.name === 'FastPayVietnam2') {
                    setFastPayVietnam2(item)
                    form.setFieldsValue({
                        "vcg": item.collectCurrencyRate.gateway,
                        "vcm": item.collectCurrencyRate.momo,
                        'vpp': item.paymentCurrencyRate.percentage,
                    })
                    if (item.collectCurrencyRate.gateway && item.collectCurrencyRate.momo) {
                        setValue4('gateway_momo')
                    } else if (item.collectCurrencyRate.gateway) {
                        setValue4('gateway')
                    } else if (item.collectCurrencyRate.momo) {
                        setValue4('momo')
                    }
                }
                else if (item.name === 'FastPayVietnam1') {
                    setFastPayVietnam1(item)
                    form.setFieldsValue({
                        "f1cg": item.collectCurrencyRate.gateway,
                        "f1cm": item.collectCurrencyRate.momo,
                        "f1pa": item.paymentCurrencyRate.amount
                    })
                    if (item.collectCurrencyRate.gateway && item.collectCurrencyRate.momo) {
                        setValue3('gateway_momo')
                    } else if (item.collectCurrencyRate.gateway) {
                        setValue3('gateway')
                    } else if (item.collectCurrencyRate.momo) {
                        setValue3('momo')
                    }
                }
                else if (item.name === 'FastPayIndia') {
                    setFastPayIndia(item)
                    form.setFieldsValue({
                        "fcp": item.collectCurrencyRate.percentage,
                        'fpp': item.paymentCurrencyRate.percentage,
                        "fpa": item.paymentCurrencyRate.amount
                    })
                } 
                else if (item.name === 'GoPayIndia') {
                    setGoPayIndia(item)
                    form.setFieldsValue({
                        "gcp": item.collectCurrencyRate.percentage,
                        "gca": item.collectCurrencyRate.amount,
                        'gpp': item.paymentCurrencyRate.percentage,
                        'gpa': item.paymentCurrencyRate.amount,
                    })
                }
            }
        }
    }

    const changeStatesFun = async (item, status, isChild=false) => {
        setLoading(true)
        let temp = status == 0 ? 1 : 0
        let tempObj = Object.assign(item, { status: temp })
        let rq = await agentUpdateInfo(tempObj)
        setLoading(false)
        if (rq.code == "00000") {
            if (!isChild) {
                getData({ current: pagination.current, pageSize: 10 })
            } else {
                getAgentChildData()
            }
        }
    }

    const onFinishFun = async () => {
        if (flag) return
        try {
            await form.validateFields();
            let values = form.getFieldValue()
            let rq = null
            if (title === '新增') {
                let SysUser = {
                    account: values.account,
                    password: md5(values.password),
                    contact: values.contact,
                    states: 0,
                    role: 4, // 默认代理
                    fundPassword: md5(values.fundPassword),
                }
                delete values.account
                delete values.password
                delete values.contact
                delete values.fundPassword
                values.sysUser = SysUser
                let c_arr = []
                let p_arr = []
                for (let item of collect) {
                    if (item === 'RainBowPayIndia') {
                        let obj = { "RainBowPayIndia": Object.assign(RainBowPayIndia.collectCurrencyRate, { percentage: values.rcp }) }
                        c_arr.push(obj)
                    } else if (item === 'FastPayIndia') {
                        let obj = { "FastPayIndia": Object.assign(FastPayIndia.collectCurrencyRate, { percentage: values.fcp }) }
                        c_arr.push(obj)
                    } else if (item === 'GoPayIndia') {
                        let obj = { "GoPayIndia": Object.assign(GoPayIndia.collectCurrencyRate, { percentage: values.gcp, amount: values.gca }) }
                        c_arr.push(obj)
                    } else if (item === 'FastPayVietnam2') {
                        let gateWay_momo = {
                            type: FastPayVietnam2.collectCurrencyRate.type
                        }
                        if (value4 === 'gateway') {
                            gateWay_momo.gateway = values.vcg
                        } else if (value4 === 'momo') {
                            gateWay_momo.momo = values.vcm
                        } else if (value4 === 'gateway_momo') {
                            gateWay_momo.gateway = values.vcg
                            gateWay_momo.momo = values.vcm
                        }
                        let obj = { "FastPayVietnam2": gateWay_momo }
                        c_arr.push(obj)
                    } else if (item === 'FastPayVietnam1') {
                        let gateWay_momo = {
                            type: FastPayVietnam1.collectCurrencyRate.type
                        }
                        if (value3 === 'gateway') {
                            gateWay_momo.gateway = values.f1cg
                        } else if (value3 === 'momo') {
                            gateWay_momo.momo = values.f1cm
                        } else if (value3 === 'gateway_momo') {
                            gateWay_momo.gateway = values.f1cg
                            gateWay_momo.momo = values.f1cm
                        }
                        let obj = { "FastPayVietnam1": gateWay_momo }
                        // let obj = { "FastPayVietnam1": Object.assign(FastPayVietnam1.collectCurrencyRate, { gateway: values.f1cg, momo: values.f1cm }) }
                        c_arr.push(obj)
                    }
                }
                for (let item of payment) {
                    if (item === 'RainBowPayIndia') {
                        let obj = { "RainBowPayIndia": Object.assign(RainBowPayIndia.paymentCurrencyRate, { percentage: values.rpp, amount: values.rpa }) }
                        p_arr.push(obj)
                    } else if (item === 'FastPayIndia') {
                        let obj = { "FastPayIndia": Object.assign(FastPayIndia.paymentCurrencyRate, { percentage: values.fpp, amount: values.fpa }) }
                        p_arr.push(obj)
                    } else if (item === 'GoPayIndia') {
                        let obj = { "GoPayIndia": Object.assign(GoPayIndia.paymentCurrencyRate, { percentage: values.gpp, amount: values.gpa }) }
                        p_arr.push(obj)
                    } else if (item === 'FastPayVietnam2') {
                        let obj = { "FastPayVietnam2": Object.assign(FastPayVietnam2.paymentCurrencyRate, { percentage: values.vpp }) }
                        p_arr.push(obj)
                    } else if (item === 'FastPayVietnam1') {
                        let obj = { "FastPayVietnam1": Object.assign(FastPayVietnam1.paymentCurrencyRate, { amount: values.f1pa }) }
                        p_arr.push(obj)
                    }
                }


                values.currencyRateCollection = JSON.stringify(c_arr)
                values.currencyRatePayment = JSON.stringify(p_arr)
                
                values.withdReviewMax = Math.max(values.withdReviewMin, values.withdReviewMax);
                values.withdReviewMin = Math.min(values.withdReviewMin, values.withdReviewMax);
                values.rechargeReviewMax = Math.max(values.rechargeReviewMin, values.rechargeReviewMax);
                values.rechargeReviewMin = Math.min(values.rechargeReviewMin, values.rechargeReviewMax);

                delete values.rpp
                delete values.rpa
                delete values.rcp

                delete values.fpp
                delete values.fpa
                delete values.fcp

                delete values.vpp
                delete values.vcg
                delete values.vcm

                delete values.f1pa
                delete values.f1cg
                delete values.f1cm

                delete values.gpa
                delete values.gpp
                delete values.gca
                delete values.gcp



                values.status = 0
                values.commission = 0

                rq = await agentAdd(values)
            } else {
                let obj = {
                    id: agentId,
                    name: values.name,
                    singleTransactionMaxWithd: values.singleTransactionMaxWithd,
                    singleTransactionMinWithd: values.singleTransactionMinWithd,
                    singleTransactionDayWithd: values.singleTransactionDayWithd,
                    withdrawalExpense: values.withdrawalExpense,
                    commission: 0,
                    withdReviewMax: Math.max(values.withdReviewMin, values.withdReviewMax),
                    withdReviewMin: Math.min(values.withdReviewMin, values.withdReviewMax),
                    rechargeReviewMax: Math.max(values.rechargeReviewMin, values.rechargeReviewMax),
                    rechargeReviewMin: Math.min(values.rechargeReviewMin, values.rechargeReviewMax),
                }
                let c_arr = []
                let p_arr = []
                for (let item of collect) {
                    if (item === 'RainBowPayIndia') {
                        let objs = { "RainBowPayIndia": Object.assign(RainBowPayIndia.collectCurrencyRate, { percentage: values.rcp }) }
                        c_arr.push(objs)
                    } else if (item === 'FastPayIndia') {
                        let objs = { "FastPayIndia": Object.assign(FastPayIndia.collectCurrencyRate, { percentage: values.fcp }) }
                        c_arr.push(objs)
                    } else if (item === 'GoPayIndia') {
                        let objs = { "GoPayIndia": Object.assign(GoPayIndia.collectCurrencyRate, { percentage: values.gcp, amount: values.gca }) }
                        c_arr.push(objs)
                    }
                     else if (item === 'FastPayVietnam2') {
                        // let objs = { "FastPayVietnam2": Object.assign(FastPayVietnam2.collectCurrencyRate, { gateway: values.vcg, momo: values.vcm }) }
                        let gateWay_momo = {
                            type: FastPayVietnam2.collectCurrencyRate.type
                        }
                        if (value4 === 'gateway') {
                            gateWay_momo.gateway = values.vcg
                        } else if (value4 === 'momo') {
                            gateWay_momo.momo = values.vcm
                        } else if (value4 === 'gateway_momo') {
                            gateWay_momo.gateway = values.vcg
                            gateWay_momo.momo = values.vcm
                        }
                        let objs = { "FastPayVietnam2": gateWay_momo }
                        c_arr.push(objs)

                    } else if (item === 'FastPayVietnam1') {
                        let gateWay_momo = {
                            type: FastPayVietnam1.collectCurrencyRate.type
                        }
                        if (value3 === 'gateway') {
                            gateWay_momo.gateway = values.f1cg
                        } else if (value3 === 'momo') {
                            gateWay_momo.momo = values.f1cm
                        } else if (value3 === 'gateway_momo') {
                            gateWay_momo.gateway = values.f1cg
                            gateWay_momo.momo = values.f1cm
                        }
                        let objs = { "FastPayVietnam1": gateWay_momo }
                        // let objs = { "FastPayVietnam1": Object.assign(FastPayVietnam1.collectCurrencyRate, { gateway: values.f1cg, momo: values.f1cm }) }
                        c_arr.push(objs)
                    }
                }
                for (let item of payment) {
                    if (item === 'RainBowPayIndia') {
                        let objs = { "RainBowPayIndia": Object.assign(RainBowPayIndia.paymentCurrencyRate, { percentage: values.rpp, amount: values.rpa }) }
                        p_arr.push(objs)
                    } else if (item === 'FastPayIndia') {
                        let objs = { "FastPayIndia": Object.assign(FastPayIndia.paymentCurrencyRate, { percentage: values.fpp, amount: values.fpa }) }
                        p_arr.push(objs)
                    } else if (item === 'GoPayIndia') {
                        let objs = { "GoPayIndia": Object.assign(GoPayIndia.paymentCurrencyRate, { percentage: values.gpp, amount: values.gpa }) }
                        p_arr.push(objs)
                    }
                    else if (item === 'FastPayVietnam2') {
                        let objs = { "FastPayVietnam2": Object.assign(FastPayVietnam2.paymentCurrencyRate, { percentage: values.vpp }) }
                        p_arr.push(objs)
                    } else if (item === 'FastPayVietnam1') {
                        let objs = { "FastPayVietnam1": Object.assign(FastPayVietnam1.paymentCurrencyRate, { amount: values.f1pa }) }
                        p_arr.push(objs)
                    }
                }

                obj.currencyRateCollection = JSON.stringify(c_arr)
                obj.currencyRatePayment = JSON.stringify(p_arr)



                rq = await agentUpdateInfo(obj)
            }
            if (rq.code == "00000") {
                message.success({ content: `${title}成功` })
                setVisible(false)
                getData({ current: pagination.current, pageSize: 10 })
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
        setFlag(false)
    }
    // 创建子代理
    const onFinishAgentFun = async () => {
        if (flag) return
        try {
            await agentForm.validateFields();
            let values = agentForm.getFieldValue()
            let rq = null
            if (title === '新增') {
                let SysUser = {
                    account: values.account,
                    password: md5(values.password),
                    contact: values.contact,
                    states: 0,
                    role: 4, // 默认代理
                    fundPassword: md5(values.fundPassword),
                    nickName: values.account,
                }
                values.name = values.account
                delete values.account
                delete values.password
                delete values.contact
                delete values.fundPassword
                values.sysUser = SysUser
                values.status = 0
                values.commission = 0
                values.withdReviewMax = Math.max(values.withdReviewMin, values.withdReviewMax);
                values.withdReviewMin = Math.min(values.withdReviewMin, values.withdReviewMax);
                values.rechargeReviewMax = Math.max(values.rechargeReviewMin, values.rechargeReviewMax);
                values.rechargeReviewMin = Math.min(values.rechargeReviewMin, values.rechargeReviewMax);
                values.currencyRateCollection = '[]'
                values.currencyRatePayment = '[]'
                rq = await insertAgentSubAccountFn(values)
            } else {
                let obj = {
                    id: agentId,
                    name: values.name,
                    singleTransactionMaxWithd: values.singleTransactionMaxWithd,
                    singleTransactionMinWithd: values.singleTransactionMinWithd,
                    singleTransactionDayWithd: values.singleTransactionDayWithd,
                    withdrawalExpense: values.withdrawalExpense,
                    commission: 0,
                    withdReviewMax: Math.max(values.withdReviewMin, values.withdReviewMax),
                    withdReviewMin: Math.min(values.withdReviewMin, values.withdReviewMax),
                    rechargeReviewMax: Math.max(values.rechargeReviewMin, values.rechargeReviewMax),
                    rechargeReviewMin: Math.min(values.rechargeReviewMin, values.rechargeReviewMax),
                }
                setAgentVisible(false)
                setLoading(true)
                rq = await agentUpdateInfo(obj)
                setLoading(false)
            }

            if (rq.code == "00000") {
                message.success({ content: `${title}成功` })
                setAgentVisible(false)
                if (title !== '新增') {
                    getAgentChildData()
                }
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
        setFlag(false)
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        let temp = Object.assign(newPagination, searchObj)
        getData(temp)
    };

    const onChange = (e) => {
        const arr = e.target.name.split('.')
        let name = arr[0]
        let types = arr[1]
        if (name === "FastPayVietnam1") {
            if (types === "collectCurrencyRate") {
                FastPayVietnam1.collectCurrencyRate.type = e.target.value
            } else if (types === "paymentCurrencyRate") {
                FastPayVietnam1.paymentCurrencyRate.type = e.target.value
            }
            setFastPayVietnam1(() => {
                return {
                    ...FastPayVietnam1
                }
            })
        }
        else if (name === "RainBowPayIndia") {
            if (types === "collectCurrencyRate") {
                RainBowPayIndia.collectCurrencyRate.type = e.target.value
            } else if (types === "paymentCurrencyRate") {
                RainBowPayIndia.paymentCurrencyRate.type = e.target.value
            }
            setRainBowPayIndia(() => {
                return {
                    ...RainBowPayIndia
                }
            })
        }
        else if (name === "FastPayVietnam2") {
            if (types === "collectCurrencyRate") {
                FastPayVietnam2.collectCurrencyRate.type = e.target.value
            } else if (types === "paymentCurrencyRate") {
                FastPayVietnam2.paymentCurrencyRate.type = e.target.value
            }
            setFastPayVietnam2(() => {
                return {
                    ...FastPayVietnam2
                }
            })
        }
        else if (name === "FastPayIndia") {
            if (types === "collectCurrencyRate") {
                FastPayIndia.collectCurrencyRate.type = e.target.value
            } else if (types === "paymentCurrencyRate") {
                FastPayIndia.paymentCurrencyRate.type = e.target.value
            }
            setFastPayIndia(() => {
                return {
                    ...FastPayIndia
                }
            })
        }
    }



    const changeTypesFn = (e, t, name) => {

        let isCancel = false
        if (t === 'collect') {
            if (e.target.checked) {
                // 判断是否存在
                if (!collect.find(el => el === name)) {
                    const c = collect.concat(name)
                    setCollect(c)
                }
            } else {
                const _index = collect.findIndex(el => el === name)
                collect.splice(_index, 1)
                setCollect(() => {
                    return [
                        ...collect
                    ]
                })
                isCancel = true
            }
        } else if (t === 'payment') {
            if (e.target.checked) {
                if (!payment.find(el => el === name)) {
                    const c = payment.concat(name)
                    setPayment(c)
                }
            } else {
                const _index = payment.findIndex(el => el === name)
                payment.splice(_index, 1)
                setPayment(() => {
                    return [
                        ...payment
                    ]
                })
                isCancel = true
            }
        }
        if (isCancel) {
            //还原
            // form.resetFields()
            if (name === 'RainBowPayIndia') {
                if (t === 'collect') {
                    form.setFieldsValue({
                        "rcp": RainBowPayIndia.collectCurrencyRate.percentage,
                    })
                } else {
                    form.setFieldsValue({
                        'rpp': RainBowPayIndia.paymentCurrencyRate.percentage,
                        "rpa": RainBowPayIndia.paymentCurrencyRate.amount
                    })
                }
            }
            else if (name === 'FastPayVietnam2') {
                if (t === 'collect') {
                    form.setFieldsValue({
                        "vcg": FastPayVietnam2.collectCurrencyRate.gateway,
                        "vcm": FastPayVietnam2.collectCurrencyRate.momo,
                    })
                } else {
                    form.setFieldsValue({
                        'vpp': FastPayVietnam2.paymentCurrencyRate.percentage,
                    })
                }
            }
            else if (name === 'FastPayVietnam1') {
                if (t === 'collect') {
                    form.setFieldsValue({
                        "f1cg": FastPayVietnam1.collectCurrencyRate.gateway,
                        "f1cm": FastPayVietnam1.collectCurrencyRate.momo,
                    })
                } else {
                    form.setFieldsValue({
                        "f1pa": FastPayVietnam1.paymentCurrencyRate.amount
                    })
                }
            }
            else if (name === 'FastPayIndia') {
                if (t === 'collect') {
                    form.setFieldsValue({
                        "fcp": FastPayIndia.collectCurrencyRate.percentage,
                    })
                } else {
                    form.setFieldsValue({
                        'fpp': FastPayIndia.paymentCurrencyRate.percentage,
                        "fpa": FastPayIndia.paymentCurrencyRate.amount
                    })
                }
            }
        }

    }

    const isCheckFn = (name) => {
        return collect.find(el => el === name) ? true : false
    }


    const isChecnkPFn = (name) => {
        return payment.find(el => el === name) ? true : false
    }

    const options = [
        {
            label: '网关扫码+Momo',
            value: 'gateway_momo',
        },
        {
            label: '网关扫码',
            value: 'gateway',
        },
        {
            label: 'Momo',
            value: 'momo',
        }
    ];

    const onChange3 = ({ target: { value } }) => {
        setValue3(value);
    };
    const onChange4 = ({ target: { value } }) => {
        setValue4(value);
    };
    const [value3, setValue3] = useState();
    const [value4, setValue4] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentId, setId] = useState()
    const handleOk = () => {
        setIsModalOpen(false)
        if (currentId) delFun(currentId)
    }

    const [agentModal, setAgentModal] = useState(false);
    // const [currentId, setId] = useState()
    const handleAgentOk = () => {
        setAgentModal(false)
    }


    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={findSysUser}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name="name">
                        <Input placeholder='请输入代理名称' allowClear />
                    </Form.Item>
                    <Form.Item name="status" >
                        <Select
                            style={{
                                width: 180,
                            }}  
                            allowClear
                            placeholder="选择用户状态"
                        >
                            <Option value="0">正常</Option>
                            <Option value="1">停用</Option>
                        </Select>
                    </Form.Item>   
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >
                            搜索
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => { showDrawer() }}>新增</Button>
                    </Form.Item>
                </Form>
            </div>
            <Table columns={columns} dataSource={data}
                rowKey={(res) => res.id}
                pagination={pagination} onChange={handleTableChange} loading={loading} />
            
            {/* 一级代理 */}
            <Drawer title={`${title}代理`} width={'60%'} placement="right" onClose={onClose} 
                visible={visible} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={onFinishFun} type="primary" >
                            确定
                        </Button>
                        <Button onClick={onClose} htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <div className=''>
                    <div className=''>
                        <Form
                            form={form}
                            onFinish={onFinishFun}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                        >
                            <div className='tag-box'>基本信息</div>
                            <div>
                                <Row type="flex">
                                    <Col span='12'>
                                        <Form.Item
                                            label="代理名称"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '代理名称不能为空',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户登录名"
                                            name="account"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '用户登录名不能为空',
                                                },
                                            ]}
                                        >
                                            <Input disabled={title ==='修改' ? true : false} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                {
                                    title === '新增' ?
                                        <Row type="flex">

                                            <Col span='12'>
                                                <Form.Item
                                                    label="用户密码"
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '用户密码不能为空',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>

                                            <Col span='12'>
                                                <Form.Item
                                                    label="资金密码"
                                                    name="fundPassword"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '资金不能为空',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        : null
                                }

                                <Row type="flex">
                                    <Col span='12'>
                                        <Form.Item
                                            label="充值金额最小值"
                                            name="rechargeReviewMin"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '充值金额最小值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="充值金额最大值"
                                            name="rechargeReviewMax"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '充值金额最大值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span='12'>
                                        <Form.Item
                                            label="提现金额最小值"
                                            name="withdReviewMin"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '提现金额最小值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="提现金额最大值"
                                            name="withdReviewMax"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '提现金额最大值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                    {
                                        title === '新增' ?
                                        <Col span='12'>
                                            <Form.Item
                                                label="联系方式"
                                                name="contact"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '联系方式不能为空',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col> : null
                                    }
                                </Row>

                            </div>

                            <div className='tag-box'>分控配置</div>
                            <Row type="flex">
                                <Col span='12'>
                                    <Form.Item
                                        label="单笔最大提现金额"
                                        name="singleTransactionMaxWithd"
                                        rules={[
                                            {
                                                required: true,
                                                message: '单笔最大提现金额为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入最大提现金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                                <Col span='12'>
                                    <Form.Item
                                        label="单笔最小提现金额"
                                        name="singleTransactionMinWithd"
                                        rules={[
                                            {
                                                required: true,
                                                message: '单笔最小提现金额为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入最小提现金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex">
                                <Col span='12'>
                                    <Form.Item
                                        label="单日提现最大金额"
                                        name="singleTransactionDayWithd"
                                        rules={[
                                            {
                                                required: true,
                                                message: '单日提现最大金额为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入单日提现最大金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                                <Col span='12'>
                                    <Form.Item
                                        label="提现费率(%)"
                                        name="withdrawalExpense"
                                        rules={[
                                            {
                                                required: true,
                                                message: '提现费率为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入提现费率!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Drawer>

            {/* 代理子代 */}
            <Drawer title={`${title}后台用户`} width={'60%'} placement="right" onClose={onAgentClose} 
                visible={agentVisible} destroyOnClose={true}
                zIndex={1200}
                footer={
                    <Space>
                        <Button onClick={onFinishAgentFun} type="primary" >
                            确定
                        </Button>
                        <Button onClick={onAgentClose} htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <div className=''>
                    <div className=''>
                        <Form
                            form={agentForm}
                            onFinish={onFinishAgentFun}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                        >
                            <div className='tag-box'>基本信息</div>
                            <div>
                                {/* <Row type="flex">
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户名称"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '用户名称不能为空',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                                <Row type="flex">
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户登录名"
                                            name="account"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '用户登录名不能为空',
                                                },
                                            ]}
                                        >
                                            <Input disabled={title ==='修改' ? true : false} />
                                        </Form.Item>
                                    </Col>

                                    {
                                        title === '新增' ?
                                        <>
                                            <Col span='12'>
                                                <Form.Item
                                                    label="联系方式"
                                                    name="contact"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '联系方式不能为空',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span='12'>
                                                <Form.Item
                                                    label="用户密码"
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '用户密码不能为空',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>

                                            <Col span='12'>
                                                <Form.Item
                                                    label="资金密码"
                                                    name="fundPassword"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '资金不能为空',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                            </Col>
                                        </> : null
                                    }
                                    
                                    <Col span='12'>
                                        <Form.Item
                                            label="充值金额最小值"
                                            name="rechargeReviewMin"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '充值金额最小值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="充值金额最大值"
                                            name="rechargeReviewMax"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '充值金额最大值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span='12'>
                                        <Form.Item
                                            label="提现金额最小值"
                                            name="withdReviewMin"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '提现金额最小值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="提现金额最大值"
                                            name="withdReviewMax"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '提现金额最大值不能为空',
                                                },
                                            ]}
                                        >
                                            <InputNumber style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </div>

                            <div className='tag-box'>分控配置</div>
                            <Row type="flex">
                                <Col span='12'>
                                    <Form.Item
                                        label="单笔最大提现金额"
                                        name="singleTransactionMaxWithd"
                                        rules={[
                                            {
                                                required: true,
                                                message: '单笔最大提现金额为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入最大提现金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                                <Col span='12'>
                                    <Form.Item
                                        label="单笔最小提现金额"
                                        name="singleTransactionMinWithd"
                                        rules={[
                                            {
                                                required: true,
                                                message: '单笔最小提现金额为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入最小提现金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex">
                                <Col span='12'>
                                    <Form.Item
                                        label="单日提现最大金额"
                                        name="singleTransactionDayWithd"
                                        rules={[
                                            {
                                                required: true,
                                                message: '单日提现最大金额为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入单日提现最大金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                                <Col span='12'>
                                    <Form.Item
                                        label="提现费率(%)"
                                        name="withdrawalExpense"
                                        rules={[
                                            {
                                                required: true,
                                                message: '提现费率为空',
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} placeholder='请输入提现费率!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Drawer>

            <Modal visible={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)}>
              <p>确认删除该代理账号?</p>
            </Modal>

            {/* 查看后台用户列表 */}
            <Modal visible={agentModal}
                onCancel={handleAgentOk}
                footer={[
                    <Button key="submit" type="primary" onClick={handleAgentOk}>
                        确定
                    </Button>,
                ]}
                width={1000}
                // footer={null}
                // bodyStyle={{
                //     height: '500px'
                // }}
            >
                <Table columns={columns1} dataSource={agentData}
                    rowKey={(res) => res.id}
                    size="small"
                    pagination={pagination1} onChange={handleTableChange1} loading={loading} scroll={{ y: 500 }} />
            </Modal>

            

            {/* 修改密码 */}
            <Modal visible={pswModal} 
                onOk={onPsdFinish} width='650px'
                onCancel={() => {
                    psdForm.resetFields()
                    setPswModal(false)
                }} title='修改密码'>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={psdForm}
                    autoComplete="off"
                >
                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirm"
                        dependencies={['newPassword']}
                        rules={[
                            {
                                required: true,
                                message: '请确认新密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('两次密码不一致!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                </Form>
            </Modal>

            {/* 修改资金密码 */}
            <Modal visible={fundPswModal} 
                onOk={onFundPsdFinish} width='650px'
                onCancel={() => {
                    fundPsdForm.resetFields()
                    setFundPswModal(false)
                }} title='修改资金密码'>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={fundPsdForm}
                    autoComplete="off"
                >
                    <Form.Item
                        label="新资金密码"
                        name="newFundPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新资金密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirm"
                        dependencies={['newFundPassword']}
                        rules={[
                            {
                                required: true,
                                message: '请确认新资金密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newFundPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('两次密码不一致!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    )
}
