import moment from 'moment';
import ExportJsonExcel from 'js-export-excel'
import './merchantList.css'
import React, { useState, useEffect, useImperativeHandle, useCallback, useRef, useMemo } from 'react';

import { merchantFindPage, merchantRecord,merchantDel, merchantAdd, findAgentInfoPage, merchantUpdate } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, message, DatePicker, Drawer, Select, Row, Col, Spin, InputNumber } from 'antd';


import md5 from 'md5';

import SearchInput  from '../SearchInput';

const { Option } = Select;
const { RangePicker } = DatePicker;






export const MerchantFund = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
        position: ['bottomCenter']
    });

    const [title, setTitle] = useState()


    const [agentId, setAgentId] = useState()

    const [etcRate, setEtcRate] = useState()


    const [params, setParams] = useState({
        uuid: null,
        account: null,
        types: 1,

    })

    const [types, setTypes] = useState(1)

    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await merchantRecord({
            page: params.current,
            limit: params.pageSize,
            merchantName: params.merchantName,
            code: params.code,
            changeType: params.changeType,
            startTime: params.startTime,
            endTime: params.endTime,
            sysType: 2
        })
        if (rq.code == '00000') {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                pageSize: rq.data.size,
                total: rq.data.total
            }))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
    }, [getData])




    const onFinish = (fieldsValue) => {
        const rangeTimeValue = fieldsValue['range-time-picker'];
        const values = {
            ...fieldsValue,
            'startTime': rangeTimeValue && rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss') || null,
            'endTime': rangeTimeValue && rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss') || null
          };
        let params = { 
            current: 1, 
            pageSize: 10,
            account: values.merchantName,
            code: values.code,
            changeType: values.changeType,
            startTime: values.startTime,
            endTime: values.endTime
         }
        getData(params)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const changeFn = (params) => {
        // setParams(params)
        // form.setFieldsValue({
        //     name: params.name,
        //     agentId: params.agentId,
        //     currencyRatePayment: params.currencyRatePayment,
        //     currencyRateCollection: params.currencyRateCollection,
        //     singleTransactionMaxWithd: params.singleTransactionMaxWithd,
        //     singleTransactionMinWithd: params.singleTransactionMinWithd,
        //     singleTransactionDayWithd: params.singleTransactionDayWithd,
        //     withdrawalExpense: params.withdrawalExpense
        // })
        // setAgentId(params.agentId)
        // setIsModalVisible(true);
        // setTitle('修改')
    }


    const columns = [
        {
            title: '用户名',
            dataIndex: 'userName',
            key: 'userName',
        },
        // {
        //     title: '所属代理',
        //     key: 'appkey',
        //     dataIndex: 'appkey',
        // },
        {
            title: '业务订单',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '通道',
            dataIndex:'currency',
            key: 'currency',
        },
        {
            title: '变更前余额',
            dataIndex: 'changeFrontMoney',
            key: 'changeFrontMoney',
        },
        {
            title: '变更余额',
            dataIndex: 'changeMoney',
            key: 'changeMoney',
        },
        {
            title: '变更后余额',
            dataIndex: 'changeAfterMoney',
            key: 'changeAfterMoney',
        },
        {
            title: '业务类型',
            dataIndex: 'changeType',
            key: 'changeType',
            render: (text) => (
                <Space size="middle">
                    {
                        text === 1 ? <Tag color='#87d068'>提现</Tag> :
                            text === 2 ? <Tag color='#108ee9'>充值</Tag> :
                                text === 3 ? <Tag color="#f50">提现</Tag> : null
                    }
                </Space>
            )
        },
        // {
        //     title: '订单金额',
        //     dataIndex: 'orderMoney',
        //     key: 'orderMoney',
        // },
        {
            title: '手续费',
            key: 'balance',
            render: (text, record, id) => (
                <Space size="middle">
                    {
                        handleExact(record.orderMoney, record.actualMoney)
                    }
                </Space>
            ),
        },
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        // {
        //     title: '操作',
        //     key: 'action',
        //     render: (text, record, id) => (
        //         <Space size="middle">
        //             <a onClick={() => changeFn(record)}>查看</a>
        //         </Space>
        //     ),
        // },
    ];

    const handleExact = (a,b) => {
        let total = 0
        if(b) {
          if (a) {
            total = Number(a)
          }
          total -= Number(b)
        }  
        return Math.floor(total).toFixed(2)
    }

    const exportExcel = (id, name) => {
        let filter = [], header = []
        for (let i = 0; i < columns.length; i++) {
            let temp = columns[i]
            filter.push(temp.dataIndex)
            header.push(temp.title)
        }
        let dataTemp = JSON.parse(JSON.stringify(data))
        for (let i = 0; i < dataTemp.length; i++) {
            let temp = dataTemp[i]
            if (temp.changeType === 1) {
                dataTemp[i].changeType = '提现'
            } else {
                dataTemp[i].changeType = '充值'
            }
        }
        // 直接导出文件
        let dataTable = [];  //excel文件中的数据内容
        let option = {};  //option代表的就是excel文件
        dataTable = dataTemp;  //数据源
        option.fileName = name;  //excel文件名称
        console.log("data===", dataTable)
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: 'Sheet1',  //excel文件中sheet页名称
                sheetFilter: filter,  //excel文件中需显示的列数据
                sheetHeader: header,  //excel文件中每列的表头名称
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setTitle('新增')
        form.setFieldsValue({
            agentId: null,
            name: null,
            currencyRatePayment: null,
            currencyRateCollection: null,
            singleTransactionMaxWithd: null,
            singleTransactionMinWithd: null,
            singleTransactionDayWithd: null
        })
        setAgentId(null)
        setIsModalVisible(true);
    };
    
    const handleOk = async (e) => {
        let temp = form.getFieldValue()
        if(title === '新增') {
            let sysUser = {
                account: temp.account,
                contact: temp.contact,
                password: md5(temp.password),
                states: 0,
                role: 5 // 商户
            }
            delete temp.account
            delete temp.password
            delete temp.contact
            temp.sysUser = sysUser
        }
        temp.agentId = agentId
        temp.status = 0 // 默认正常
        temp.currencyRatePayment = '{klzf:[Paytm:{USD:0.001,INR:0.001},Uip:{USD:0.002,INR:0.002}}]}'
        temp.currencyRateCollection = '{klzf:[Paytm:{USD:0.001,INR:0.001},Uip:{USD:0.002,INR:0.002}}]}'
        setLoading(true)
        let rq = null 
        if (title === '新增') {
            rq = await merchantAdd(temp)
        } else {
            rq = await merchantUpdate(temp)
        }
        if (rq.code == "00000") {
            message.success({ content: `${title}成功` })
            getData({ current: pagination.current, account: account, pageSize: 10 })
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        // form.resetFields();
        setIsModalVisible(false);
    };



    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        getData(newPagination)
    };


    const changeStatesFun = async (item, status) => {
        setLoading(true)
        let temp = status == 0 ? 1 : 0
        let tempObj = Object.assign(item, { status: temp })
        let rq = await merchantUpdate(tempObj)
        if (rq.code == "00000") {
            getData({ current: pagination.current, pageSize: 10 })
        }
    }


    const delFun = async (id) => {
        setLoading(true)
        let rq = await merchantDel({ id: id })
        if (rq.code == "00000") {
            getData({ current: 1, pageSize: 10 })
        }
    }



    const onTypesChange = (e) => {
        setTypes(e.target.value)
    };

    const serarchFun = (e) => {
        setAccount(e.target.value)
    }

    const handleChange = (newValue) => {
        setAgentId(newValue)
      };

    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name="merchantName">
                        <Input placeholder="请输入商户名称" allowClear />
                    </Form.Item>

                    <Form.Item name="code">
                        <Input placeholder="请输入业务订单号" allowClear />
                    </Form.Item>
                    <Form.Item name="changeType" >
                        <Select
                            style={{
                                width: 180,
                            }}
                            allowClear
                            placeholder="业务类型"
                        >
                            <Option value="1">收入</Option>
                            <Option value="2">支出</Option>
                            <Option value="3">提现</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='range-time-picker'>
                        <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" allowClear />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button  onClick={() => exportExcel('table_report', '商户资金流水')}>
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
                pagination={pagination} loading={loading} onChange={handleTableChange} />

            <Drawer title={title} width={'50%'} visible={isModalVisible} onClose={handleCancel} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={handleOk} type="primary" size='large'>
                            确定
                        </Button>
                        <Button onClick={handleCancel} htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    autoComplete="off"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    hideRequiredMark
                >

                    <div className='tag-box'>基本信息</div>

                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="商户名称"
                                name="name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="所属代理"
                                name="agentId" style={{ textAlign: 'left' }} >
                                <SearchInput
                                    value={agentId}
                                    placeholder="请输入所属代理"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        title === '新增' ?
                            <div>
                                <Row type="flex">
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户登录名"
                                            name="account">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户密码"
                                            name="password">
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="联系方式"
                                            name="contact">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            : null
                    }

                  {/* <div className='tag-box'>通道配置</div> */}
                  <div className='tag-box'>分控配置</div>
                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="单笔最大提现金额"
                                name="singleTransactionMaxWithd">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入最大提现金额!' size="large" />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="单笔最小提现金额"
                                name="singleTransactionMinWithd">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入最小提现金额!' size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="单日提现最大金额"
                                name="singleTransactionDayWithd">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入单日提现最大金额!' size="large" />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="提现费率(%)"
                                name="withdrawalExpense">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入提现费率!' size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}
