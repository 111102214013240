import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser } from "../../app/store";
import React, { useState, useEffect, useImperativeHandle, useCallback } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone, DownOutlined } from '@ant-design/icons';
import { sysUserFindPage, sysUserDel, sysUserStartAndStop, agentAdd, 
    sysUserReg, sysUserReset, resetFundPassword, resettingGoogleKey, changePassword,
    changeFundPassword } from '../../public/js/api'

import { Space, Table, Button, Form, Input, DatePicker, Drawer, Select, 
    Switch, message, Radio, Row, Col, Tag, InputNumber, Modal, Dropdown, Menu } from 'antd';
import md5 from 'md5';
const { Option } = Select;







export const UserList = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [visible, setVisible] = useState(false);
    // const [pagination, setPagination] = useState({
    //     current: 1,
    //     pageSize: 10,
    //     hideOnSinglePage: true,
    //     position: ['bottomCenter'],
    //     total: 0
    // });
    
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        // hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
        onShowSizeChange: (current, pageSize) => {
            setPagination(Object.assign(pagination, {
                current: current,
            }))
        },
        showTotal: (total, range) => `共${total}条数据`
    });

    const [userInfo, setUserInfo] = useState(useSelector(selectUser));

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState();

    const [role, setRole] = useState(0); // 默认超管
    const [roleService, setRoleService] = useState() // 客服区分

    const [flag, setFlag] = useState(false)

    const [expirationTime, setExpirationTime] = useState()
    const [etcRate, setEtcRate] = useState()
    const [trcRate, setTrcRate] = useState()
    const [btcRate, setBtcRate] = useState()
    const [ercAddress, setErcAddress] = useState()
    const [trcAddress, setTrcAddress] = useState()
    const [btcAddress, setBtcAddress] = useState()

    const [initObj, setInitObj] = useState({
        account: null,
        password: null,
        contact: null,
        types: 1
    })

    const [searchObj, setSearchObj] = useState({
        account: null,
        role: null,
        states: null
    })

    const [types, setTypes] = useState(1) // 默认代理权限基础
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentId, setId] = useState()
    const handleOk = () => {
        setIsModalOpen(false)
        if (currentId) delFun(currentId)
    }

    /// 修改密码
    // 下拉菜单
    const itemsDrop = (record) => {
        return <Menu>
            <Menu.Item key='fundPsd' onClick={() => { 
                setId(record.id)
                setPswModal(true)
            }}>修改登录密码</Menu.Item>
            { 
                record.role === 4 ?
                <>
                    <Menu.Item key='psd' onClick={() => { 
                        setId(record.id)
                        setFundPswModal(true)
                    }}>修改资金密码</Menu.Item>
                </> : null
            }
            <Menu.Item key='googleKey' onClick={() => { resetGoogleKey(record?.id) }}>重置谷歌验证码</Menu.Item>
            
        </Menu>
    }
    const [openDropId, setOpenDropId] = useState(undefined);
    const [fundPswModal, setFundPswModal] = useState(false);
    const [pswModal, setPswModal] = useState(false);
    const [fundPsdForm] = Form.useForm();
    const [psdForm] = Form.useForm();
    const onFundPsdFinish = async () => {
        await fundPsdForm.validateFields()
        const values = fundPsdForm.getFieldsValue()
        let params = {
            newPassword: md5(values.newFundPassword),
            userId: currentId
        }
        // params.usedPassword = md5(values.usedFundPassword)
        params.usedPassword = md5(values.newFundPassword)
        const hide = message.loading('密码修改中')
        setFundPswModal(false)
        fundPsdForm.resetFields()
        const rq = await changeFundPassword(params)
        hide()
        if (rq.code == "00000") {
            message.success({ content: '密码修改成功!' })
        } else {
            message.error(rq.msg)
        }
    };
    const onPsdFinish = async () => {
        await psdForm.validateFields()
        const values = psdForm.getFieldsValue()
        let params = {
            newPassword: md5(values.newPassword),
            userId: currentId
        }
        // params.usedPassword = md5(values.usedPassword)
        params.usedPassword = md5(values.newPassword)
        const hide = message.loading('密码修改中')
        setPswModal(false)
        psdForm.resetFields()
        const rq = await changePassword(params)
        hide()
        if (rq.code == "00000") {
            message.success({ content: '密码修改成功!' })
        } else {
            message.error(rq.msg)
        }
    };


    const columns = [
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '用户名称',
            dataIndex: 'account',
            key: 'account',
        },
        {
            title: '用户角色',
            dataIndex: 'role',
            key: 'role',
            render: (text, record, index) => (
                <Space size="middle">
                    {
                    record.role === 0 ? <span>超管</span> :
                        record.role === 2 ? <span>客服</span> :
                            record.role === 3 ? <span>财务</span> :
                                record.role === 4 ? <span>代理后台用户</span> :
                                    record.role === 5 ? <span>商户</span> :
                                     null}
                </Space>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '状态',
            dataIndex: 'states',
            key: 'states',
            render: (text, record, index) => (
                <Space size="middle">
                    <Switch checked={record.states === 0 ? true : false} onChange={() => changeStatesFun(record.id, record.status)} />
                </Space>
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Space size="middle">
                    {/* <a onClick={() => resetFun(record.userId ?? record.id)}>重置登录密码</a>
                    {
                        record.role === 4 || record.role === 5 ?
                        <>
                            <a onClick={() => resetFunPwd(record.id)}>重置资金密码</a>
                            <a onClick={() => resetGoogleKey(record.id)}>重置谷歌验证码</a>
                        </> : null
                    } */}
                    <Dropdown
                        overlay={() => {
                            return itemsDrop(record)
                        }}
                        onOpenChange={(flag) => {
                            flag ? setOpenDropId(record.userId) : setOpenDropId(undefined)
                        }}
                        open={openDropId == record.userId}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                权限
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <a onClick={() => {
                        setId(record.id)
                        setIsModalOpen(true)
                    }}>删除</a>
                </Space>
            ),
        },
    ];


    const showDrawer = () => {
        form.setFieldsValue({
            account: null,
            password: null,
            states: null,
            contact: null,
            role: null,
        })
        setTitle('新增')
        setRole(0)
        setTypes(1)
        setEtcRate(null)
        setTrcRate(null)
        setBtcRate(null)
        setErcAddress(null)
        setTrcAddress(null)
        setBtcAddress(null)
        setVisible(true);

    };

    const onClose = () => {
        setVisible(false);
    };



    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await sysUserFindPage({
            page: params.current,
            limit: params.pageSize,
            account: params.account,
            role: params.role,
            states: params.states
        })
        if (rq.code === "00000") {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                total: rq.data.total,
                pageSize: rq.data.size
            }))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
    }, [getData])


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    const delFun = async (id) => {
        setLoading(true)
        let rq = await sysUserDel({ id: id })
        if (rq.code == "00000") {
            getData({ current: 1, pageSize: pagination.pageSize ?? 10 })
            message.success('删除成功')
        } else {
            message.error(rq.msg ?? '删除失败')
        }
    }

    const resetFun = async (id) => {
        setLoading(true)
        let rq = await sysUserReset({ id: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '密码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }
    const resetFunPwd = async (id) => {
        setLoading(true)
        let rq = await resetFundPassword({ id: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '密码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }
    const resetGoogleKey = async (id) => {
        setLoading(true)
        let rq = await resettingGoogleKey({ userId: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '谷歌验证码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }

    const findSysUser = async (query) => {
        let role = query.role ? parseInt(query.role) : null
        let states = query.states ? parseInt(query.states) : null
        setSearchObj(Object.assign(searchObj, { role: role, states: states }))
        getData({
            current: 1,
            pageSize: pagination.pageSize ?? 10,
            account: query.account,
            role: role,
            states: states
        })
    }

    const changeStatesFun = async (id, status) => {
        setLoading(true)
        let temp = status == 0 ? 1 : 0
        let rq = await sysUserStartAndStop({ id: id, status: temp })
        if (rq.code == "00000") {
            getData({ current: pagination.current, pageSize: pagination.pageSize ?? 10 })
        }
    }

    const onFinishFun = async () => {
        if (flag) return
        try {
            await form.validateFields(['account']);
            await form.validateFields(['password']);
            await form.validateFields(['role']);
            let values = form.getFieldValue()
            let rq = null
            setFlag(true)
            values.password = md5(values.password)
            rq = await sysUserReg(values)
            if (rq.code == "00000") {
                message.success({ content: '新增成功' })
                setVisible(false)
                getData({ current: 1, pageSize: pagination.pageSize ?? 10 })
            }
            setFlag(false)
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }

    const onTimeChange = (date, dateString) => {
        setExpirationTime(dateString)
    };

    const etcRateChange = (e) => {
        setEtcRate(e.target.value)
    }
    const trcRateChange = (e) => {
        setTrcRate(e.target.value)
    }
    const btcRateChange = (e) => {
        setBtcRate(e.target.value)
    }

    const ercAddressFun = (e) => {
        setErcAddress(e.target.value)
    }
    const trcAddressFun = (e) => {
        setTrcAddress(e.target.value)
    }
    const btcAddressFun = (e) => {
        setBtcAddress(e.target.value)
    }

    const onRoleChange = (e) => {
        setRole(e.target.value);
    };

    const onRoleServiceChange = (e) => {
        setRoleService(e.target.value);
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        let temp = Object.assign(newPagination, searchObj)
        getData(temp)
    };

    const onTypesChange = (e) => {
        setTypes(e.target.value)
    };


    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={findSysUser}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name="account">
                        <Input placeholder='请输入用户名称' size='large' allowClear />
                    </Form.Item>
                    <Form.Item name="role">
                        <Select
                            style={{
                                width: 180,
                            }}
                            size={'large'}
                            allowClear
                            placeholder="用户角色"
                        >
                            {/* <Option value="0">超管</Option> */}
                            <Option value="2">客服</Option>
                            <Option value="3">财务</Option>
                            <Option value="4">代理后台用户</Option>
                            <Option value="5">商户</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="states" >
                        <Select
                            style={{
                                width: 180,
                            }}
                            size={'large'}
                            allowClear
                            placeholder="选择用户状态"
                        >
                            <Option value="0">正常</Option>
                            <Option value="1">停用</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size='large'>
                            搜索
                        </Button>
                    </Form.Item>
                    {/* <Form.Item>
            <Button size='large' onClick={onReset} >
              重置
            </Button>
          </Form.Item> */}
                    <Form.Item>
                        <Button type='primary' size='large' onClick={() => { showDrawer() }}>新增用户</Button>
                    </Form.Item>
                </Form>
            </div>

            <Table columns={columns} dataSource={data}
                rowKey={(res) => res.id}
                pagination={pagination} 
                onChange={handleTableChange} 
                loading={loading} />
            <Drawer title={`${title}用户`} width={'50%'} placement="right" onClose={onClose} visible={visible} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={onFinishFun} type="primary" size='large'>
                            确定
                        </Button>
                        <Button onClick={onClose} size='large' htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <div className=''>
                    <div className='' style={{ width: '80%' }}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinishFun}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 20,
                            }}
                            initialValues={{
                                role: role,
                                types: types
                            }}
                        >
                            <Form.Item
                                label="用户名称"
                                name="account"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名称!',
                                    },
                                ]}>
                                <Input placeholder='请输入用户名称' size='large' />
                            </Form.Item>
                            <Form.Item
                                label="用户密码"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户密码!',
                                    },
                                ]}>
                                <Input.Password size='large'
                                    placeholder="请输入用户密码"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="角色"
                                name="role"
                                style={{ textAlign: 'left' }}
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择用户角色类型!',
                                    }
                                ]}>
                                <Radio.Group onChange={onRoleChange}>
                                    <Radio value={2}>客服</Radio>
                                    <Radio value={3}>财务</Radio>
                                    <Radio value={0}>系统管理员</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="联系方式"
                                name="contact">
                                <Input placeholder='请输入联系方式' size='large' />
                            </Form.Item>
                            {/* <Form.Item wrapperCol={{ offset: 10 }}>
                                <Button type="primary" htmlType="submit" size='large' style={{ marginRight: '20px' }}>
                                    确定
                                </Button>
                            </Form.Item> */}
                        </Form>
                    </div>
                </div>
            </Drawer>

            <Modal visible={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)}>
              <p>确认删除当前账号?</p>
            </Modal>

            {/* 修改密码 */}
            <Modal visible={pswModal} 
                onOk={onPsdFinish} width='650px'
                onCancel={() => {
                    psdForm.resetFields()
                    setPswModal(false)
                }} title='修改密码'>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={psdForm}
                    autoComplete="off"
                >
                    {/* <Form.Item
                        label="原始密码"
                        name="usedPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入原始密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item> */}

                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirm"
                        dependencies={['newPassword']}
                        rules={[
                            {
                                required: true,
                                message: '请确认新密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('两次密码不一致!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                </Form>
            </Modal>

            {/* 修改资金密码 */}
            <Modal visible={fundPswModal} 
                onOk={onFundPsdFinish} width='650px'
                onCancel={() => {
                    fundPsdForm.resetFields()
                    setFundPswModal(false)
                }} title='修改资金密码'>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={fundPsdForm}
                    autoComplete="off"
                >
                    {/* <Form.Item
                        label="原始资金密码"
                        name="usedFundPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入原始资金密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item> */}

                    <Form.Item
                        label="新资金密码"
                        name="newFundPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新资金密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirm"
                        dependencies={['newFundPassword']}
                        rules={[
                            {
                                required: true,
                                message: '请确认新资金密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newFundPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('两次密码不一致!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    )
}
