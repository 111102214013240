import { withdrawAddressFindPage } from '../../public/js/api'
import { Select } from 'antd';
import React, { useState,useEffect, useCallback } from 'react';
const { Option } = Select;

let timeout;
let currentValue;

const fetch = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;

  const fake = async () => {
    let rq = await withdrawAddressFindPage({ name: value })
    if (rq.code == '00000') {
        const data = rq.data.records.map((item) => ({
            value: item.id,
            text: item.name,
        }));
        callback(data);
    }
  };

  timeout = setTimeout(fake, 300);
};

const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const valueTemp =  props.value ? `${props.value}` : null

  const [value, setValue] = useState(valueTemp);

  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };

  const getData = useCallback(async (params = {}) => {
    let rq = await withdrawAddressFindPage({name:props.value})
    if (rq.code == '00000') {
        const data = rq.data.records.map((el) => ({
            text: el.name,
            value: el.id,
        }))
       setData(data)
    }
}, [])

useEffect(() => {
    getData()
}, [getData])

  const handleChange = (newValue) => {
    setValue(newValue);
    props.onChange(Number(newValue))
  };

  const options = data.map((d) => <Option key={d.value}>{d.text}</Option>);
  return (
    <Select
      showSearch
      value={value}
      allowClear
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      size='large'
    >
      {options}
    </Select>
  );
};

export default SearchInput