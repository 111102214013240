import './systemSet.css'
import React, { useState, useEffect, useCallback } from 'react';

import { sysConfigFindPage, sysConfigUpdate } from '../../public/js/api'

import { 
  Space, Table, Tag, Button, Form, Input, Drawer, Modal, 
  Radio, message, Row, Col, Divider, Spin 
} from 'antd';

export const SystemMange = () => {
  const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
      hideOnSinglePage: true,
      position: ['bottomCenter']
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [title, setTitle] = useState('更新')
    const [form] = Form.useForm();

    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await sysConfigFindPage({ page: 1, limit: 10 })
        if (rq.code == '00000') {
          setData(rq.data.records)
          setPagination(Object.assign(pagination, {
            current: rq.data.current,
            pageSize: rq.data.size,
            total: rq.data.total
          }))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
    }, [getData])

    const handleTableChange = (newPagination, filters, sorter) => {
      getData(newPagination)
    };

    const onFinish = async () => {
      try {
        await form.validateFields()
        let temp = form.getFieldValue()
        setLoading(true)
        let rq = await sysConfigUpdate({
          id: temp.id,
          configKey: temp.configKey,
          configValue: temp.configValue,
          configDescribe: temp.configDescribe,
        })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: `${title}成功` })
            getData({ current: pagination.current, pageSize: 10 })
            setIsModalVisible(false);
        }
      } catch (error) {
        setLoading(false)
      }
    };

    const changeFn = async (params) => {
      form.setFieldsValue({
        id: params.id,
        configKey: params.configKey,
        configValue: params.configValue,
        configDescribe: params.configDescribe,
      })
      setIsModalVisible(true);
    }

    const handleCancel = () => {
      form.resetFields();
      setIsModalVisible(false);
    };

    const columns = [
      {
        title: '序号',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: '参数名',
        dataIndex: 'configDescribe',
        key: 'configDescribe',
      },
      {
        title: 'key',
        dataIndex: 'configKey',
        key: 'configKey',
      },
      {
        title: 'value',
        dataIndex: 'configValue',
        key: 'configValue',
      },
      {
        title: '操作时间',
        dataIndex: 'createTime',
        key: 'createTime',
      },
      {
        title: '操作',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <a onClick={() => changeFn(record)}>更新</a>
          </Space>
        ),
      },
    ]

    return (
        <div className='agent-page'>
            <div>
              <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
                pagination={pagination} loading={loading}  onChange={handleTableChange} />
              <Drawer title={title} width={'50%'} visible={isModalVisible} onClose={handleCancel} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={onFinish} type="primary">
                            确认
                        </Button>
                        <Button onClick={handleCancel} htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
              >
                <Form
                  name="basic"
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                >
                  <div>
                    <Form.Item label="参数名" name="configKey" rules={[{ required: true, message: '请输入参数名' }]}>
                      <Input placeholder="请输入参数名" />
                    </Form.Item>
                    <Form.Item label="key" name="configValue" rules={[{ required: true, message: '请输入key值' }]}>
                      <Input placeholder="请输入key值" />
                    </Form.Item>
                    <Form.Item label="value" name="configDescribe" rules={[{ required: true, message: '请输入value值' }]}>
                      <Input placeholder="请输入value值" />
                    </Form.Item>
                  </div>
                </Form>
              </Drawer>
            </div>
        </div>
    )
}

