import SearchInput from './SearchInput';
import './settlePayMent.less'
import moment from 'moment';
import md5 from 'md5';
import React, { useState, useEffect, useImperativeHandle, useCallback, useRef, useMemo } from 'react';

import { 
    withdrawalRecord, withdrawalRecordExam, withdrawAddressByUserId, 
    withdrawAddressById, withdrawConfirmReceipt, withdrawPaymentVoucher, adminUpdateOrder,
    adminArtificialNotify,
} from '../../public/js/api'

import {
    Space, Table, Switch, Button, Form, Input, message, Modal, Drawer, Select, Row, Col, Spin, InputNumber, Radio,
    DatePicker,
    Tag, Upload, Statistic, Image,
} from 'antd';
const { Countdown } = Statistic;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
import { LoadingOutlined, PlusOutlined,DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
dayjs.extend(weekday)
dayjs.extend(localeData)

/**
 * 
 *  订单一进来   <等待接单中>  1
    如果长时间无人接单或者未点击【锁定】，变成  <过期>  -2
    如果订单被下游取消，变成   <撤单>  -3
    如果有人接单或者点了 【锁定】，就变成   <锁定状态> -1
    如果有人付款或者点了【确认收款】  ，变成   <已确认付款> 3
    如果玩家确认确认到账（下游回传了确认付款），或者点了【确认付款】，变成 <已确认收款> 4
    超时未确认付款，变成  <系统确认收款> 5
    如果收款金额不符，变成  <确认收款金额不符>  6
    如果拒绝，变成  <拒绝> 2
 */



export const SettlePayMent = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        // pageSize: 10,
        // hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        position: ['bottomCenter'],
        onShowSizeChange: (current, pageSize) => {
            setPagination(Object.assign(pagination, {
                current: current,
            }))
        },
        showTotal: (total, range) => `共${total}条数据`
    });

    const [title, setTitle] = useState()


    const [withdrawalAccountId, setWithdrawalAccountId] = useState()

    const [change, setChange] = useState(0)

    const [address, setAddress] = useState({
        bankCard: null,
        branchCode: null,
        contact: null,
        name: null,
        payeeAccount: null,
        remarks: null,
        types: null
    })

    const [etcRate, setEtcRate] = useState()


    const [params, setParams] = useState({
        uuid: null,
        account: null,
        types: 1,

    })
    
    const dayFormat = 'YYYY-MM-DD HH:mm:ss'
    const initDateValue = [
        dayjs(new Date().toLocaleDateString() + ' 00:00:00', dayFormat), 
        dayjs(new Date().toLocaleDateString() + ' 23:59:59', dayFormat)
    ]
    const [filterParams, setFilterParams] = useState({
        current: pagination.current,
        pageSize: pagination.pageSize,
        startTime: initDateValue[0].format(dayFormat),
        endTime: initDateValue[1].format(dayFormat),
        sortColumn: 'createTime',
        sort: false,
    })

    
    let intervalHandle = useRef();

    const [types, setTypes] = useState(1)
    const getData = useCallback(async (params = {}, needLoad = true) => {
        needLoad && setLoading(true)
        // let rq = await withdrawalRecord({
        //     page: params.current,
        //     limit: params.pageSize,
        //     name: params.name,
        //     status: params.status,
        //     types: params.types,
        //     startTime: params.startTime,
        //     endTime: params.endTime
        // })
        let query = {
            page: filterParams.current,
            limit: filterParams.pageSize || pagination.pageSize,
            name: filterParams.name,
            status: filterParams.status,
            types: filterParams.types,
            startTime: filterParams.startTime,
            endTime: filterParams.endTime,
            orderCode: filterParams.orderCode,
            sortColumn: filterParams.sortColumn,
            sort: filterParams.sort,
            lastOperatorBy: filterParams.lastOperatorBy,
        }
        let rq = await withdrawalRecord(query)
        if (rq.code == '00000') {
            let records = rq.data.records;
            records.map((item, index) => {
                // item.countdown = Date.now() + 3600000
                item.countdown = new Date(item.expirationTime).getTime()
                item.number = index + 1 + (filterParams.page ?? params.current - 1) * (filterParams.limit ?? params.pageSize ?? 10)
            })
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                pageSize: rq.data.size,
                total: rq.data.total
            }))
        }
        needLoad && setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
        return () => {
            clearInterval(intervalHandle.current)
            intervalHandle.current = null
        }
    }, [getData])


    const onFinish = (fieldsValue) => {
        const rangeTimeValue = fieldsValue['range-time-picker'];
        const values = {
            ...fieldsValue,
            'startTime': rangeTimeValue && rangeTimeValue[0].format(dayFormat) || null,
            'endTime': rangeTimeValue && rangeTimeValue[1].format(dayFormat) || null
        };
        let params = {
            current: 1,
            // pageSize: 10,
            types: Number(values.types) || null,
            status: Number(values.status) || null,
            name: values.name ? values.name : null,
            startTime: values.startTime || null,
            endTime: values.endTime || null,
            orderCode: values.orderCode ? values.orderCode : null,
            lastOperatorBy: values.lastOperatorBy || null
        }
        setFilterParams(Object.assign(filterParams, params))
        getData(params)
    };

    const examFn = async (fieldsValue) => {
        try {
            await form.validateFields()
            let temp = form.getFieldValue()
            setLoading(true)
            let rq = await withdrawalRecordExam({
                id: temp.id,
                status: Number(temp.status),
                serviceCharge: temp.serviceCharge,
                payAmount: temp.payAmount,
                withdrawalAccountId: address.id,
                rejectReason: temp.rejectReason
            })
            if (rq.code == "00000") {
                message.success({ content: `${title}成功` })
                getData({ current: pagination.current, account: account, pageSize: 10 })
                setIsModalVisible(false);
            }
        } catch (error) {

        }

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const changeFn = async (params) => {
        setParams(params)
        let rs = await withdrawAddressById({ id: params.withdrawalAccountId }, {
            content: `收款单号: ${params.code}, 操作: 查询收款地址信息`
        })
        let rq = rs.data
        if (rq) {
            setAddress({
                id: params.id,
                contact: rq.contact,
                name: rq.name,
                payeeAccount: rq.payeeAccount,
                remarks: rq.remarks,
                types: rq.types,
                bankCard: rq.bankCard,
                branchCode: rq.branchCode,
                withdrawalAccountId: params.withdrawalAccountId
            })
        }
        setChange(0)
        setIsModalVisible(true);
        setTitle('修改')
    }
    const showFn = async (params) => {
        setParams(params)
        // let rs = await withdrawAddressById({ id: params.withdrawalAccountId })
        // let rq = rs.data
        setAddress({
            // contact: rq.contact,
            // name: rq.name,
            // payeeAccount: rq.payeeAccount,
            // remarks: rq.remarks,
            // types: rq.types,
            // bankCard: rq.bankCard,
            // branchCode: rq.branchCode,

            name: params.payeeName,
            // payeeAccount: params.merchantAccount,
            types: params.types,
            bankCard: params.bankCard,
            id: params.id,
            withdrawalAccountId: params.withdrawalAccountId,
            bankName: params.bankName,
            payAmount: params.money,
            paymentVoucher: params.paymentVoucher
        })
        form.setFieldsValue({
            id: params.id,
            status: `${params.status}`
        })
        setWithdrawalAccountId(params.withdrawalAccountId)
        setIsModalVisible(true);
        setTitle('查看')
    }

    const adminConfirm = async (params) => {
        let rs = await withdrawConfirmReceipt({ orderCode: params.code }, {
            content: `收款单号: ${params.code}, 操作: 确认收款`
        })
        if (rs.code === '00000') {
            message.success({ content: `收款成功` })
            getData({ current: pagination.current, account: account, pageSize: 10 })
        }
    }

    /// 倒计时结束
    const countdownFinished = () => {
        console.log('finished!');
    };
    /// 排序
    const [sortedInfo, setSortedInfo] = useState({
        columnKey: 'createTime',
        order: 'descend'
    });
    const setSort = (columnKey, order) => {
        if (columnKey === 'money' || columnKey === 'createTime') {
            setSortedInfo({
                //   order: isDescend ? 'descend' : 'ascend',
                order,
                columnKey,
            });
            let sorterData = {}
            sorterData = order === 'ascend' 
                ? {
                    sortColumn: columnKey,
                    sort: true
                } 
                : order === 'descend' 
                ? {
                    sortColumn: columnKey,
                    sort: false
                } : {
                    sortColumn: null,
                    sort: null
                }
            setFilterParams(Object.assign(filterParams, sorterData));
        }
    };

    /// 人工回调
    const adminArtificialNotifyFn = async (record) => {
        setLoading(true)
        let rs = await adminArtificialNotify({orderId: record.id}, {
            content: `收款单号: ${record.code}, 操作: 人工回调`
        }) 
        setLoading(false)
        if (rs.code === '00000') {
            message.success({ content: '回调成功' })
            getData({ current: pagination.current, account: account, pageSize: 10 })
        }
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'number',
            key: 'number',
            width: 50,
        },
        {
            title: '提现类型',
            dataIndex: 'types',
            key: 'types',
            width: 90,
            render: (text) => (
                <Space size="middle">
                    {
                        text === 1 ? <Tag color='#108ee9'>商户提现</Tag> :
                            text === 2 ? <Tag>代理提现</Tag> :
                                null
                    }
                </Space>
            ),
        },
        {
            title: '系统订单号',
            dataIndex: 'sysCode',
            key: 'sysCode',
            width: 100,
        },
        {
            title: '收款单号',
            dataIndex: 'code',
            key: 'code',
            width: 100,
        },
        {
            title: '付款单号',
            dataIndex: 'rechargeCode',
            key: 'rechargeCode',
            width: 100,
        },
        {
            title: '接单商名称',
            dataIndex: 'lockUserName',
            key: 'lockUserName',
            width: 80,
        },
        {
            title: '结算商名称',
            dataIndex: 'userName',
            key: 'userName',
            width: 80,
        },
        {
            title: '通道',
            dataIndex: 'currency',
            key: 'currency',
            width: 70,
        },
        {
            title: '申请提现金额',
            dataIndex: 'money',
            key: 'money',
            width: 100,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'money' ? sortedInfo.order : null,
        },
        {
            title: '手续费',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
            width: 80,
        },
        {
            title: '实际打款金额',
            dataIndex: 'payAmount',
            key: 'payAmount',
            width: 90,
        },
        {
            title: '结算状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                <Space size="middle">
                    {  withdrawStatus(text) }
                </Space>
            ),
            width: 100,
        },
        {
            title: '结算时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 110,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'createTime' ? sortedInfo.order : null,
        },
        {
            title: '倒计时',
            dataIndex: 'countdown',
            key: 'countdown',
            width: 110,
            render: (text, record, index) => (
                <Space size="middle">
                    {  
                        record.status !== -1 ?
                        <Countdown title="" value={text} onFinish={countdownFinished} 
                            valueStyle={{
                                color: '#fff',
                                backgroundColor: '#333',
                                borderRadius: 3,
                                padding: "0 5px",
                                fontSize: 18,
                                boxShadow: '0px 1px 11px 2px rgba(0,0,0,.3)'
                            }} /> : null
                    }
                </Space>
            ),
        },
        {
            title: '操作人',
            dataIndex: 'lastOperatorBy',
            key: 'lastOperatorBy',
            width: 80,
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, id) => (
                <Space size="middle">
                    <Button size='small' htmlType="button" type='primary' onClick={() => changeFn(record)} disabled={record.status === 0 ? false : true}>审核</Button>
                    <Button size='small' type="primary" htmlType="button" 
                        disabled={record.status === -1 || record.status === -2 || record.status === 3 || record.status === 4 ? false : true}  
                        onClick={() => adminArtificialNotifyFn(record)}
                    >人工回调</Button>
                    {/* <a onClick={() => delFun(record.id)}>删除</a> */}
                    {
                        record.status === 1 
                        ? <Button size='small' type="primary" htmlType="button" onClick={() => adminUpdateOrderFn(record)}>锁单</Button>
                        : record.status === -1 
                            ? <Button size='small' type="primary" htmlType="button" onClick={() => adminUpdateOrderFn(record)}>解单</Button>
                            : null
                    }
                    {
                        record.status === 3
                            ? <Button size='small' type="primary" htmlType="button" onClick={() => adminConfirm(record)}>确认收款</Button>
                            : record.status === -1 
                            ? <Button size='small' type="primary" htmlType="button" onClick={() => {
                                paymanVoucher.setFieldsValue({
                                    code: record.code,
                                    rechargeCode: record.rechargeCode,
                                    file: record.paymentVoucher ? record.paymentVoucher : null,
                                    // file: 'https://images.haozigo.com/cary_test/2023-07-25/2023072533459402.jpg',
                                })
                                console.log('确认付款:', record);
                                setModalVisible(true)
                            }}>确认付款</Button>
                            : null
                    }
                    <a onClick={() => showFn(record)}>详情</a>
                </Space>
            ),
        },
    ];
    
    const withdrawStatus = (status) => {
        let tag = <Tag color='error'>锁定</Tag>
        switch (status) {
            // case 0:
            //     tag = <Tag color='warning'>申请中</Tag>
            //     break;
            case 1:
                // tag = <Tag color='warning'>通过未打款</Tag>
                tag = <Tag color='warning'>等待接单</Tag>
                break;
            case 2:
                tag = <Tag color='error'>拒绝</Tag>
                break;
            case 3:
                tag = <Tag color='success'>已打款</Tag>
                break;
            case 4:
                tag = <Tag color='success'>已确认收款</Tag>
                break;
            case 5:
                tag = <Tag color='success'>系统确认收款</Tag>
                break;
            case 6:
                tag = <Tag color='success'>收款金额不符</Tag>
                break;
            case -2:
                tag = <Tag color='warning'>过期</Tag>
                break;
            case -3:
                tag = <Tag color='warning'>撤单</Tag>
                break;
            case -1:
                break;
            default:
                break;
        }
        return tag;
    }
    const onChange = (e) => {
        setChange(e.target.value);
        if (e.target.value === 1) {
            handleSerachAddressFn(form.getFieldValue('userId'))
        } else {
            // 切换原来的钱包id
            let initAddressId = form.getFieldValue('withdrawalAccountId')
            handleChange(initAddressId)
        }
    };

    const [isModalVisible, setIsModalVisible] = useState(false);



    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };




    const handleTableChange = (newPagination, filters, sorter) => {
        setFilterParams(Object.assign(filterParams, {
            current: newPagination.current,
            pageSize: newPagination.pageSize
        }));
        setSort(sorter.columnKey, sorter.order)
        getData(newPagination)
    };

    // 提现收款地址账号
    const [addOptions, setOptions] = useState([])

    const handleSerachAddressFn = async (userId) => {

        let rq = await withdrawAddressByUserId({ userId: userId })
        if (rq.code == '00000') {
            const data = rq.data.map((item) => ({
                value: item.id,
                text: item.name,
            }));
            setOptions(data)
        }

    }

    const handleChange = async (newValue) => {
        if (newValue) {
            setWithdrawalAccountId(newValue)
            let rs = await withdrawAddressById({ id: newValue })
            let rq = rs.data
            setAddress({
                id: rq.id,
                contact: rq.contact,
                name: rq.name,
                payeeAccount: rq.payeeAccount,
                remarks: rq.remarks,
                types: rq.types,
                bankCard: rq.bankCard,
                branchCode: rq.branchCode
            })
        }
    };

    // 管理员修改订单
    const adminUpdateOrderFn = async (record) => {
        if (record.status !== 1 && record.status !== -1) return
        let status = record.status === 1 ? -1 : 1
        setLoading(true)
        let rs = await adminUpdateOrder({status: status, id: record.id}, {
            content: `收款单号: ${record.code}, 操作: ${record.status === 1 ? '锁单' : '解单'}`
        }) 
        setLoading(false)
        if (rs.code === '00000') {
            message.success({ content: record.status === 1 ? `已锁单` : '已解单' })
            getData({ current: pagination.current, account: account, pageSize: 10 })
        }
    }

    /// 复制
    const [copyStatus, setCopyStatus] = useState(false)
    function copyToClipboard(content = '') {
        copyToClipboardEx(content)
          .then((res, rej) => setCopyStatus(true))
          .finally(() => setTimeout(() => setCopyStatus(false), 1500));
    }
    async function copyToClipboardEx(content = '') {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(content);
          message.success({ content: `复制成功` })
        } else {
          // 非 HTTPS 连接下无法使用 navigator.clipboard
          const textArea = document.createElement("textarea");
          textArea.value = content;
          textArea.style.position = "absolute";
          textArea.style.left = "-999999px";
      
          document.body.prepend(textArea);
          textArea.select();
          try {
            document.execCommand("copy");
            message.success({ content: `复制成功` })
          } catch (error) {
            console.error(error);
            message.success({ content: `复制失败` })
          } finally {
            textArea.remove();
          }
        }
    }

    /// 确认付款
    const [modalVisible, setModalVisible] = useState(false);
    const adminPaymentVoucher = async (params, content) => {
        let temp = paymanVoucher.getFieldValue()
        setLoading(true)
        let rs = await withdrawPaymentVoucher(params, content) 
        setLoading(false)
        // paymanVoucher.setFieldsValue({
        //     code: temp.code,
        //     rechargeCode: temp.rechargeCode,
        //     file: null
        // })
        paymanVoucher.setFieldsValue(temp, {file: null})
        setFileList([])
        if (rs.code === '00000') {
            message.success({ content: `付款成功` })
            getData({ current: pagination.current, account: account, pageSize: 10 })
        }
    }
    const [paymanVoucher] = Form.useForm();
    const [fileList, setFileList] = useState([])
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            let temp = paymanVoucher.getFieldValue()
            paymanVoucher.setFieldsValue({
                code: temp.code,
                rechargeCode: temp.rechargeCode,
                file: null
            })
            // paymanVoucher.setFieldsValue(temp, {file: null})
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            let temp = paymanVoucher.getFieldValue()
            // paymanVoucher.setFieldsValue({
            //     code: temp.code,
            //     rechargeCode: temp.rechargeCode,
            //     file: file
            // })
            paymanVoucher.setFieldsValue(temp, {file: file})
            return false;
        },
        fileList,
        listType: "picture-card",
        onChange: ({ fileList: newFileList }) => setFileList(newFileList),
        onPreview: () => {},
    };
    const handleUpload = async () => {
        let flag = await paymanVoucher.validateFields()
        let temp = paymanVoucher.getFieldValue()
        // paymanVoucher.setFieldsValue({
        //     code: temp.code,
        //     rechargeCode: temp.rechargeCode,
        //     file: temp.file
        // })
        if (typeof temp.file == 'string') {
            let data = new FormData()
            data.append('orderCode', temp.code)
            data.append('rechargeCode', temp.rechargeCode)
            adminPaymentVoucher(data, {
                content: `收款单号: ${temp.code}, 操作: 确认付款`
            })
        } else {
            const reader = new FileReader();
            if (fileList.length <= 0) return;
            const file = fileList[0].originFileObj
            reader.readAsDataURL(file);
            reader.onload = () => {
                let data = new FormData()
                // const imgBlob = new Blob([reader.result], { type: file.type })
                // data.append('file', imgBlob, file.name)
                data.append('file', file)
                data.append('orderCode', temp.code)
                data.append('rechargeCode', temp.rechargeCode)
                adminPaymentVoucher(data, {
                    content: `收款单号: ${temp.code}, 操作: 确认付款`
                })
            }
            reader.onerror = (error) => reject(error);
            setModalVisible(false)
        }
    };

    // 自动刷新
    const reloadList = [
        { label: '自动刷新选择', value: 0 },
        { label: '3秒', value: 3 },
        { label: '5秒', value: 5 },
        { label: '10秒', value: 10 },
        { label: '20秒', value: 20 },
        { label: '30秒', value: 30 },
        { label: '1分钟', value: 60 },
        { label: '2分钟', value: 120 }
    ]
    const selectReload = (value, option) => {
        clearInterval(intervalHandle.current);
        intervalHandle.current = null;
        if (!value) return;
        intervalHandle.current = setInterval(() => {
            getData({}, false)
        }, value * 1000);
    }

    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name='name' style={{ marginBottom: 5 }}>
                        <Input placeholder="结算商名称" />
                    </Form.Item>
                    <Form.Item name='orderCode'>
                        <Input placeholder="订单号" />
                    </Form.Item>
                    <Form.Item name='lastOperatorBy'>
                        <Input placeholder="操作人" />
                    </Form.Item>
                    <Form.Item name="types">
                        <Select
                            placeholder="提现类型"
                            allowClear
                            style={{ width: '219px' }}

                        >
                            <Option value="1">商户提现</Option>
                            <Option value="2">代理提现</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="status">
                        <Select
                            placeholder="结算状态"
                            allowClear
                            style={{ width: '219px' }}

                        >
                            {/* <Option value="0">申请中</Option> */}
                            <Option value="1">等待接单</Option>
                            <Option value="2">拒绝</Option>
                            <Option value="3">已打款</Option>
                            <Option value="4">已确认收款</Option>
                            <Option value="5">系统确认收款</Option>
                            <Option value="6">收款金额不符</Option>
                            <Option value="-1">锁单</Option>
                            <Option value="-2">过期</Option>
                            <Option value="-3">撤单</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='range-time-picker' initialValue={initDateValue}>
                        <RangePicker
                            defaultValue={initDateValue}
                            format={dayFormat} 
                            allowClear />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </Form.Item>
                </Form>
                <div style={{
                    display: "block",
                    marginTop: 15,
                    textAlign: "left"
                }}>
                    <Select
                        placeholder="自动刷新选择"
                        // allowClear
                        style={{ width: '219px' }}
                        defaultValue={reloadList[0].label}
                        onChange={selectReload}
                    >
                        {
                            reloadList.map((item, index) => {
                                return <Option key={item.value} value={item.value}>{item.label}</Option>
                            })
                        }
                    </Select>
                </div>
            </div>
            <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
                pagination={pagination} loading={loading} 
                onChange={handleTableChange}
                scroll={{
                    y: 800
                }}
            />

            <Drawer title={title} width={'50%'} open={isModalVisible} onClose={handleCancel} destroyOnClose={true}
                footer={
                    <Space>
                        {
                            title != '查看' ?
                                <Button onClick={examFn} type="primary">
                                    确定
                                </Button>
                                : null
                        }
                        <Button onClick={handleCancel} htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    {
                        title != '查看' ?
                            <div>
                                <Form.Item label="申请提现金额" name="money" >
                                    <InputNumber style={{ width: '100%' }} disabled />
                                </Form.Item>
                                <Form.Item label="实际打款金额" name="payAmount" rules={[{ required: true, message: '请输入实际打款金额' }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item label="提现手续费" name="serviceCharge" rules={[{ required: true, message: '请输入提现手续费' }]}>
                                    <InputNumber min={0} style={{ width: '100%' }} max={100} />
                                </Form.Item>
                                <Form.Item
                                    label="是否更改打款账号"
                                    name="change"
                                    style={{ textAlign: 'left' }}
                                >
                                    <Radio.Group onChange={onChange}>
                                        <Radio value={0}>否</Radio>
                                        <Radio value={1}>是</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {
                                    change === 1 ?
                                        <Form.Item label="选择更改收款账号" name="name" style={{ textAlign: 'left' }}>
                                            <Select
                                                allowClear
                                                placeholder="请输入收款账号"
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={handleChange}
                                            >
                                                {
                                                    addOptions.map((d) => <Option key={d.value}>{d.text}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        : null
                                }
                                <Form.Item name="status" label="业务状态">
                                    <Select
                                        style={{
                                            width: 180,
                                        }}
                                        allowClear
                                        placeholder="业务状态"
                                    >
                                        <Option value="0">申请中</Option>
                                        {/* <Option value="1">通过未打款</Option> */}
                                        <Option value="2">拒绝</Option>
                                        <Option value="3">已打款</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('status') === '2' ? (
                                            <Form.Item name="rejectReason" label='拒绝备注' rules={[{ required: true, message: '请输入拒绝原因' }]}>
                                                <TextArea rows={4} />
                                            </Form.Item>
                                        ) : null
                                    }
                                </Form.Item>
                            </div>
                            : null
                    }
                </Form>

                <div>
                    <Row type='flex' justify='space-between' className='row-item'>
                        <Col span={12}>
                            账号类型
                        </Col>
                        <Col span={12}>
                            {
                                address.types === 1 ? '银行卡' : '区块链钱包'
                            }
                        </Col>
                    </Row>
                    <Row type='flex' justify='space-between' className='row-item'>
                        <Col span={12}>
                            姓名
                        </Col>
                        <Col span={12}>
                            {address.name}
                        </Col>
                    </Row>
                    {/* <Row type='flex' justify='space-between' className='row-item'>
                        <Col span={12}>
                            联系方式
                        </Col>
                        <Col span={12}>
                            {address.contact}
                        </Col>
                    </Row> */}
                    {/* <Row type='flex' justify='space-between' className='row-item'>
                        <Col span={12}>
                            收款账号
                        </Col>
                        <Col span={12}>
                            {address.payeeAccount}
                        </Col>
                    </Row> */}
                    <Row type='flex' justify='space-between' className='row-item'>
                        <Col span={12}>
                            金额
                        </Col>
                        <Col span={12}>
                            {address.payAmount}
                        </Col>
                    </Row>
                    {
                        address.types === 1 ?
                            <div>
                                <Row type='flex' justify='space-between' className='row-item'>
                                    <Col span={12}>
                                        银行类型
                                    </Col>
                                    <Col span={12}>
                                        {address.bankName}
                                    </Col>
                                </Row>
                                <Row type='flex' justify='space-between' className='row-item row-item-last'>
                                    <Col span={12}>
                                        银行卡号
                                    </Col>
                                    <Col span={12}>
                                        {address.bankCard}
                                    </Col>
                                </Row>
                                {/* <Row type='flex' justify='space-between' className='row-item'>
                                    <Col span={12}>
                                        分行代码
                                    </Col>
                                    <Col span={12}>
                                        {address.branchCode}
                                    </Col>
                                </Row> */}
                            </div>
                            : null
                    }
                    {/* <Row type='flex' justify='space-between' className='row-item row-item-last'>
                        <Col span={12}>
                            转账描述
                        </Col>
                        <Col span={12}>
                            {address.remarks}
                        </Col>
                    </Row> */}
                    <Row>
                        {
                            address.paymentVoucher ?
                                <Col span={12} style={{ paddingTop: 20 }}>
                                    <div>支付凭证</div>
                                    <Image
                                        width={200}
                                        src={address.paymentVoucher}
                                        placeholder={
                                            <Image
                                                preview={false}
                                                src={address.paymentVoucher}
                                                width={200}
                                            />
                                        }
                                    />
                                </Col> : null
                        }
                        <Col span={address.paymentVoucher ? 12 : 24} style={{ textAlign: 'right', marginTop: 20 }}>
                            <Button htmlType="button" type='primary' onClick={() => copyToClipboard(
                                `
                                    姓名: ${address.name ?? ''};
                                    金额: ${address.payAmount ?? ''};
                                    银行类型: ${address.bankName ?? ''};
                                    银行卡号: ${address.bankCard ?? ''};
                                    ${address.paymentVoucher ? '支付凭证: ' + address.paymentVoucher + ';' : ''}
                                `
                            )}>一键复制</Button>
                        </Col>
                    </Row>
                </div>
            </Drawer>

            <Modal
                title="确认付款"
                centered
                open={modalVisible}
                onOk={handleUpload}
                onCancel={() => {
                    setFileList([])
                    let temp = paymanVoucher.getFieldValue()
                    paymanVoucher.setFieldsValue(temp, {file: null})
                    setModalVisible(false)
                }}
            >
                <Form
                    name="basic"
                    form={paymanVoucher}
                    layout="vertical"
                    autoComplete="off"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    <div>
                        <Form.Item label="订单编号" name="code" >
                            <InputNumber style={{ width: '100%' }} disabled />
                        </Form.Item>
                        <Form.Item label="充值单号" name="rechargeCode"  rules={[{ required: true, message: '请输入充值单号' }]}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="图片上传" name="file" rules={[{ required: true, message: '请上传图片' }]}>
                            {
                                typeof paymanVoucher.getFieldValue().file == 'string'
                                ? <Image
                                    width={100}
                                    height={100}
                                    style={{ objectFit: 'contain' }}
                                    src={paymanVoucher.getFieldValue().file}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={paymanVoucher.getFieldValue().file}
                                        />
                                    }
                                />
                                : <Upload
                                    {...props}
                                >
                                    {
                                    fileList.length >= 1 ? 
                                        null : 
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>上传</div>
                                        </div>
                                    }
                                </Upload>
                            }
                            
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </div >
    )
}
