import React ,{ useState,useCallback,useEffect }from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../app/store';
// import { Link } from 'react-router-dom'
import { Button, Checkbox, Form, Input, message,Spin } from 'antd';

import { useNavigate } from "react-router-dom";

import { Login,findSysContact, findUserInfo } from '../../public/js/api';

import { setUserFun } from './../../public/js/user'

import './login.less'

import logo_left from './../../assets/img/logo_left.png'

import Cookies from 'js-cookie'

import md5 from 'md5'


export const LoginFun = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 1,
    });
    const [loading, setLoading] = useState(false);
    const [contact,setContact] = useState()

    const onFinish = async (values) => {
        let password = md5(values.password)
        console.log('onFinish', values, password);
        values.password = password
        setLoading(true)
        let rq = await Login(values)
        if (rq.code === "00000") {
            dispatch(setUser(rq.data))
            Cookies.set('token', rq.data.token)
            navigate('/')
        }
        setLoading(false)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await findSysContact({ page: params.current, limit:params.pageSize})
        if (rq.code == '00000') {
             for(let item of rq.data){
                if(item.types === 1){
                    setContact(item.contact)
                }
             }
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        // getData(pagination)
    }, [getData])


    const linkTo = () =>{
        let temp = null
        if (contact) {
            if (/^(@|＠)+/.test(contact)) {
                temp = contact.substring(1)
            } else {
                temp = contact
            }
            let protoUrl = `https://t.me/${temp}`;
            window.open(protoUrl, '_blank')
        } else {
            message.error({ content: "当前没有设置联系方式" })
        }
    }


    return (
        <div className='loginPage'>
            <div className='rowBox'>
                <div className='rowBoxAll'>
                    <div className='loginLeft'>
                        <img src={logo_left} />
                    </div>
                    <div className='loginRight'>
                        <Spin spinning={loading}>
                        <div className='formBox'>
                            <div>
                                <div className='tag'><p>支付超管后台登录</p></div>
                                <div>
                                    <div className='formBox-box'>
                                        <Form
                                            name="basic"
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                name="account"
                                                rules={[{ required: true, message: '请输入账号!' }]}
                                            >
                                                <Input placeholder='请输入账号' size='large' />
                                            </Form.Item>


                                            <Form.Item
                                                name="password"
                                                rules={[{ required: true, message: '请输入密码!' }]}
                                            >
                                                <Input.Password placeholder='请输入密码' size='large' />
                                            </Form.Item>


                                            <Form.Item
                                                name="code"
                                                rules={[{ required: false, message: '请输入谷歌验证码!' }]}
                                            >
                                                <Input placeholder='请输入谷歌验证码' size='large' />
                                            </Form.Item>

                                            <Form.Item >
                                                <Button type="primary" htmlType="submit" block size='large' >
                                                    登录
                                                </Button>
                                            </Form.Item>
                                            {/* <p style={{color: '#4862ee',marginTop:'-10px'}}　onClick={()=>{linkTo()}}>忘记密码?</p> */}
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}
