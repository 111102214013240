import './DailyShow.css'
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from "../../app/store";
import { sysFindPage, sysAdd, sysServiceFindPage } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, DatePicker, Drawer, Row, Col, Divider } from 'antd';
const { RangePicker } = DatePicker;






export const DailyShow = () => {
  const [userInfo, setUserInfo] = useState(useSelector(selectUser));
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
  });
  const [visible, setVisible] = useState(false);
  const [sysObj, setSysObj] = useState()

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const getData = useCallback(async (params = {}) => {
    setLoading(true)
    let tempApi = sysFindPage
    if (userInfo.role != 0) {
      tempApi = sysServiceFindPage
    }

    let rq = await tempApi({
      page: params.current,
      limit: params.pageSize,
      operationModule: params.operationModule,
      operator: params.operator,
      startTime: params.startTime,
      endTime: params.endTime
    })
    if (rq.code === "00000") {
      setData(rq.data.records)
      setPagination(Object.assign(pagination, {
        current: rq.data.current,
        pageSize: rq.data.size,
        total: rq.data.total
      }))
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    getData(pagination)
  }, [getData])

  const onFinish = (fieldsValue) => {
    const rangeTimeValue = fieldsValue['range-time-picker'];

    // getDate(params)

    const values = {
      ...fieldsValue,
      'startTime': rangeTimeValue && rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss') || null,
      'endTime': rangeTimeValue && rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss') || null
    };
    let params = {
      current: 1,
      pageSize: 10,
      operator: values.operator,
      operationModule: values.operationModule,
      startTime: values.startTime,
      endTime: values.endTime
    }
    getData(params)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showFn = (record) => {
    setSysObj(record)
    showDrawer()
  }


  const handleTableChange = (newPagination, filters, sorter) => {
    getData(newPagination)
  };

  const columns = [
    {
      title: '序号',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      key: 'operator',
    },
    {
      title: '操作模块',
      dataIndex: 'operationModule',
      key: 'operationModule',
    },
    {
      title: '操作类型',
      dataIndex: 'operationType',
      key: 'operationType',
      render: (text, record, index) => (
        <span>{ text === 0 ?'登录': text === 1 ? '增加' : text === 2 ? "删除" : text === 3 ? "修改" :''}</span>
      )
    },
    {
      title: '操作内容',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => showFn(record)}>查看</a>
        </Space>
      ),
    },
  ];


  return (
    <div className='agent-page daily-show'>
      <div className='agent-search-box'>
        <Form
          name="basic"
          layout="inline"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="operationModule">
            <Input placeholder="请输入操作模块" size='large' allowClear />
          </Form.Item>
          <Form.Item name="operator">
            <Input placeholder="请输入操作人" size='large' allowClear />
          </Form.Item>
          <Form.Item name='range-time-picker'>
            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" size='large' allowClear />
          </Form.Item>

          <Form.Item
          >
            <Button type="primary" htmlType="submit" size='large'>
              搜索
            </Button>
          </Form.Item>
          {/* <Form.Item
          >
            <Button  htmlType="submit" size='large'>
              重置
            </Button>
          </Form.Item> */}
        </Form>
      </div>
      <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
        pagination={pagination} loading={loading}  onChange={handleTableChange} />

      <Drawer width={'50%'} placement="right" onClose={onClose} visible={visible}>
        {
          sysObj ?
            <div style={{ lineHight: '40px' }}>
              <Row className='row-box'>
                <Col span={12}><Space><Tag color="#108ee9">操作模块:</Tag>{sysObj.operationModule}</Space> </Col>
                <Col span={12}><Space><Tag color="#108ee9">请求地址:</Tag>{sysObj.url}</Space></Col>
              </Row>
              <Row className='row-box'>
                <Col span={12}><Space><Tag color="#108ee9">登录信息:</Tag>{sysObj.types === 1 ? '超管' : sysObj.types === 2 ? '客服' : sysObj.types === 3 ? '代理' : null}</Space></Col>
                <Col span={12}><Space><Tag color="#108ee9">请求方式:</Tag>{sysObj.mode === 1 ? 'get' : sysObj.mode === 2 ? 'post' : sysObj.mode === 3 ? 'put' : 'delete'}</Space></Col>
              </Row>
              <Row >
                <Col><Tag color="#108ee9">请求参数:</Tag></Col>
                <Col span={20}>{sysObj.request}</Col>
              </Row>
              <Row className='row-box'>
                <Col><Tag color="#108ee9">返回参数:</Tag>{sysObj.response}</Col>
              </Row>
              <Row className='row-box'>
                <Col span={12}><Space><Tag color="#108ee9">操作状态:</Tag>{sysObj.status ==='00000'?'success':'error'}</Space></Col>
                <Col span={12}><Space><Tag color="#108ee9">操作时间:</Tag>{sysObj.createTime}</Space></Col>
              </Row>
            </div>
            : null
        }


      </Drawer>


    </div>
  )
}
