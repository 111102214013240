import './noticeAdd.css'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import React, { useState, useEffect, useImperativeHandle, useCallback } from 'react';
import { noticeDelete, noticeFindPage,noticeAdd,noticeUpdate } from '../../public/js/api'

import { Space, Table, Button, Form, Input,  Drawer,  message } from 'antd';








export const NoticeList = () => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    const [title, setTitle] = useState()
    const [types, setTypes] = useState(0) // 默认发送全部
    const [visible, setVisible] = useState(false);
    const [details,setDetails] = useState()
    const [id,setId] = useState()

    const [flag,setFlag] = useState()

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage:true,
        position: ['bottomCenter']
    });
    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await noticeFindPage({ page: params.current, limit: params.pageSize, title: params.title, types: params.types, })
        if (rq.code == "00000") {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                pageSize: rq.data.size,
                total: rq.data.total
            }))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
    }, [getData])



    const columns = [
        {
            title: '序号',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: '公告标题',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <Space size="middle">
                    <a onClick={() => updateNotice(record)}>编辑</a>
                    <a onClick={() => delFun(record.id)}>删除</a>
                </Space>
            ),
        },
    ];

    const delFun = async (id) => {
        setLoading(true)
        let rq = await noticeDelete({ id: id })
        if (rq.code === '00000') {
            message.success({ content: "删除成功" })
            getData({current:pagination.current,pageSize:pagination.pageSize})
        }
        setLoading(false)
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        getData(newPagination)
    };

    const onFormChange = ({ title }) => {
        console.log(title);
        // console.log(types);
    }

    const onFinish = (values) => {
        getData(Object.assign({ current: 1, pageSize: pagination.pageSize }, values))
    };


    const updateNotice = (record) => {
        setVisible(true);
        setTitle('编辑')
        setId(record.id)
        form.setFieldsValue({title:record.title})
        form.setFieldsValue({details: BraftEditor.createEditorState(record.details)})
    }



    const onFinishFun = async (values) => {
        values.details = values.details.toHTML()
        values.types = 0 // 默认全部
        let rq = null
        setLoading(true)
        if(flag) return
        setFlag(true)
        if(title === '编辑'){
            values.id = id
            rq = await noticeUpdate(values)
            if (rq.code =="00000") {
                message.success({ content: '修改成功' })
                setVisible(false)
                setFlag(false)
            }
        }else if(title === '新增'){
            rq = await noticeAdd(values)
            if (rq.code =="00000") {
                message.success({ content: '新增成功' })
                setVisible(false)
                setFlag(false)
            }
        }
     
        setLoading(false)
        getData({current:pagination.current,pageSize:pagination.pageSize})
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onClose = () => {
        setVisible(false);
    };
    
    const showDrawer = () =>{
        setVisible(true);
        setTitle('新增')
        form.setFieldsValue({title:null})
        form.setFieldsValue({details: BraftEditor.createEditorState(null)})
    }

    return (
        <div className='notice-page'>
            <div className='my-form'>
                <Form
                    layout='inline'
                    initialValues={{
                        title: title,
                        types: types
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item name="title">
                        <Input placeholder="请输入公告标题" size='large' allowClear />
                    </Form.Item>
                    {/* <Form.Item name="types">
                        <Select className='select-box' placeholder="请选择发送位置" size='large' allowClear>
                            <Select.Option value="0">全部</Select.Option>
                            <Select.Option value="1">系统后台</Select.Option>
                            <Select.Option value="2">代理后台</Select.Option>
                        </Select>
                    </Form.Item> */}
                    <Form.Item >
                        <Button type="primary" size='large' htmlType='submit'>搜索</Button>
                    </Form.Item>
                    <Form.Item >
                        <Button size='large' onClick={()=>{showDrawer()}}>新增</Button>
                    </Form.Item>
                </Form>
            </div>
            <Table loading={loading} columns={columns} dataSource={data} rowKey={(res) => res.id} pagination={pagination} onChange={handleTableChange} />
            <Drawer title={`${title}公告`} width={1000} placement="right" onClose={onClose} visible={visible}>
                <div className='agent-add-page'>
                    <div className='' style={{ width: '80%' }}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinishFun}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            labelCol={{
                                span: 4,
                            }}
                            initialValues={{
                                title: title,
                                types: types,
                                details: details
                            }}>
                            <Form.Item
                                label="公告标题"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入公告标题!',
                                    },
                                ]}>
                                <Input placeholder='请输入公告标题' size='large' />
                            </Form.Item>
                            {/* <Form.Item
                                label="发送位置"
                                name="types" style={{ textAlign: 'left' }}>
                                <Radio.Group>
                                    <Radio value={0}>全部</Radio>
                                    <Radio value={1}>后台系统</Radio>
                                    <Radio value={2}>代理系统</Radio>
                                </Radio.Group>
                            </Form.Item> */}
                            <Form.Item label="内容" name='details'>
                                <BraftEditor
                                    className="my-editor"
                                    // controls={controls}
                                    placeholder="请输入正文内容"
                                />
                            </Form.Item>
                            <Form.Item wrapperCol={{offset:12}}>
                                <Button type="primary" htmlType="submit" size='large' style={{ marginRight: '20px' }}>
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
