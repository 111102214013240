import moment from 'moment'
import React, { useState, useEffect, useImperativeHandle } from 'react';

import { findPlatformRevenueDetails, countFindPage, findOrderStatistics } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, DatePicker, Drawer, message, Select } from 'antd';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
dayjs.extend(weekday)
dayjs.extend(localeData)
const { RangePicker } = DatePicker;

const { Option } = Select
import './CountIncome.css'


const InfoCard = ({ title, index, desc }) => {
  return (
    <div
      key={index}
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 4px 0 rgba(35,49,128,0.02), 0 4px 8px 0 rgba(49,69,179,0.02)',
        borderRadius: '8px',
        fontSize: '14px',
        color: 'rgba(0,0,0,0.65)',
        textAlign: 'justify',
        lineHeight: ' 22px',
        flex: 1,
      }}
      className='box'
    >
      <div
        style={{
          display: 'flex',
          gap: '16px',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div className='boxImgBox' />
        <div
          style={{
            fontSize: '14px',
            fontWeight: '600px',
            paddingBottom: 8,
            color: '#677392'
          }}
        >
          {title}
        </div>
      </div>
      <div
        style={{
          fontSize: '14px',
          color: 'rgba(0,0,0,0.65)',
          textAlign: 'justify',
          lineHeight: '22px',
          marginBottom: 8,
        }}
      >
        {desc}
      </div>
    </div>
  );
}


export const CountIncome = () => {
  const [form] = Form.useForm();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [data, setData] = useState({})
  const [dataDetail, setDataDetail] = useState([])
  const [wallet, setWallet] = useState()
  const [page] = useState(1)
  const [limit] = useState(10)
  const [dataTime, setDataTime] = useState(moment().format('YYYY-MM-DD'))
  const [visible, setVisible] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
  });

  const dayFormat = 'YYYY-MM-DD HH:mm:ss'
  const initDateValue = [
    dayjs(new Date().toLocaleDateString() + ' 00:00:00', dayFormat),
    dayjs(new Date().toLocaleDateString() + ' 23:59:59', dayFormat)
  ]

  const [subPage, setSubPage] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
  });
  const [loading, setLoading] = useState(false);


  const columns = [
    {
      title: '代理名称',
      dataIndex: 'name',
      key: 'name'
    },
    // {
    //   title: '通道',
    //   dataIndex: 'currency',
    //   key: 'currency'
    // },
    {
      title: '总收益',
      dataIndex: 'totalIncome',
      key: 'totalIncome '
    },
    {
      title: '总交易金额',
      dataIndex: 'totalTransactionAmount',
      key: 'totalTransactionAmount'
    },
    {
      title: '总交易数量',
      dataIndex: 'totalTransactionNum',
      key: 'totalTransactionNum',
    },
    // {
    //   title: '操作',
    //   key: 'action',
    //   render: (text, record, index) => (
    //     <Space size="middle">
    //       <a onClick={() => findDetails(record)}>收益明细</a>
    //     </Space>
    //   ),
    // },
  ];

  const columns2 = [
    // {
    //   title: '钱包地址ID',
    //   dataIndex: 'walletId',
    //   key: 'walletId',
    // },
    {
      title: '通道',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: '交易类型',
      dataIndex: 'types',
      key: 'types',
      render: (text, record, index) => (
        <span>{record.types === 1 ? '代理划账' : record.types === 2 ? '平台划账' : null}</span>
      )
    },
    {
      title: '转出账户总金额',
      dataIndex: 'accountOutTotalMoney',
      key: 'accountOutTotalMoney',
    },
    {
      title: '转出金额',
      dataIndex: 'amountOut',
      key: 'amountOut',
    },
    {
      title: '转出账户',
      dataIndex: 'accountOut',
      key: 'accountOut',
      width: 200,
      render: (text, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '转入金额',
      dataIndex: 'amountInto',
      key: 'amountInto',
    },
    {
      title: '转入账户',
      dataIndex: 'accountInto',
      key: 'accountInto',
      width: 200,
      render: (text, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '平台收取手续费金额',
      dataIndex: 'amountCharge',
      key: 'amountCharge',
    },
    {
      title: '平台收费账户',
      dataIndex: 'accountCharge',
      key: 'accountCharge',
      width: 200,
      render: (text, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    }

  ];



  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const findDetails = async (arg) => {
    // setLoading(true)
    // let rq = await findPlatformRevenueDetails({ page: arg.current, limit: arg.pageSize, walletId: arg, dataTime: dataTime })
    // if(rq.code ==="00000"){
    //   setDataDetail(rq.data.transactionRecordPage.records)
    //   setWallet(rq.data.walletAuxiliary)
    //   setVisible(true)
    // }
    // setLoading(false)
  }

  useEffect(() => {
    async function fn() {
      // getData({ current: 1, pageSize: 10, dateTime: moment().format('YYYY-MM-DD') })
      getData({ current: 1, pageSize: 10, startTime: initDateValue[0].format(dayFormat), endTime: initDateValue[1].format(dayFormat) })
    }
    fn()
  }, [])

  const onChange = (date, dateString) => {
    setDataTime(dateString)
  };

  // const getData  = async (params) => {
  //   setLoading(true)
  //   let rq = await countFindPage({
  //     currency: params.currency,
  //     page: params.current,
  //     limit: params.pageSize,
  //     name: params.name,
  //     startTime: params.startTime,
  //     endTime: params.endTime
  //   })
  //   if(rq.code =='00000'){
  //     setData(rq.data.records)
  //     setPagination(Object.assign(pagination, {
  //         current: rq.data.current,
  //         pageSize: rq.data.size,
  //         total: rq.data.total
  //     }))
  //   }
  //   setLoading(false)
  // }
  const getData = async (params) => {
    setLoading(true)
    let rq = await findOrderStatistics({
      startTime: params.startTime,
      endTime: params.endTime
    })
    if (rq.code == '00000') {
      if (rq.data && rq.data.length > 0) {
        const inData = rq.data.filter((item) => item.orderType == 1)[0]
        const outData = rq.data.filter((item) => item.orderType == 2)[0]
        setData({ inData, outData })
      }
      setPagination(Object.assign(pagination, {
        current: rq.data.current,
        pageSize: rq.data.size,
        total: rq.data.total
      }))
    }
    setLoading(false)
  }

  const onFinish = (fieldsValue) => {
    const rangeTimeValue = fieldsValue['range-time-picker'];
    const values = {
      ...fieldsValue,
      'startTime': rangeTimeValue && rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss') || null,
      'endTime': rangeTimeValue && rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss') || null
    };
    values.current = 1
    values.pageSize = 10
    // let params = {
    //   current: 1,
    //   startTime: rangeTimeValue.startTime,
    //   endTime: rangeTimeValue.endTime
    // }
    getData(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    setDataTime(null)
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    getData(newPagination)
  };

  const handleChange = (newPagination, filters, sorter) => {
    findDetails(newPagination)
  };




  return (
    <div className='agent-page'>
      <div className='agent-search-box'>
        <Form
          name="basic"
          layout="inline"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            dataTime: moment()
          }}
        >
          {/* <Form.Item name='name'>
             <Input placeholder="请输入代理名称"  allowClear   />
          </Form.Item>
          <Form.Item name="currency" >
            <Select
              style={{
                width: 180,
              }}
              allowClear
              placeholder="通道"
            >
              <Option value="USDT">USDT</Option>
              <Option value="人民币">人民币</Option>
            </Select>
          </Form.Item>
          <Form.Item name="types" >
            <Select
              style={{
                width: 180,
              }}
              allowClear
              placeholder="提现类型"
            >
              <Option value="1">充值</Option>
              <Option value="2">提现</Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item name='range-time-picker'>
            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" allowClear />
          </Form.Item> */}
          <Form.Item name='range-time-picker' initialValue={initDateValue}>
            <RangePicker
                initialValues={initDateValue}
                format={dayFormat} 
                allowClear />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" >
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div
        key="center"
        style={{
          display: 'flex',
          gap: 16,
        }}
      >
        <InfoCard
          index={1}
          title='充值总额'
          key='inData1'
          desc={data?.inData?.totalMoney}
        />
        <InfoCard
          index={2}
          title='充值手续费'
          key='inData2'
          desc={data?.inData?.totalCommission}
        />
        <InfoCard
          index={3}
          title='充值统计'
          key='inData3'
          desc={data?.inData?.totalNum}
        />
      </div>
      <div
        key="center2"
        style={{
          display: 'flex',
          gap: 16,
          marginTop: 10,
        }}
      >
        <InfoCard
          index={1}
          title='提现总额'
          key='outData1'
          desc={data?.outData?.totalMoney}
        />
        <InfoCard
          index={2}
          title='提现手续费'
          key='outData2'
          desc={data?.outData?.totalCommission}
        />
        <InfoCard
          index={3}
          title='提现统计'
          key='outData3'
          desc={data?.outData?.totalNum}
        />
      </div>
      {/* <Table columns={columns} dataSource={data} rowKey={(res) => res.userId}
        pagination={pagination} loading={loading} onChange={handleTableChange}  /> */}
      {/* <Drawer   title="收益详细" width={'80%'} placement="right" onClose={onClose} visible={visible}>
          <div style={{marginBottom:'20px'}}>
            <b>助记词</b> {wallet}
          </div>
          <Table columns={columns2} dataSource={dataDetail}  rowKey={(res) => res.id}
           pagination={subPage} loading={loading} onChange={handleChange} />
      </Drawer> */}
    </div>
  )
}
