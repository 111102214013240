import { createSlice, configureStore } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: JSON.parse(localStorage.getItem('userInfo')) || null,
        currentPage: JSON.parse(sessionStorage.getItem('currentPage') || null),
        currentArr: JSON.parse(sessionStorage.getItem('currentArr')) || []
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
            localStorage.setItem('userInfo',JSON.stringify(action.payload))
        },
        setNowPage:(state, action) => {
            state.currentPage = action.payload
            sessionStorage.setItem('currentPage',JSON.stringify(action.payload))
        },
        setCurrentArr:(state, action) => {
            state.currentArr = action.payload
            sessionStorage.setItem('currentArr',JSON.stringify(action.payload))
        },
    }

})

export const store = configureStore({
    reducer: userSlice.reducer
})


export const { setUser,setNowPage,setCurrentArr } = userSlice.actions

export const selectUser = (state) => state.user
export const selectCurrentPage = (state) => state.currentPage
export const selectCurrentArr = (state) => state.currentArr