import moment from 'moment'
import React, { useState, useEffect, useImperativeHandle } from 'react';

import { findPlatformRevenuePage, withdrawalRecord, countOutFindPage } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, DatePicker, Drawer, message,Select } from 'antd';

const { RangePicker } = DatePicker;

const { Option} = Select




export const CountOut = () => {
  const [form] = Form.useForm();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [data, setData] = useState([])
  const [dataDetail, setDataDetail] = useState([])
  const [wallet, setWallet] = useState()
  const [page] = useState(1)
  const [limit] = useState(10)
  const [dataTime, setDataTime] = useState(moment().format('YYYY-MM-DD'))
  const [visible, setVisible] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
  });

  const [subPage, setSubPage] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
  });
  const [loading, setLoading] = useState(false);


  const columns = [
    {
      title: '结算商名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '提现类型',
      dataIndex: 'types',
      key: 'types',
      render: (text) => (
        <Tag>
          {
            text === 1 ? '商户提现' : '代理提现'
          }
        </Tag>
      ),
    },
    // {
    //   title: '通道',
    //   dataIndex: 'currency',
    //   key: 'currency',
    // },
    {
      title: '结算金额',
      dataIndex: 'totalTransactionAmount',
      key: 'totalTransactionAmount',
    },
    {
      title: '手续费',
      dataIndex: 'totalIncome',
      key: 'totalIncome',
    },
    {
      title: '提现笔数',
      dataIndex: 'totalTransactionNum',
      key: 'totalTransactionNum',
    },
    // {
    //   title: '操作',
    //   key: 'action',
    //   render: (text, record, index) => (
    //     <Space size="middle">
    //       <a onClick={() => findDetails(record.userId)}>结算明细</a>
    //     </Space>
    //   ),
    // },
  ];

  const columns2 = [
    {
      title: '结算日期',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '结算单号',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '结算商名称',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: '通道',
      dataIndex: 'currency',
      key:'currency',
    },
    {
      title: '结算金额',
      dataIndex: 'money',
      key: 'money',
    },
    {
      title: '手续费',
      dataIndex: 'serviceCharge',
      key: 'serviceCharge',
    }
  ];



  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const findDetails = async (arg) => {
    setLoading(true)
    let rq = await withdrawalRecord({ page: arg.current, limit: arg.pageSize, id: arg })
    if (rq.code === "00000") {
      setDataDetail(rq.data.records)
      setVisible(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    async function fn() {
      getData({ current: 1, pageSize: 10, dateTime: moment().format('YYYY-MM-DD') })
    }
    fn()
  }, [])

  const onChange = (date, dateString) => {
    setDataTime(dateString)
  };

  const getData = async (params) => {
    setLoading(true)
    let rq = await countOutFindPage({
      page: params.current,
      limit: params.pageSize,
      name: params.name,
      startTime: params.startTime,
      endTime: params.endTime
    })
    if (rq.code == '00000') {
      setData(rq.data.records)
      setPagination(Object.assign(pagination, {
        current: rq.data.current,
        pageSize: rq.data.size,
        total: rq.data.total
      }))
    }
    setLoading(false)
  }

  const onFinish = (fieldsValue) => {
    let params = {
      current: 1,
      pageSize: 10,
      name: fieldsValue.name,
      types:Number(fieldsValue.types)
    }
    getData(params)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    setDataTime(null)
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    getData(newPagination)
  };

  const handleChange = (newPagination, filters, sorter) => {
    findDetails(newPagination)
  };




  return (
    <div className='agent-page'>
      <div className='agent-search-box'>
        <Form
          name="basic"
          layout="inline"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item name='name'>
             <Input placeholder="请输入结算商名称"  allowClear   />
          </Form.Item>
          <Form.Item name="types" >
            <Select
              style={{
                width: 180,
              }}
              allowClear
              placeholder="提现类型"
            >
              <Option value="1">商户提现</Option>
              <Option value="2">代理提现</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" >
              搜索
            </Button>
          </Form.Item>
          {/* <Form.Item
          >
            <Button  onClick={onReset}>
              重置
            </Button>
          </Form.Item> */}
        </Form>
      </div>
      <Table columns={columns} dataSource={data} rowKey={(res) => res.userId}
        pagination={pagination} loading={loading} onChange={handleTableChange} />
      <Drawer title="收益详细" width={'80%'} placement="right" onClose={onClose} visible={visible}>
        <Table columns={columns2} dataSource={dataDetail} rowKey={(res) => res.id}
          pagination={subPage} loading={loading} onChange={handleChange} />
      </Drawer>
    </div>
  )
}
