import SearchInput from '../SearchInput';
import ExportJsonExcel from 'js-export-excel'
import moment from 'moment';
import md5 from 'md5';
import React, { useState, useEffect, useImperativeHandle, useCallback, useRef, useMemo } from 'react';

import { merchantFindPage, merchantDel, merchantAdd, merchantOrderFindPage, merchantUpdate } from '../../public/js/api'

import {
    Space, Table, Switch, Button, Form, Input, message, Modal, Drawer, Select, Row, Col, Spin, InputNumber,
    DatePicker,
    Tag
} from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;







export const MerchantOrderTake = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
        position: ['bottomCenter']
    });

    const [title, setTitle] = useState()


    const [agentId, setAgentId] = useState()

    const [etcRate, setEtcRate] = useState()


    const [params, setParams] = useState({
        uuid: null,
        account: null,
        types: 1,

    })

    const [types, setTypes] = useState(1)

    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await merchantOrderFindPage({ 
            page: params.current,
            limit: params.pageSize,
            orderCode: params.orderCode,
            status: params.status,
            startTime: params.startTime,
            endTime: params.endTime,
            orderType: 2  // 提现
         })
        if (rq.code == '00000') {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                pageSize: rq.data.size,
                total: rq.data.total
            }))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
    }, [getData])




    const onFinish = (fieldsValue) => {
        const rangeTimeValue = fieldsValue['range-time-picker'];
    
        const values = {
          ...fieldsValue,
          'startTime': rangeTimeValue && rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss') || null,
          'endTime': rangeTimeValue && rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss') || null
        };
        let params = {
          current: 1,
          pageSize: 10,
          status: values.status,
          orderCode: values.orderCode,
          startTime: values.startTime,
          endTime: values.endTime
        }
        getData(params)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const changeFn = (params) => {
        setParams(params)
        form.setFieldsValue({
            name: params.name,
            agentId: params.agentId,
            currencyRatePayment: params.currencyRatePayment,
            currencyRateCollection: params.currencyRateCollection,
            singleTransactionMaxWithd: params.singleTransactionMaxWithd,
            singleTransactionMinWithd: params.singleTransactionMinWithd,
            singleTransactionDayWithd: params.singleTransactionDayWithd,
            withdrawalExpense: params.withdrawalExpense
        })
        setAgentId(params.agentId)
        setIsModalVisible(true);
        setTitle('修改')
    }


    const columns = [
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '所属代理',
            dataIndex: 'agentName',
            key: 'agentName',
            render: (text,) => (
                <div>
                    {
                        text ? text : <Tag>-</Tag>
                    }
                </div>
            ),
        },
        // {
        //     title: '商品ID',
        //     dataIndex: 'goodsId',
        //     key: 'goodsId',
        // },
        {
            title: '订单号',
            dataIndex: 'orderCode',
            key: 'orderCode',
        },
        // {
        //     title: '商品订单号',
        //     dataIndex: 'goodsName',
        //     key: 'goodsName',
        // },
        // {
        //     title: '商品名称',
        //     dataIndex: 'goodsName',
        //     key: 'goodsName',
        // },
        // {
        //     title: '订单价',
        //     dataIndex: 'orderType',
        //     key: 'orderType',
        // },
        {
            title: '三方订单号',
            dataIndex: 'payOrderCode',
            key: 'payOrderCode',
        },
        {
            title: '通道',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: '订单金额',
            dataIndex: 'orderMoney',
            key: 'orderMoney',
        },
        {
            title: '手续费',
            key:'sysCommissionMoney',
            render: (text, record) => (
                <div>
                    {
                        record.sysCommissionMoney + record.agentCommissionMoney
                    }
                </div>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                <Space size="middle">
                    {
                        text === 0 ? <Tag color='#f50'>未支付</Tag> :
                            text === 1 ? <Tag color="#108ee9">成功</Tag> :
                                <Tag>失败</Tag>
                    }
                </Space>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        // {
        //     title: '操作',
        //     key: 'action',
        //     render: (text, record, id) => (
        //         <Space size="middle">
        //             <a>查看</a>
        //         </Space>
        //     ),
        // },
    ];


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setTitle('新增')
        form.setFieldsValue({
            agentId: null,
            name: null,
            currencyRatePayment: null,
            currencyRateCollection: null,
            singleTransactionMaxWithd: null,
            singleTransactionMinWithd: null,
            singleTransactionDayWithd: null
        })
        setAgentId(null)
        setIsModalVisible(true);
    };

    const handleOk = async (e) => {
        let temp = form.getFieldValue()
        if (title === '新增') {
            let sysUser = {
                account: temp.account,
                contact: temp.contact,
                password: md5(temp.password),
                states: 0,
                role: 5 // 商户
            }
            delete temp.account
            delete temp.password
            delete temp.contact
            temp.sysUser = sysUser
        }
        temp.agentId = agentId
        temp.status = 0 // 默认正常
        temp.currencyRatePayment = '{klzf:[Paytm:{USD:0.001,INR:0.001},Uip:{USD:0.002,INR:0.002}}]}'
        temp.currencyRateCollection = '{klzf:[Paytm:{USD:0.001,INR:0.001},Uip:{USD:0.002,INR:0.002}}]}'
        setLoading(true)
        let rq = null
        if (title === '新增') {
            rq = await merchantAdd(temp)
        } else {
            rq = await merchantUpdate(temp)
        }
        if (rq.code == "00000") {
            message.success({ content: `${title}成功` })
            getData({ current: 1, account: account, pageSize: 10 })
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        // form.resetFields();
        setIsModalVisible(false);
    };

  

    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        getData(newPagination)
    };


    const changeStatesFun = async (item, status) => {
        setLoading(true)
        let temp = status == 0 ? 1 : 0
        let tempObj = Object.assign(item, { status: temp })
        let rq = await merchantUpdate(tempObj)
        if (rq.code == "00000") {
            getData({ current: pagination.current, pageSize: 10 })
        }
    }


    const delFun = async (id) => {
        setLoading(true)
        let rq = await merchantDel({ id: id })
        if (rq.code == "00000") {
            getData({ current: 1, pageSize: 10 })
        }
    }



    const onTypesChange = (e) => {
        setTypes(e.target.value)
    };

    const serarchFun = (e) => {
        setAccount(e.target.value)
    }

    const handleChange = (newValue) => {
        setAgentId(newValue)
    };
    const exportExcel = (id, name) => {
        let filter = [], header = []
        for (let i = 0; i < columns.length; i++) {
            let temp = columns[i]
            filter.push(temp.dataIndex)
            header.push(temp.title)
        }
        let dataTemp = JSON.parse(JSON.stringify(data))
        // for (let i = 0; i < dataTemp.length; i++) {
        //     let temp = dataTemp[i]
        //     if (temp.changeType === 1) {
        //         dataTemp[i].changeType = '提现'
        //     } else {
        //         dataTemp[i].changeType = '充值'
        //     }
        // }
        // 直接导出文件
        let dataTable = [];  //excel文件中的数据内容
        let option = {};  //option代表的就是excel文件
        dataTable = dataTemp;  //数据源
        option.fileName = name;  //excel文件名称
        console.log("data===", dataTable)
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: 'Sheet1',  //excel文件中sheet页名称
                sheetFilter: filter,  //excel文件中需显示的列数据
                sheetHeader: header,  //excel文件中每列的表头名称
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }

    const onStatusChange = (value) => { };

    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    name=''
                    >
                        <Input placeholder="请输入商户名称"  />
                    </Form.Item>
                    <Form.Item name='orderCode'>
                        <Input placeholder="请输入商户订单号"  allowClear />
                    </Form.Item>
                    <Form.Item name="status">
                        <Select
                            placeholder="状态"
                            onChange={onStatusChange}
                            allowClear
                            style={{ width: '219px' }}
                            
                        >
                            <Option value="0">未支付</Option>
                            <Option value="1">成功</Option>
                            <Option value="2">失败</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='range-time-picker'>
                        <RangePicker showTime format="YYYY-MM-DD HH:mm:ss"  allowClear />
                    </Form.Item>
                    <Form.Item
>
                        <Button type="primary" htmlType="submit" >
                            搜索
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit"  onClick={() => exportExcel('table_report', '提现订单')}>
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
                pagination={pagination} loading={loading} onChange={handleTableChange} />

            <Drawer title={title} width={'50%'} visible={isModalVisible} onClose={handleCancel} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={handleOk} type="primary" >
                            确定
                        </Button>
                        <Button onClick={handleCancel}  htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    autoComplete="off"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    hideRequiredMark
                >

                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="商户名称"
                                name="name">
                                <Input  />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="所属代理"
                                name="agentId" style={{ textAlign: 'left' }} >
                                <SearchInput
                                    value={agentId}
                                    placeholder="请输入所属代理"
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        title === '新增' ?
                            <div>
                                <Row type="flex">
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户登录名"
                                            name="account">
                                            <Input  />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="用户密码"
                                            name="password">
                                            <Input.Password  />
                                        </Form.Item>
                                    </Col>
                                    <Col span='12'>
                                        <Form.Item
                                            label="联系方式"
                                            name="contact">
                                            <Input  />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            : null
                    }


                    {/* <div>考拉支付平台</div> */}

                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="单笔最大提现金额"
                                name="singleTransactionMaxWithd">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入最大提现金额!' size="large" />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="单笔最小提现金额"
                                name="singleTransactionMinWithd">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入最小提现金额!' size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="单日提现最大金额"
                                name="singleTransactionDayWithd">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入单日提现最大金额!' size="large" />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="提现费率(%)"
                                name="withdrawalExpense">
                                <InputNumber style={{ width: '100%' }} placeholder='请输入提现费率!' size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}
