import React, { useState, useEffect, useImperativeHandle,useCallback } from 'react';

import { walletInsert,findWalletPage,walletStartAndStop,walletDellet,walletUpdate} from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input,DatePicker,Drawer,Select,Switch,message} from 'antd';
const { Option } = Select;
const { TextArea } = Input;

// import './agentPage.css'






export const WalletAddress = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([])
  const [page] = useState(1)
  const [limit] = useState(10)
  const [visible, setVisible] = useState(false);
  const [states, setStates] = useState(0);

  const [id, setId] = useState();

  const [types, setTypes] = useState(null)

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState();

  const [address, setAddress] = useState()

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    position: ['bottomCenter']
});

const [initObj,setInitObj] = useState({
        types: null,
        address:null,
        states: null
})
 
  const columns = [
    {
        title: '序号',
        key: 'num',
        render: (text,record,index) =>(
          <span>{index+1}</span>
        )
    },
    {
      title: '钱包类型',
      dataIndex: 'type',
      key: 'type',
      render: (text,record,index) => (
        <Space size="middle">
          {
            record.type === 1 ?
              <span>TRX</span> :
              record.type === 2 ?
                <span>ETH</span> :
                <span>BTC</span>
          }
        </Space>
      
      )
    },
    {
      title: '钱包地址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '状态',
      dataIndex: 'states',
      key: 'states',
      render: (text,record,index) => (
        <Space size="middle">
          <Switch checked={record.states === 0  ? true : false} onChange={()=>changeStatesFun(record.id,record.states)} />
        </Space>
      ),
    },
      {
        title: '操作',
        key: 'action',
        render: (text,record,index) => (
          <Space size="middle">
            <a onClick={()=>editFun(record)}>编辑</a>
            <a onClick={()=>delFun(record.id)}>删除</a>
          </Space>
        ),
      },
  ];


  const showDrawer = () => {
    setTitle('新增')
    form.setFieldsValue({ address: null })
    form.setFieldsValue({ states: null})
    form.setFieldsValue({ type: null})
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setTypes(null)
    setInitObj({
      types: null,
      address:null,
      states: null
    })
  };

  const handleChange = (value) => {
    setTypes(value)
  };


  const getData = useCallback(async (params = {}) => {
    setLoading(true)
    let rq = await findWalletPage({ page:params.current,limit:params.pageSize,types: params.types})
    if(rq.code =='00000'){
      setData(rq.data.records)
      setPagination(Object.assign(pagination, {
          current: rq.data.current,
          pageSize: rq.data.size,
          total: rq.data.total
      }))
    }
    setLoading(false)
}, [])

useEffect(() => {
    getData(pagination)
}, [getData])


  const onFinishFun = async (values) => {
    values.types = Number(values.types)
    values.states = states
    let rq = null
    if(title == '新增'){
      rq = await walletInsert(values)
    }else {
      values.id = id
      rq = await walletUpdate(values)
    }
    if(rq){
      let temp = title == '新增' ? '新增成功': '编辑成功'
      message.success({content: temp})
      setVisible(false)
      getData({current:pagination.current,pageSize:10})
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    // setType(null)
  };

  const handleChangeFun = (checked) => {
    if(checked){
      setStates(0)
    }else {
      setStates(1)
    }
  };

  const delFun = async (id) => {
    setLoading(true)
    let rq = await walletDellet({id:id})
    if(rq.code =="00000") {
      // findWalletFun({types:Number(types)})
      getData({current:1,pageSize:10})
    }
    console.log(rq);
  }

  const findWalletFun = async (query) => {
    console.log(query);
    let params = {
      current:1,pageSize:10
    }
    if(query.types){
      params.types = Number(query.types)
    }
      getData(params)
  }

  const changeStatesFun = async (id,states) => {
       setLoading(true)
       let rq = await walletStartAndStop({id:id})
       if(rq.code =="00000"){
          getData({current:pagination.current,pageSize:pagination.pageSize})
       }
  }

  const editFun = async (record) =>{
    setVisible(true)
    setTitle('编辑')
    form.setFieldsValue({ address: record.address })
    form.setFieldsValue({ states: record.states })
    form.setFieldsValue({ type: `${record.type}` })
    setId(record.id)
  }
  const handleTableChange = (newPagination, filters, sorter) => {
    getData(newPagination)
  };
 
  return (
    <div className='agent-page'>
      <div className='agent-search-box'>
        <Form
          name="basic"
          layout="inline"
          onFinish={findWalletFun}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="types" defaultValue={types}>
            <Select
              style={{
                width: 180,
              }}
              size={'large'}
              allowClear
              placeholder="选择钱包类型"
              onChange={handleChange}
            >
              <Option value="1">TRX</Option>
              <Option value="2">ETH</Option>
              <Option value="3">BTC</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size='large'>
              搜索
            </Button>
          </Form.Item>
          {/* <Form.Item>
            <Button size='large' onClick={onReset} >
              重置
            </Button>
          </Form.Item> */}
          <Form.Item>
          <Button size='large' onClick={()=>{showDrawer()}}>新增钱包地址</Button>
          </Form.Item>
        </Form>
      </div>
      <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
        pagination={pagination}  loading={loading} onChange={handleTableChange}  />
      <Drawer title={`${title}钱包`} width={800} placement="right" onClose={onClose} visible={visible}>
        <div>
          <Form
            name="basic"
            form={form}
            onFinish={onFinishFun}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{
              address: address,
              states: states,
              type: types
            }}
          >
            <Form.Item
              label="钱包类型"
              name='type'
              rules={[
                {
                  required: true,
                  message: '请输入钱包类型!',
                },
              ]}>
              <Select
                style={{
                  width: 180,
                }}
                size={'large'}
                placeholder="选择钱包类型"
                onChange={handleChange}
              >
                <Option value="1">TRX</Option>
                <Option value="2">ETH</Option>
                <Option value="3">BTC</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="钱包地址"
              name="address"
              rules={[
                {
                  required: true,
                  message: '请输入钱包地址',
                },
              ]}>
              <TextArea rows={4}  />
            </Form.Item>
            {/* <Form.Item label="状态" name="states">
                <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={states == 0 ? true : false} checked={states == 0 ? true : false} size="large" onChange={handleChangeFun} />
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" size='large' style={{marginRight:'20px'}}>
                确定
              </Button>
              {/* <Button size='large' onClick={onClose} >
                取消
              </Button> */}
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </div>
  )
}
