import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import React, { useState } from 'react';
import { LoginFun } from './container/login/login'
import { Main } from './container/Main/Main';
import { ConfigProvider } from 'antd'

import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
// moment.locale('en');
moment.locale('zh-cn');

function App() {
  const [locale, setLocal] = useState(zhCN);
  return (
    <ConfigProvider locale={locale}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/login" element={<LoginFun />} />
            <Route exact path="/" element={<Main />} />
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
