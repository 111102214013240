import { Button, Form, Input, message,Spin } from 'antd';
import md5 from 'md5';
import React, { useState, useEffect } from 'react';
import { changePassword } from './../../public/js/api'







export const ChangePwd = () => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
            setLoading(true)
            values.account = JSON.parse(userInfo).account;
            values.userId = JSON.parse(userInfo).id
            values.usedPassword = md5(values.usedPassword)
            values.newPassword = md5(values.newPassword)
            let rq = await changePassword(values)
            if (rq.code == "00000") {
                message.success({ content: '密码修改成功!' })
            }
            setLoading(false)
        }
    };



    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className='info' style={{ width: '600px' }}>
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="原始密码"
                        name="usedPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入原始密码',
                            },
                        ]}
                    >
                        <Input.Password size='large' />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码',
                            },
                        ]}
                    >
                        <Input.Password size='large' />
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirm"
                        dependencies={['newPassword']}
                        rules={[
                            {
                                required: true,
                                message: '请确认新密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('两次密码不一致!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size='large' />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" size='large'>
                            确定
                        </Button>
                    </Form.Item>


                </Form>
            </Spin>
        </div>
    )
}
