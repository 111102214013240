import { LoadingOutlined, PlusOutlined,DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Upload ,Spin, Modal, Image} from 'antd';
import React, { useState,useEffect } from 'react';
import { setUserInfo,findUserInfo,getGoogleKey } from './../../public/js/api'
import axios from 'axios';
import { selectUser, setUser} from "../../app/store";
import { useSelector, useDispatch } from 'react-redux';

import './info.less'

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
};


export const Info = () => {

    const [form] = Form.useForm();

    const [user, setUserObj] = useState(useSelector(selectUser));
    
    const dispatch = useDispatch()


    const   onFinish = (values) => {
        values.headImg = imageKey
        values.code = code
        let userInfoTemp = setUserInfo(values)
        if(userInfoTemp){
            message.success('修改成功!');
            findUserInfoFn()
        }
        
    };

    const findUserInfoFn = async () =>{
        let rq = await findUserInfo({id:user.id})
        if (rq.code === "00000") {
            setImageUrl(rq.data.headImg)
            form.setFieldsValue({
                account: rq.data.account,
                nickName: rq.data.nickName,
                contact: rq.data.contact,
                headImg: rq.data.headImg,
                code: rq.data.code
            });
            dispatch(setUser(rq.data))
        }
        setLoading(false)
    }
    
  useEffect(() => {
    async function getUseInfo() {
        setLoading(true)
        findUserInfoFn()
    }
    getUseInfo()
  }, [])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [imageKey, setImageKey] = useState();
    const [code,setCode] = useState()
    const [googleKey, setGoogleKey] = useState('')

    const [headImg, setHeadImg] = useState()

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const upload = async (content) => {
        let formData = new FormData
        formData.append('file', content.file)
        let config = {
            'Content-Type': 'multipart.form-data'
        }
        const res = await axios.post('/prod-api/upload/v1/file', formData, config)
        setImageUrl(res.data.url)
        setImageKey(res.data.key)
        setLoading(false)
        return res;
    }


    const [fileList, setFileList] = useState([]);

    const onChange = async ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };


    const delImgFun = (e) =>{
        //del avatar
        setLoading(false)
        setImageUrl(null)
    }

    const [modalVisible, setModalVisible] = useState(false);
    const codeFun = async () => {
        // setUserObj(Object.assign(user, {googleKey: code}));
        // console.log('user', user);
        // return
        setLoading(true)
        let code = await getGoogleKey({ id: user.id, account: user.account })
        setLoading(false)
        if (code.code == '00000') {
            console.log('谷歌验证:', code);
            // setCode(code.data)
            setGoogleKey(code.data)
            setModalVisible(true)
        } else {
            message.error(code.msg ?? '谷歌验证码获取失败')
        }
    }

    return (
        <Spin spinning={loading}>
            <div className='info' style={{ width: '600px' }}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="账号名称"
                        name="account"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: '请输入账号名称',
                        //     },
                        // ]}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="用户昵称"
                        name="nickName"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: '请输入用户昵称',
                        //     },
                        // ]}
                    >
                        <Input value={user.nickName} />
                    </Form.Item>

                    <Form.Item
                        label="联系方式"
                        name="contact"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: '请输入您的联系方式!',
                        //     },
                        // ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="角色"
                        name="role"
                    >
                        <div style={{ textAlign: 'left' }}> {user.role === 0 ? '超管' : user.role === 1 ? "客服" : user.role === 2 ? "代理" : null}</div>
                    </Form.Item>


                    <Form.Item
                        label="头像"
                        name="headImg"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: '请上传您的头像!',
                        //     },
                        // ]}
                    >
                        <div className='avatar-box'>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={upload}
                                action="#"
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {
                                    imageUrl ?
                                        <div >
                                            <div>
                                                <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        : (
                                            uploadButton
                                        )
                                }
                            </Upload>
                            {
                                imageUrl ?
                                    <div className='avatar-del' onClick={() => delImgFun()}>
                                        <DeleteOutlined />
                                    </div>
                                    : null
                            }
                        </div>
                    </Form.Item>

                    <Form.Item
                        label={user.googleKey || code ? "Google验证码" : "Google二维码"}
                        name="code"
                    >
                        <div>
                            {/* {user.code || code ? <Input value={user.code || code} style={{ width: '200px' }} disabled /> :
                                <Button type="primary" onClick={() => { codeFun() }} >
                                    点击生成谷歌验证码
                                </Button>
                            } */}
                            {user.googleKey || code ? <Input value={user.googleKey || code} style={{ width: '200px' }} readOnly /> :
                                googleKey ? <Image
                                    width={150}
                                    height={150}
                                    src={googleKey}
                                /> :
                                <Button type="primary" onClick={() => { codeFun() }} >
                                    点击生成谷歌验证码
                                </Button>
                            }
                        </div>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>


                </Form>










                {/* <Button type="primary" onClick={() => { codeFun() }} >
                                    点击生成谷歌验证码
                                </Button> */}













                <Modal
                    title="Google验证绑定"
                    centered
                    visible={modalVisible}
                    onOk={() => {
                        setModalVisible(false)
                    }}
                    onCancel={() => {
                        setModalVisible(false)
                    }}
                >
                    <Form
                        name="basic"
                        autoComplete="off"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    >
                        <div>
                            <p style={{color: '#777'}}>用Google身份验证器扫描下方二维码</p>
                            <div style={{display: 'flex', alignItems: 'start', flexDirection: 'start' }}>
                                <Form.Item label="二维码" name="qrImg" >
                                    <Image
                                        width={150}
                                        height={150}
                                        src={googleKey}
                                    />
                                </Form.Item>
                                {/* <Form.Item label="Google验证码" name="qrCode" initialValue={user.googleKey || code}>
                                    <Input style={{ width: '100%' }} readOnly />
                                </Form.Item> */}
                            </div>

                            <p style={{color: '#777'}}>安装Google身份验证器</p>
                            <div style={{display: 'flex', justifyContent: 'start'}}>
                                <Form.Item label="Android" name="Android" >
                                    <Image
                                        width={150}
                                        height={150}
                                        src="https://upimg.haozigo.com/google/gg_android.png"
                                    />
                                </Form.Item>
                                <Form.Item label="IOS" name="IOS" >
                                    <Image
                                        width={150}
                                        height={150}
                                        src="https://upimg.haozigo.com/google/gg_ios.png"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Modal>
              
            </div>
        </Spin>
    )
}
