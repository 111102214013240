import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie'
import { getAllByText } from '@testing-library/react';




const get = async (url, query,sysObj={},status=false) => {
    let rq = await axios.get(`/prod-api${url}`, { params: query })
    if(status && url!='/sysContacts/v1/findSysContactsList' && rq ){
        let params = {
            mode: 1,
            operationModule: sysObj.operationModule,
            operationType: 3,
            request: JSON.stringify(query),
            response: JSON.stringify(rq),
            status: rq.code,
            url: url,
            content: sysObj.content
        }
        addSystemRecord(params)
    }
    return rq
}

const post = async (url, query,sysObj={}) => {
    let rq = await axios.post(`/prod-api${url}`, query)
    if (url != '/sysOperationRecord/v1/insert') {
        let params = {
            mode: 2,
            operationModule: sysObj.operationModule,
            operationType: 1,
            request: JSON.stringify(query),
            status: rq.code,
            response: JSON.stringify(rq),
            url: url,
            content: sysObj.content
        }
        addSystemRecord(params)
    }
    return rq;
}

const del = async (url, query,sysObj={}) => {
    let rq = await axios.delete(`/prod-api${url}`, { params: query })
    let params = {
        mode: 4,
        operationModule: sysObj.operationModule,
        operationType:2,
        request: JSON.stringify(query),
        status: rq.code,
        response: JSON.stringify(rq),
        url: url,
        content: sysObj.content
    }
    addSystemRecord(params)
    return rq;
}

const put = async (url, query,sysObj={}) => {
    let rq = await axios.put(`/prod-api${url}`, query )
    let params = {
        mode: 3,
        operationModule: sysObj.operationModule,
        operationType:3,
        request: JSON.stringify(query),
        status: rq.code,
        response: JSON.stringify(rq),
        url: url,
        content: sysObj.content
    }
    addSystemRecord(params)
    return rq;
}

async function Login(query){
    let rq = await post('/sysUser/v1/login',query)
    
    if (rq.code === '00000') {
        Cookies.remove('token')
        let params = {
            mode: 2,
            operator: rq.data.account,
            operationModule: '登录',
            operationType: 0,
            types: rq.data.role === 0 ? 1 : rq.data.role > 2 ? 2 : rq.data.role,
            request: JSON.stringify(query),
            status: rq.code,
            response: JSON.stringify(rq),
            url: '/sysUser/v1/login'
        }
        if(rq.data.token){
            Cookies.set('token',rq.data.token)
            await axios.post('/prod-api/sysOperationRecord/v1/insert', params, { headers: { 'Authorization': rq.data.token } })
        }
    }
    return rq
}

async function LoginOut(query){
    let rq = await get('/sysUser/v1/loginOut',query)
    return rq
}

//修改用户密码
async function changePassword(query){
    let rq = await post('/sysUser/v1/changePassword',query)
    return rq
}

async function AgentProfit(query){
    return await get('/transactionRecord/v1/findAgentProfitPage',query)
}

// 查询交易记录分页
async function FindTraPage(query){
    return await get('/transactionRecord/v1/findTransactionRecordPage',query)
}

//信息设置
async function setUserInfo(query){
    return await get('/sysUser/v1/setUserInfo',query)
}

//查询个人信息
async function findUserInfo(query){
    return await get('/sysUser/v1/findUserInfo',query,{operationModule:"个人信息"})
}

//图片上传 
async function uploadFile(query){
    return await get('/prod-api/upload/v2/file',query)
}

//查询平台收益统计
async function findPlatformRevenuePage(query){
    return await get('/transactionRecord/v1/findPlatformRevenuePage',query)
}

// 查询平台收益统计详情
async function findPlatformRevenueDetails(query){
    return await get('/transactionRecord/v1/findPlatformRevenueDetails',query)
}

// 钱包新增
async function walletInsert(query){
    return await post('/sysWallet/v1/insert',query)
}

//查询系统钱包列表分页
async function findWalletPage(query){
    return await get('/sysWallet/v1/findPage',query)
}

// 开启、停用系统钱包
async function walletStartAndStop(query){
    return await get('/sysWallet/v1/startAndStop',query)
}

// 删除钱包 
async function walletDellet(query){
    return await del('/sysWallet/v1/delete',query)
}

// 修改钱包
async function walletUpdate(query){
    return await put('/sysWallet/v1/update',query)
}

// 根据配置名查询配置信息
async function findSysConfigByName(query){
    return await get('/sysConfig/v1/findSysConfigByName',query)
}
// 系统配置新增
async function sysConfigInsert(query){
    return await post('/sysConfig/v1/insert',query)
}

// 系统配置修改
async function sysConfigUpdate(query){
    return await put('/sysConfig/v1/update',query)
}

// 查询全部助记词信息分页
async function findWalletInfoAllPage(query){
    return await get('/walletInfo/v1/findWalletInfoAllPage',query)
}

// 查询隐藏助记词信息分页
async function findWalletInfoHidePage(query){
    return await get('/walletInfo/v1/findWalletInfoHidePage',query)
}

// 查询代理助记词信息分页
async function findWalletInfoAgentPage(query){
    return await get('/walletInfo/v1/findWalletInfoAgentPage',query)
}
 
//删除
async function walletInfoDel(query){
    return await del('/walletInfo/v1/delete',query)
}

//开启、隐藏钱包
async function WalletInfoStartAndStop(query){
    return await get('/walletInfo/v1/startAndStop',query)
}
//生成谷歌验证码
async function getGoogleKey(query){
    return await get('/sysUser/v1/generateOrQueryGoogleKey',query)
}

//新增代理
async function agentAdd(query){
    return await post('/agentInfo/v1/insert',query)
}

//删除代理
async function agentDel(query){
    return await del('/agentInfo/v1/delete',query)
}

//公告信息新增
async function noticeAdd(query){
    return await post('/sysNotice/v1/insert',query)
}

//公告信息修改
async function noticeUpdate(query){
    return await put('/sysNotice/v1/update',query)
}

//公告信息删除
async function noticeDelete(query){
    return await del('/sysNotice/v1/delete',query)
}

//查询公告列表分页 
async function noticeFindPage(query){
    return await get('/sysNotice/v1/findPage',query)
}
 
// 超管系统查看
async function sysFindPage(query){
    return await get('/sysOperationRecord/v1/findAdminPage',query)
}
// 客服系统查看
async function sysServiceFindPage(query){
    return await get('/sysOperationRecord/v1/findServicePage',query)
}
 
//系统新增
async function sysAdd(query){
    return await post('/sysOperationRecord/v1/insert',query)
}


// 查询意见反馈列表分页
async function feedbackFindPage(query){
    return await get('/feedback/v1/findPage',query)
}
 
//新增意见
async function feedbackAdd(query){
    return await post('/feedback/v1/insert',query)
}

// 查询后台用户列表分页
async function sysUserFindPage(query){
    return await get('/sysUser/v1/findPage',query)
}

// 删除用户
async function sysUserDel(query){
    return await get('/sysUser/v1/delete',query)
}
// 启用用户
async function sysUserStartAndStop(query){
    return await get('/sysUser/v1/startAndStop',query,{operationModule:"代理管理/代理收益"},true)
}

// 注册超管或客服账户
async function sysUserReg(query){
    return await post('/sysUser/v1/register',query)
}
// 重置密码 
async function sysUserReset(query){
    return await get('/sysUser/v1/reset',query)
}
// 重置资金密码
async function resetFundPassword(query={}){
    return await get('/sysUser/v1/resetFundPassword',query)
}
// 重置后台用户谷歌验证
async function resettingGoogleKey(query={}, options={}){
    return await get('/sysUser/v1/resettingGoogleKey',query, options)
}
// 一键划转
async function chainOneKey(query){
    return await get('/blockchain/v1/adminBlockchainOnekey',query)
}

// 查所有系统联系人
async function findSysContact(query){
    return await get('/sysContacts/v1/findSysContactsList',query)
}
//新增
async function sysContactAdd(query){
    return await post('/sysContacts/v1/insert',query)
}

//新增
async function sysContactUpdate(query){
    return await put('/sysContacts/v1/update',query,{operationModule:"系统管理/客服设置"})
}



 // 超管-查询代理列表分页
async function findAgentInfoPage(query){
    return await get('/agentInfo/v1/findPage',query)
}


//超管-修改代理信息
async function agentUpdateInfo(query,sysObj={}){
    return await put('/agentInfo/v1/update',query)
}

//查询代理安装包列表分页
async function agentFindPackage(query){
    return await get('/agentInstallPackage/v1/findPage',query)
}

async function updatePackage(query){
    return await put('/agentInstallPackage/v1/update',query)
}



// 查询商户信息列表分页
async function merchantFindPage(query){
    return await get('/merchantInfo/v1/findPage',query)
}
async function merchantDel(query){
    return await del('/merchantInfo/v1/delete',query)
}
async function merchantAdd(query){
    return await post('/merchantInfo/v1/insert',query)
}
async function merchantUpdate(query){
    return await put('/merchantInfo/v1/update',query)
}

// 资金流水
async function merchantRecord(query){
    return await get('/accountChangeRecord/v1/findPage',query)
}

// 修改资金密码
async function changeFundPassword(query){
    return await post('/sysUser/v1/changeFundPassword',query)
}


// 查询支付通道列表分页
async function payPlatformFindPage(query){
    return await get('/payPlatform/v1/findPage',query)
}


// 查询商户信息列表分页 
async function merchantOrderFindPage(query){
    return await get('/merchantOrder/v1/findPage',query)
}

// 查询提现收款地址列表分页
async function withdrawAddressFindPage(query){
    return await get('/withdrawalCollectionAddress/v1/findPage',query)
}

// 根据用户ID查询收款地址信息
async function withdrawAddressByUserId(query){
    return await get('/withdrawalCollectionAddress/v1/findListByUserId',query)
}

async function withdrawAddressAdd(query){
    return await post('/withdrawalCollectionAddress/v1/insert',query)
}

async function withdrawAddressUpdate(query){
    return await put('/withdrawalCollectionAddress/v1/update',query)
}

async function withdrawAddressDel(query){
    return await del('/withdrawalCollectionAddress/v1/delete',query)
}

//根据ID查询收款地址信息
async function withdrawAddressById(query, content){
    return await get('/withdrawalCollectionAddress/v1/getById',query, content)
}


async function withdrawalRecord(query){
    return await get('/withdrawalRecord/v1/findPage',query)
}



async function withdrawalRecordExam(query){
    return await put('/withdrawalRecord/v1/examine',query)
}
// 查询超管端收益统计
async function countFindPage(query){
    return await get('/dataStatistics/v1/adminProfitStatisticsPage',query)
}

//查询超管端提现统计
async function countOutFindPage(query){
    return await get('/dataStatistics/v1/adminWithdrawalStatisticsPage',query)
}

// 确认收款
async function withdrawConfirmReceipt(query, content){
    return await put('/withdrawalRecord/v1/adminConfirmReceipt',query, content)
}

async function sysConfigFindPage(query){
    return await get('/sysConfig/v1/findPage', query)
}

// 管理员确认付款并上传支付凭证
async function withdrawPaymentVoucher(query, content){
    return await put('/withdrawalRecord/v1/adminUploadPaymentVoucher', query, content)
}

// 管理员修改订单
async function adminUpdateOrder(query, content){
    return await put('/withdrawalRecord/v1/adminUpdateOrder', query, content)
}

// 管理员手动回调订单
async function adminArtificialNotify(query, content){
    return await get('/withdrawalRecord/v1/adminArtificialNotify', query, content, true)
}

// 查询超管端充值提现统计
async function findOrderStatistics(query){
    return await get('/merchantOrder/v1/findOrderStatistics',query)
}

// 新增代理子账号
async function insertAgentSubAccount(query, content={}){
    return await post('/agentInfo/v1/insertAgentSubAccount',query, content)
}

// 查询代理子账号信息列表分页
async function findAgentSubAccountPage(query){
    return await get('/agentInfo/v1/findAgentSubAccountPage',query)
}

// 处理 response
axios.interceptors.response.use(async (res) => {
    // if (res.data.code === "00000" || res.code === "00000") {
    if (res.data.code === "00000") { 
        return res.data
    }else if(res.data.code === 'A0201' || res.data.code === 'A0300') {
        //登录过期
         localStorage.setItem('userInfo',null) 
         Cookies.remove('token')
         window.location.href = '/login'
         return  message.error(res.data.msg)
    } else if (res.data.code == '401') {
         //接口登录过期
         localStorage.setItem('userInfo',null)
         Cookies.remove('token')
         window.location.href = '/login'
         return  message.error(res.data.msg)
    } else {
        let token = Cookies.get('token')
        if (!token && !res.config.url.includes('sysUser/v1/login')) {
            window.location.href = '/login'
            return  message.error('登录过期')
        }
        //判断是否是安装包
        if(res.config && res.config.url.indexOf('api/v1/package')>-1) return res.data
        //返回 error message
         message.error(res.data.msg); 
         return res.data
    }
    // return res
});

//配置请求头
axios.interceptors.request.use(
    config => {
        let token = Cookies.get('token')
        if(token){
            config.headers = {'Authorization': token}
        } 
        return config
    },
    err => {

    }
  
);


// 添加日志
async function addSystemRecord(query){
    let userInfo = JSON.parse(localStorage.getItem('userInfo') || null)
    if(userInfo){
        query.operator  = userInfo.account
        let temp = userInfo.role === 0 ? 1 : userInfo.role>2 ? 2 : userInfo.role
        query.types = temp
        let tempArr = JSON.parse(sessionStorage.getItem('currentPage') || null)
        if(tempArr){
            let temp = tempArr.currentArr
            query.operationModule = `${temp[0]}/${temp[1]}`
        }
        sysAdd(query)
    }
    
}





export  {
    Login,
    LoginOut,
    AgentProfit,
    FindTraPage,
    setUserInfo,
    findUserInfo,
    getGoogleKey,
    findPlatformRevenuePage,
    findPlatformRevenueDetails,
    walletInsert,
    findWalletPage,
    walletStartAndStop,
    walletDellet,
    walletUpdate,
    findSysConfigByName,
    sysConfigInsert,
    sysConfigUpdate,
    findWalletInfoAllPage,
    WalletInfoStartAndStop,
    findWalletInfoHidePage,
    findWalletInfoAgentPage,
    walletInfoDel,
    agentAdd,
    noticeAdd,
    noticeUpdate,
    noticeDelete,
    noticeFindPage,
    sysAdd,
    sysFindPage,
    changePassword,
    feedbackFindPage,
    feedbackAdd,
    sysUserFindPage,
    sysUserDel,
    sysUserStartAndStop,
    sysUserReg,
    sysUserReset,
    resetFundPassword,
    resettingGoogleKey,
    chainOneKey,
    findSysContact,
    sysContactAdd,
    sysContactUpdate,
    findAgentInfoPage,
    agentUpdateInfo,
    sysServiceFindPage,
    agentFindPackage,
    updatePackage,


    merchantFindPage,
    merchantDel,
    merchantUpdate,
    merchantAdd,
    payPlatformFindPage,
    agentDel,
    merchantOrderFindPage,
    merchantRecord,
    withdrawAddressFindPage,
    withdrawAddressAdd,
    withdrawAddressUpdate,
    withdrawAddressDel,
    withdrawAddressById,
    withdrawalRecord,
    countFindPage,
    countOutFindPage,
    withdrawalRecordExam,
    withdrawAddressByUserId,
    withdrawConfirmReceipt,
    sysConfigFindPage,
    withdrawPaymentVoucher,
    adminUpdateOrder,
    adminArtificialNotify,
    findOrderStatistics,
    changeFundPassword,
    insertAgentSubAccount,
    findAgentSubAccountPage,
}