import './userAgentAdd.css'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState, useEffect, useImperativeHandle } from 'react';
import { agentAdd } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, DatePicker, Drawer, Select, Switch, message,Radio,Row,Col  } from 'antd';
const { Option } = Select;
const { TextArea } = Input;








export const UserAgentAdd = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [states, setStates] = useState(0);

    const [id, setId] = useState();

    const [types, setTypes] = useState()

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState();

    const [address, setAddress] = useState()

    const[expirationTime,setExpirationTime] = useState()

    const[expenses,setExpenses] = useState({ERC:0,TRC:0,BTC:0})

    const[etcRate,setEtcRate] = useState()
    const[ercAddress,setErcAddress] = useState()

    const handleLoadingChange = (enable) => {
        setLoading(enable);
    };


    const [value, setValue] = useState(1);

    const onChange = (e) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };
  

    useEffect(() => {
        // async function getData() {
        //   let rq = await findPlatformRevenuePage({page:1,limit:10})
        //   setData(rq.data.records)
        // }
        // getData()
    }, [])


    const onFinishFun = async (values) => {
        values.expirationTime = expirationTime
        values.expenses = {
            ERC:etcRate
        }
        values.walletAddress = {
            ERC: ercAddress
        }
        values.expenses = JSON.stringify(values.expenses)
        values.walletAddress = JSON.stringify(values.walletAddress)
                console.log(values);
        let rq = null
        rq = await agentAdd(values)
        if (rq.code =="00000") {
            message.success({ content: '新增成功' })
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        // setType(null)
    };

    const handleChangeFun = (checked) => {
        if (checked) {
            setStates(0)
        } else {
            setStates(1)
        }
    };

    const findWalletFun = async (query) => {
        setLoading(true)
    }


    const editFun = async (record) => {
        setVisible(true)
        setTitle('编辑')
        setAddress(record.address)
        setStates(record.states)
        setId(record.id)
    }
    const onFormLayoutChange = ({expirationTime}) => {
        console.log(expirationTime);
      };

      const onTimeChange = (date, dateString) => {
        setExpirationTime(dateString)
      };

const etcRateChange = (e)=>{
    setEtcRate(e.target.value)
}

const addressFun = (e)=>{
    setErcAddress(e.target.value)
}



    return (
        <div className='agent-add-page'>
            <div className='' style={{width:'60%'}}>
                <Form
                    name="basic"
                    onFinish={onFinishFun}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelCol={{
                        span: 4,
                    }}
                    initialValues={{
                        address: address,
                        types: types,
                        states: states
                    }}>
                    <Form.Item
                        label="用户名称"
                        name="account"
                        rules={[
                            {
                                required: true,
                                message: '请输入用户名称!',
                            },
                        ]}>
                        <Input placeholder='请输入用户名称'  size='large' />
                    </Form.Item>
                    <Form.Item
                        label="用户密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入用户密码!',
                            },
                        ]}>
                        <Input.Password size='large'
                            placeholder="请输入用户密码"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="上级代理"
                        name="superiorUserId">
                        <Input placeholder='请输入上级代理' size='large'/>
                    </Form.Item>
                    <Form.Item
                        label="代理权限变更"
                        name="types" style={{textAlign:'left'}}>
                        <Radio.Group>
                            <Radio value={1}>基础</Radio>
                            <Radio value={2}>高级</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="点位设置">
                            <div style={{textAlign:'left',lineHeight:'40px'}}>
                            <Input.Group size="large">
                                <Row  align="middle" gutter={16}>
                                    <Col span={2} >ERC:</Col>
                                    <Col span={5}> <Input value={etcRate} onChange={(e)=>{etcRateChange(e)}} /></Col>
                                    <Col span={2}>TRC:</Col>
                                    <Col span={5}> <Input  /></Col>
                                    <Col span={2}>BTC:</Col>
                                    <Col span={5}> <Input  /></Col>
                                </Row>
                            </Input.Group>
                            </div>
                    </Form.Item>
                    <Form.Item
                        label="代理费"
                        name="agencyFee">
                        <Input placeholder='请输入代理费!' />
                    </Form.Item>
                    <Form.Item
                        label="到期时间"
                        style={{textAlign:'left'}}>
                        <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime  allowClear size='large' onChange={onTimeChange}/>
                    </Form.Item>
                    <Form.Item label="ERC钱包地址">
                        <Input placeholder='请输入钱包地址!' value={ercAddress} onChange={(e)=>{addressFun(e)}}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size='large' style={{ marginRight: '20px' }}>
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
