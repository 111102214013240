import React, { useState, useEffect, useImperativeHandle } from 'react';

import { findSysConfigByName, sysConfigInsert, sysConfigUpdate } from '../../public/js/api'

import { Space, Table, Tag, Button, Form, Input, DatePicker, Drawer, message, Row, Col, Spin,InputNumber  } from 'antd';

import './systemSet.css'
import {InfoCircleOutlined} from '@ant-design/icons'






export const SystemSet = () => {

    const [id, setId] = useState()
    const [delayTime, setDelayTime] = useState(0)
    const [hidingRate, setHidingRate] = useState(0)
    const [money, setMoney] = useState(0)
    const [loading, setLoading] = useState(false)
    const [flag,setFlag] = useState(false)

    useEffect(() => {
        async function getData() {
            setLoading(true)
            let rq = await findSysConfigByName({ configName: 'DISPLAYDELAYSETTING' })
            if (rq.code == "00000") {
                let temp = JSON.parse(rq.data.configContent)
                setId(rq.data.id)
                setDelayTime(temp.delayTime)
                setMoney(temp.money)
                setHidingRate(temp.hidingRate)
            }
            setLoading(false)
        }
        getData()
    }, [])

    const onFinish = async () => {
        if(flag) return
        let values = {
            delayTime: delayTime,
            money: money,
            hidingRate: hidingRate
        }
        let params = {
            configName: 'DISPLAYDELAYSETTING',
            configContent: JSON.stringify(values),
            id: id
        }
        setFlag(true)
        let rq = await sysConfigUpdate(params)
        if (rq.code == "00000") {
            message.success('修改成功!')
            setFlag(false)
        }
    };



    // const onReset = () => {
    //     // setDataTime(null)
    // };

    const timeChange = (value) => {
        setDelayTime(value)
    }
    const moneyChange = (value) => {
        setMoney(value)
    }
    const rateChange = (value) => {
        setHidingRate(value)
    }



    return (
        <div className='system-set-page'>
            <div className='agent-search-box' style={{ width: '600px', textAlign: 'left', paddingTop: '38px', paddingLeft: '50px' }}>
                <Spin spinning={loading}>
                    <div style={{ marginBottom: '30px' }}>
                        <Row align='middle'>
                            <Col span={6}><div className='s1'>设置延迟时间:</div></Col>
                            <Col span={18}><InputNumber placeholder='请设置延迟时间' min={0} style={{ width: '100%' }} size='large' value={delayTime} onChange={(e) => timeChange(e)} /></Col>
                        </Row>
                    </div>
                    <div style={{ marginBottom: '30px' }}>
                        <Row align='middle'>
                            <Col span={6} ><div className='s1'>超过:</div></Col>
                            <Col span={4}><InputNumber placeholder='请设置超过数值' min={0}  style={{ width: '100%' }}   size='large' value={money} onChange={(e) => moneyChange(e)} /></Col>
                            <Col span={3}><div className='s2'>的,隐藏</div></Col>
                            <Col span={4}><InputNumber placeholder='请设置隐藏数值' min={0}  style={{ width: '100%' }}  size='large' value={hidingRate} onChange={(e) => rateChange(e)} /></Col>
                            <Col span={1}><div className='s2'>%</div></Col>
                            <Col span={6}><div className='s3'><Space><InfoCircleOutlined />百分号请填写正整数</Space></div></Col>
                        </Row>
                    </div>
                    <div>
                        <Row justify='center'>
                            <Col span={7} offset={6}>
                                <Button type="primary" size='large' block onClick={() => { onFinish() }}>
                                    确定
                                </Button>
                            </Col>
                            {/* <Button  size='large' style={{marginLeft:'10px'}}>
                            取消
                        </Button> */}
                        </Row>
                    </div>
                </Spin>
            </div>
        </div>
    )
}
