import './merchantList.css'
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { merchantFindPage, merchantDel, merchantAdd, merchantUpdate, sysUserReset, payPlatformFindPage, findAgentInfoPage, findUserInfo } from '../../public/js/api'

import { Space, Table, Switch, Button, Form, Input, message, Divider, 
    Drawer, Radio, Row, Col, Checkbox, InputNumber, Tag, Spin, Select, Modal } from 'antd';


import md5 from 'md5';

import SearchInput from '../SearchInput';

const { Option } = Select;




export const MerchantList = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
        position: ['bottomCenter']
    });

    const [title, setTitle] = useState()


    const [agentId, setAgentId] = useState()

    const [etcRate, setEtcRate] = useState()


    const [params, setParams] = useState({
        uuid: null,
        account: null,
        types: 1,

    })


    const [RainBowPayIndia, setRainBowPayIndia] = useState(null)
    const [FastPayVietnam2, setFastPayVietnam2] = useState(null)
    const [FastPayVietnam1, setFastPayVietnam1] = useState(null)
    const [FastPayIndia, setFastPayIndia] = useState(null)
    const [GoPayIndia, setGoPayIndia] = useState(null)
    const [collect, setCollect] = useState([])
    const [payment, setPayment] = useState([])

    const [types, setTypes] = useState(1)


    const [status, setStatus] = useState(1)

    const [setting, setSetting] = useState(false)

    const [agentOptions, setAgentOpts] = useState([])

    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await merchantFindPage({ 
            page: params.current, 
            limit: params.pageSize, 
            merchantName: params.merchantName,
            status: params.status
        })
        if (rq.code == '00000') {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                pageSize: rq.data.size,
                total: rq.data.total
            }))
            setCollect([])
            setPayment([])
            setTypes(null)
            setFastPayIndia(null)
            setRainBowPayIndia(null)
            setFastPayVietnam1(null)
            setFastPayVietnam2(null)
        }
        setLoading(false)
    }, [])



    useEffect(() => {
        getData(pagination)
    }, [getData])


    const findAgentInfoPageFn = async (params) => {
        let tempParams = {
            page: 1,
            limit: 50
        }
        if (params) {
            tempParams.agentName = params.agentName
        }
        let rq = await findAgentInfoPage(tempParams)
        if (rq.code == '00000') {
            const temp = rq.data.records.filter(el => el.status === 0)
            const data = temp.map((el) => ({
                text: el.name,
                value: el.userId,
            }))
            setAgentOpts(data)
        }
    }


    const onFinish = (values) => {
        let params = { current: 1, pageSize: 10 }
        if (account) params.merchantName = account
        if (values.status) params.status = values.status
        getData(params)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const changeFn = async (params) => {
        
        if (params.agentName) {
            setSetting(true)
            await findAgentByName(params.agentName)
            setSetting(false)
        } else await payFn(1)
        // 获取所有id
        findAgentInfoPageFn()
        setParams(params)
        setCollect([])
        setPayment([])
        try {
            let collectCurrencyRate = JSON.parse(params.currencyRateCollection)
            let paymentCurrencyRate = JSON.parse(params.currencyRatePayment)

            for (let item of collectCurrencyRate) {
                let name1 = Object.keys(item)[0]
                let _index = collect.findIndex(el => el === name1)
                if (_index > -1) { continue }
                if (name1 === 'RainBowPayIndia') {
                    form.setFieldsValue({
                        "rcp": item.RainBowPayIndia.percentage,
                    })
                    collect.push('RainBowPayIndia')
                } else if (name1 === 'FastPayVietnam2') {
                    form.setFieldsValue({
                        "vcg": item.FastPayVietnam2.gateway,
                        "vcm": item.FastPayVietnam2.momo,
                    })
                    collect.push('FastPayVietnam2')
                    if (item.FastPayVietnam2.gateway && item.FastPayVietnam2.momo) {
                        setValue4('gateway_momo')
                    } else if (item.FastPayVietnam2.gateway) {
                        setValue4('gateway')
                    } else if (item.FastPayVietnam2.momo) {
                        setValue4('momo')
                    }


                } else if (name1 === 'FastPayVietnam1') {
                    form.setFieldsValue({
                        "f1cg": item.FastPayVietnam1.gateway,
                        "f1cm": item.FastPayVietnam1.momo,
                    })
                    collect.push('FastPayVietnam1')

                    if (item.FastPayVietnam1.gateway && item.FastPayVietnam1.momo) {
                        setValue3('gateway_momo')
                    } else if (item.FastPayVietnam1.gateway) {
                        setValue3('gateway')
                    } else if (item.FastPayVietnam1.momo) {
                        setValue3('momo')
                    }


                }
                else if (name1 === 'FastPayIndia') {
                    form.setFieldsValue({
                        "fcp": item.FastPayIndia.percentage,
                    })
                    collect.push('FastPayIndia')
                }
            }


            for (let item of paymentCurrencyRate) {
                let name1 = Object.keys(item)[0]
                let _index = payment.findIndex(el => el === name1)
                if (_index > -1) { continue }
                if (name1 === 'RainBowPayIndia') {
                    form.setFieldsValue({
                        'rpp': item.RainBowPayIndia.percentage,
                        "rpa": item.RainBowPayIndia.amount
                    })
                    payment.push('RainBowPayIndia')
                }
                else if (name1 === 'FastPayVietnam2') {
                    form.setFieldsValue({
                        'vpp': item.FastPayVietnam2.percentage,
                    })
                    payment.push('FastPayVietnam2')
                }
                else if (name1 === 'FastPayVietnam1') {
                    form.setFieldsValue({
                        "f1pa": item.FastPayVietnam1.amount
                    })
                    payment.push('FastPayVietnam1')
                }
                else if (name1 === 'FastPayIndia') {
                    form.setFieldsValue({
                        'fpp': item.FastPayIndia.percentage,
                        "fpa": item.FastPayIndia.amount
                    })
                    payment.push('FastPayIndia')
                }
            }

        } catch (error) {

        }

        form.setFieldsValue({
            name: params.name,
            agentId: params.agentId,
            agentName: params.agentName,
            account: params.sysUser.account,
            contact: params.sysUser.contact,
            singleTransactionMaxWithd: params.singleTransactionMaxWithd,
            singleTransactionMinWithd: params.singleTransactionMinWithd,
            singleTransactionDayWithd: params.singleTransactionDayWithd,
            withdrawalExpense: params.withdrawalExpense
        })
        setAgentId(params.agentId)
        setIsModalVisible(true);
        setTitle('修改')
        setCollect(collect)
        setPayment(payment)

    }





    const columns = [
        {
            title: '商户Appkey',
            key: 'appkey',
            dataIndex: 'appkey',
        },
        {
            title: '商户名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '登录账号',
            dataIndex: 'sysUser',
            key: 'sysUser',
            render: (h) => {
                return h.account
            },
        },
        {
            title: '所属代理',
            dataIndex: 'agentName',
            key: 'agentName',
            render: (agentName) => (
                <Tag>
                    {
                        agentName ? agentName : '-'
                    }
                </Tag>
            )
        },
        // {
        //     title: '通道',
        //     render: () => {
        //         return <span>USDT</span>
        //     }
        // },
        // 首页的VND改成人民币，INR改成USDT
        {
            title: 'USDT余额',
            dataIndex: 'indiaBalance',
            key: 'indiaBalance',
        },
        {
            title: '人民币余额',
            dataIndex: 'vietnamBalance',
            key: 'vietnamBalance',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                <Space size="middle">
                    <Switch checked={record.status === 0 ? true : false} onChange={() => changeStatesFun(record, record.status)} />
                </Space>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, id) => (
                <Space size="middle">
                    <a onClick={() => changeFn(record)}>编辑</a>
                    <a onClick={() => {
                        setId(record.id)
                        setIsModalOpen(true)
                    }}>删除</a>
                    <a onClick={() => resetFun(record.userId)}>重置登录密码</a>
                    {/* <a onClick={() => resetFunPwd(record.userId)}>重置资金密码</a>
                    <a onClick={() => resetGoogleKey(record.userId)}>重置谷歌验证码</a> */}
                </Space>
            ),
        },
    ];


    const [isModalVisible, setIsModalVisible] = useState(false);

    const clearTextFn = () => {
        setCollect([])
        setPayment([])
        setIsModalVisible(true);
        setTypes(null)
        setFastPayIndia(null)
        setRainBowPayIndia(null)
        setFastPayVietnam1(null)
        setFastPayVietnam2(null)
        form.setFieldsValue({
            agentId: null,
            agentName: null,
            name: null,
            account: null,
            password: null,
            contact: null,
            currencyRatePayment: null,
            currencyRateCollection: null,
            singleTransactionMaxWithd: null,
            singleTransactionMinWithd: null,
            singleTransactionDayWithd: null
        })
    }

    const showModal = () => {
        // 先清空参数
        clearTextFn()
        setTimeout(() => {
            findAgentInfoPageFn()
            setTitle('新增')
            payFn(1)
        }, 300)
    };


    const handleOk = async (e) => {
        try {
            await form.validateFields();
            let temp = form.getFieldValue()

            // if (collect.length < 1) {
            //     return message.error({ content: '请选择提现通道配置' })
            // }
            // if (payment.length < 1) {
            //     return message.error({ content: '请选择充值通道配置' })
            // }


            if (title === '新增') {
                let sysUser = {
                    account: temp.account,
                    contact: temp.contact,
                    password: md5(temp.password),
                    states: 0,
                    role: 5 // 商户
                }
                delete temp.account
                delete temp.password
                delete temp.contact
                temp.sysUser = sysUser
            }
            temp.agentId = agentId
            temp.status = 0 // 默认正常



            let c_arr = []
            let p_arr = []
            for (let item of collect) {
                if (item === 'RainBowPayIndia') {
                    if (RainBowPayIndia.collectCurrencyRate) {
                        let objs = { "RainBowPayIndia": Object.assign(RainBowPayIndia.collectCurrencyRate, { percentage: temp.rcp }) }
                        c_arr.push(objs)
                    }
                } else if (item === 'FastPayIndia') {
                    if (FastPayIndia.collectCurrencyRate) {
                        let objs = { "FastPayIndia": Object.assign(FastPayIndia.collectCurrencyRate, { percentage: temp.fcp }) }
                        c_arr.push(objs)
                    }
                } else if (item === 'GoPayIndia') {
                    if (GoPayIndia.collectCurrencyRate) {
                        let objs = { "GoPayIndia": Object.assign(GoPayIndia.collectCurrencyRate, { percentage: temp.gcp, amount: temp.gca }) }
                        c_arr.push(objs)
                    }
                } 
                else if (item === 'FastPayVietnam2') {
                    if (FastPayVietnam2.collectCurrencyRate) {
                        let gateWay_momo = {
                            type: FastPayVietnam2.collectCurrencyRate.type
                        }
                        if (value4 === 'gateway') {
                            gateWay_momo.gateway = temp.vcg
                        } else if (value4 === 'momo') {
                            gateWay_momo.momo = temp.vcm
                        } else if (value4 === 'gateway_momo') {
                            gateWay_momo.gateway = temp.vcg
                            gateWay_momo.momo = temp.vcm
                        }
                        let objs = { "FastPayVietnam2": gateWay_momo }
                        // let objs = { "FastPayVietnam2": Object.assign(FastPayVietnam2.collectCurrencyRate, { gateway: temp.vcg, momo: temp.vcm }) }
                        c_arr.push(objs)
                    }
                } else if (item === 'FastPayVietnam1') {
                    if (FastPayVietnam1.collectCurrencyRate) {
                        let gateWay_momo = {
                            type: FastPayVietnam1.collectCurrencyRate.type
                        }
                        if (value3 === 'gateway') {
                            gateWay_momo.gateway = temp.f1cg
                        } else if (value3 === 'momo') {
                            gateWay_momo.momo = temp.f1cm
                        } else if (value3 === 'gateway_momo') {
                            gateWay_momo.gateway = temp.f1cg
                            gateWay_momo.momo = temp.f1cm
                        }
                        let objs = { "FastPayVietnam1": gateWay_momo }
                        c_arr.push(objs)
                    }

                }
            }
            for (let item of payment) {
                if (item === 'RainBowPayIndia') {
                    if (RainBowPayIndia.paymentCurrencyRate) {
                        let objs = { "RainBowPayIndia": Object.assign(RainBowPayIndia.paymentCurrencyRate, { percentage: temp.rpp, amount: temp.rpa }) }
                        p_arr.push(objs)
                    }
                } else if (item === 'FastPayIndia') {
                    if (FastPayIndia.paymentCurrencyRate) {
                        let objs = { "FastPayIndia": Object.assign(FastPayIndia.paymentCurrencyRate, { percentage: temp.fpp, amount: temp.fpa }) }
                        p_arr.push(objs)
                    }

                } else if (item === 'GoPayIndia') {
                    if (GoPayIndia.paymentCurrencyRate) {
                        let objs = { "GoPayIndia": Object.assign(GoPayIndia.paymentCurrencyRate, { percentage: temp.gpp, amount: temp.fpa,amount:temp.gpa }) }
                        p_arr.push(objs)
                    }
                } else if (item === 'FastPayVietnam2') {
                    if (FastPayVietnam2.paymentCurrencyRate) {
                        let objs = { "FastPayVietnam2": Object.assign(FastPayVietnam2.paymentCurrencyRate, { percentage: temp.vpp }) }
                        p_arr.push(objs)
                    }
                } else if (item === 'FastPayVietnam1') {
                    if (FastPayVietnam1.paymentCurrencyRate) {
                        let objs = { "FastPayVietnam1": Object.assign(FastPayVietnam1.paymentCurrencyRate, { amount: temp.f1pa }) }
                        p_arr.push(objs)
                    }
                }
            }

            temp.currencyRateCollection = JSON.stringify(c_arr)
            temp.currencyRatePayment = JSON.stringify(p_arr)


            delete temp.rpp
            delete temp.rpa
            delete temp.rcp

            delete temp.fpp
            delete temp.fpa
            delete temp.fcp

            delete temp.vpp
            delete temp.vcg
            delete temp.vcm

            delete temp.f1pa
            delete temp.f1cg
            delete temp.f1cm

            delete temp.gpa
            delete temp.gpp
            delete temp.gcp
            delete temp.gca


            setLoading(true)
            let rq = null
            if (title === '新增') {
                rq = await merchantAdd(temp)
            } else {
                temp.id = params.id
                rq = await merchantUpdate(temp)
            }
            if (rq.code == "00000") {
                message.success({ content: `${title}成功` })
                getData({ current: pagination.current, account: account, pageSize: 10 })
                setIsModalVisible(false);
            } else {
                clearTextFn()
            }
        } catch (error) {
            // setCollect([])
            // setPayment([])
            console.log(error)
        }

    };

    const handleCancel = () => {
        // form.resetFields();
        setIsModalVisible(false);
        setCollect([])
        setPayment([])
    };

    const resetFun = async (id) => {
        setLoading(true)
        let rq = await sysUserReset({ id: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '密码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }
    const resetFunPwd = async (id) => {
        setLoading(true)
        let rq = await resetFundPassword({ id: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '密码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }
    const resetGoogleKey = async (id) => {
        setLoading(true)
        let rq = await resettingGoogleKey({ userId: id })
        setLoading(false)
        if (rq.code == "00000") {
            message.success({ content: '谷歌验证码重置成功' })
        } else {
            message.error({ content: rq.msg })
        }
    }



    const handleTableChange = (newPagination, filters, sorter) => {
        getData(newPagination)
    };


    const changeStatesFun = async (item, status) => {
        setLoading(true)
        let temp = status == 0 ? 1 : 0
        let tempObj = Object.assign(item, { status: temp })
        let rq = await merchantUpdate(tempObj)
        if (rq.code == "00000") {
            getData({ current: pagination.current, pageSize: 10 })
        }
    }


    const delFun = async (id) => {
        setLoading(true)
        let rq = await merchantDel({ id: id })
        if (rq.code == "00000") {
            getData({ current: pagination.current, pageSize: 10 })
            message.success('删除成功')
        } else {
            message.error(rq.msg ?? '删除失败')
        }
    }





    const serarchFun = (e) => {
        setAccount(e.target.value)
    }

    const handleChange = async (newValue, agent) => {
        setAgentId(newValue ? newValue : null)
        if (agent) {
            setSetting(true)
            await findAgentByName(agent.children)
            setSetting(false)
        } else {
            await payFn(1)
        }
        setCollect([])
        setPayment([])
    };

    const findAgentByName = async (agentName) => {
        let rq = await findAgentInfoPage({ agentName: agentName })
        if (rq.code === "00000") {
            try {
                await payFn(0, rq)
            } catch (error) {
            }
        }
    }

    const payFn = async (flag, _rq) => {
        let rq = null
        if (flag) {
            rq = await payPlatformFindPage()
            if (rq.code == "00000") {
                for (let item of rq.data.records) {
                    item.collectCurrencyRate = JSON.parse(item.collectCurrencyRate)
                    item.paymentCurrencyRate = JSON.parse(item.paymentCurrencyRate)
                    if (item.name === 'RainBowPayIndia') {
                        setRainBowPayIndia(() => { return item })
                        form.setFieldsValue({
                            "rcp": item.collectCurrencyRate.percentage,
                            'rpp': item.paymentCurrencyRate.percentage,
                            "rpa": item.paymentCurrencyRate.amount
                        })
                    }
                    else if (item.name === 'FastPayVietnam2') {
                        setFastPayVietnam2(() => {
                            return item
                        })
                        form.setFieldsValue({
                            "vcg": item.collectCurrencyRate.gateway,
                            "vcm": item.collectCurrencyRate.momo,
                            'vpp': item.paymentCurrencyRate.percentage,
                        })
                        if (item.collectCurrencyRate.gateway && item.collectCurrencyRate.momo) {
                            setValue4('gateway_momo')
                        } else if (item.collectCurrencyRate.gateway) {
                            setValue4('gateway')
                        } else if (item.collectCurrencyRate.momo) {
                            setValue4('momo')
                        }

                    }
                    else if (item.name === 'FastPayVietnam1') {
                        setFastPayVietnam1(item)
                        form.setFieldsValue({
                            "f1cg": item.collectCurrencyRate.gateway,
                            "f1cm": item.collectCurrencyRate.momo,
                            "f1pa": item.paymentCurrencyRate.amount
                        })

                        if (item.collectCurrencyRate.gateway && item.collectCurrencyRate.momo) {
                            setValue3('gateway_momo')
                        } else if (item.collectCurrencyRate.gateway) {
                            setValue3('gateway')
                        } else if (item.collectCurrencyRate.momo) {
                            setValue3('momo')
                        }
                    }
                    else if (item.name === 'FastPayIndia') {
                        setFastPayIndia(item)
                        form.setFieldsValue({
                            "fcp": item.collectCurrencyRate.percentage,
                            'fpp': item.paymentCurrencyRate.percentage,
                            "fpa": item.paymentCurrencyRate.amount
                        })
                    }
                    else if (item.name === 'GoPayIndia') {
                        setGoPayIndia(item)
                        form.setFieldsValue({
                            "gcp": item.collectCurrencyRate.percentage,
                            "gca": item.collectCurrencyRate.amount,
                            'gpp': item.paymentCurrencyRate.percentage,
                            "gpa": item.paymentCurrencyRate.amount
                        })
                    }
                }
            }
        } else {
            
            rq = _rq
            if (rq.code == "00000") {
                setRainBowPayIndia(null);
                setFastPayIndia(null);
                setFastPayVietnam1(null);
                setFastPayVietnam2(null);
                setGoPayIndia(null)

                for (let item of rq.data.records) {
                    const collectCurrencyRate = JSON.parse(item.currencyRateCollection)
                    const paymentCurrencyRate = JSON.parse(item.currencyRatePayment)
                    let RainBowPayIndiaObj = {}
                    let FastPayIndiaObj = {}
                    let FastPayVietnam2Obj = {}
                    let FastPayVietnam1Obj = {}
                    let GoPayIndiaObj = {}

                    for (let items of collectCurrencyRate) {
                        let name1 = Object.keys(items)[0]
                        if (name1 === 'RainBowPayIndia') {
                            RainBowPayIndiaObj['collectCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                "rcp": items.RainBowPayIndia.percentage,
                            })
                        }
                        else if (name1 === 'FastPayVietnam2') {
                            FastPayVietnam2Obj['collectCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                "vcg": items.FastPayVietnam2.gateway,
                                "vcm": items.FastPayVietnam2.momo,
                            })

                            if (items.FastPayVietnam2.gateway && items.FastPayVietnam2.momo) {
                                setValue4('gateway_momo')
                                setOptions4([
                                    {
                                        label: '网关扫码+Momo',
                                        value: 'gateway_momo',
                                    },
                                    {
                                        label: '网关扫码',
                                        value: 'gateway',
                                    },
                                    {
                                        label: 'Momo',
                                        value: 'momo',
                                    }
                                ])
                            } else if (items.FastPayVietnam2.gateway) {
                                setValue4('gateway')
                                setOptions4([
                                    {
                                        label: '网关扫码',
                                        value: 'gateway',
                                    }
                                ])
                            } else if (items.FastPayVietnam2.momo) {
                                setValue4('momo')
                                setOptions4([
                                    {
                                        label: 'Momo',
                                        value: 'momo',
                                    }
                                ])
                            }

                        }
                        else if (name1 === 'FastPayVietnam1') {
                            FastPayVietnam1Obj['collectCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                "f1cg": items.FastPayVietnam1.gateway,
                                "f1cm": items.FastPayVietnam1.momo,
                            })

                            if (items.FastPayVietnam1.gateway && items.FastPayVietnam1.momo) {
                                setValue3('gateway_momo')
                                setOptions3([
                                    {
                                        label: '网关扫码+Momo',
                                        value: 'gateway_momo',
                                    },
                                    {
                                        label: '网关扫码',
                                        value: 'gateway',
                                    },
                                    {
                                        label: 'Momo',
                                        value: 'momo',
                                    }
                                ])
                            } else if (items.FastPayVietnam1.gateway) {
                                setValue3('gateway')
                                setOptions3([
                                    {
                                        label: '网关扫码',
                                        value: 'gateway',
                                    }
                                ])
                            } else if (items.FastPayVietnam1.momo) {
                                setValue3('momo')
                                setOptions3([
                                    {
                                        label: 'Momo',
                                        value: 'momo',
                                    }
                                ])
                            }
                        }
                        else if (name1 === 'FastPayIndia') {
                            FastPayIndiaObj['collectCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                "fcp": items.FastPayIndia.percentage,
                            })
                        } else if (name1 === 'GoPayIndia') {
                            GoPayIndiaObj['collectCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                "gcp": items.GoPayIndia.percentage,
                                "gca": items.GoPayIndia.amount,
                            })
                        }
                    }
                    for (let items of paymentCurrencyRate) {
                        let name1 = Object.keys(items)[0]
                        if (name1 === 'RainBowPayIndia') {
                            RainBowPayIndiaObj['paymentCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                'rpp': items.RainBowPayIndia.percentage,
                                "rpa": items.RainBowPayIndia.amount
                            })
                        }
                        else if (name1 === 'FastPayVietnam2') {
                            FastPayVietnam2Obj['paymentCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                'vpp': items.FastPayVietnam2.percentage,
                            })
                        }
                        else if (name1 === 'FastPayVietnam1') {
                            FastPayVietnam1Obj['paymentCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                "f1pa": items.FastPayVietnam1.amount
                            })
                        }
                        else if (name1 === 'FastPayIndia') {
                            FastPayIndiaObj['paymentCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                'fpp': items.FastPayIndia.percentage,
                                "fpa": items.FastPayIndia.amount
                            })
                        }
                        else if (name1 === 'GoPayIndia') {
                            GoPayIndiaObj['paymentCurrencyRate'] = items[name1]
                            form.setFieldsValue({
                                'gpp': items.GoPayIndia.percentage,
                                "gpa": items.GoPayIndia.amount
                            })
                        }
                    }
                    if (FastPayVietnam2Obj) {
                        setFastPayVietnam2(FastPayVietnam2Obj)
                    }
                    if (FastPayVietnam1Obj) {
                        setFastPayVietnam1(FastPayVietnam1Obj)
                    }
                    if (RainBowPayIndiaObj) {
                        setRainBowPayIndia(RainBowPayIndiaObj)
                    }
                    if (FastPayIndiaObj) {
                        setFastPayIndia(FastPayIndiaObj)
                    }
                    if (GoPayIndia) {
                        setGoPayIndia(GoPayIndiaObj)
                    }

                }
            }
        }

    }





    const changeTypesFn = (e, t, name) => {
        let isCancel = false
        if (t === 'collect') {
            if (e.target.checked) {
                // 判断是否存在
                if (!collect.find(el => el === name)) {
                    const c = collect.concat(name)
                    setCollect(c)
                }
            } else {
                const _index = collect.findIndex(el => el === name)
                collect.splice(_index, 1)
                setCollect(() => {
                    return [
                        ...collect
                    ]
                })
                isCancel = true
            }
        } else if (t === 'payment') {
            if (e.target.checked) {
                if (!payment.find(el => el === name)) {
                    const c = payment.concat(name)
                    setPayment(c)
                }
            } else {
                const _index = payment.findIndex(el => el === name)
                payment.splice(_index, 1)
                setPayment(() => {
                    return [
                        ...payment
                    ]
                })
                isCancel = true
            }
        }
        if (isCancel) {
            //还原
            // form.resetFields()
            if (name === 'RainBowPayIndia') {
                if (RainBowPayIndia.collectCurrencyRate) {
                    form.setFieldsValue({
                        "rcp": RainBowPayIndia.collectCurrencyRate.percentage,
                    })
                }
                if (RainBowPayIndia.paymentCurrencyRate) {
                    form.setFieldsValue({
                        'rpp': RainBowPayIndia.paymentCurrencyRate.percentage,
                        "rpa": RainBowPayIndia.paymentCurrencyRate.amount
                    })
                }
            }
            else if (name === 'FastPayVietnam2') {
                if (FastPayVietnam2.collectCurrencyRate) {
                    form.setFieldsValue({
                        "vcg": FastPayVietnam2.collectCurrencyRate.gateway,
                        "vcm": FastPayVietnam2.collectCurrencyRate.momo,
                    })
                }
                if (FastPayVietnam2.paymentCurrencyRate) {
                    form.setFieldsValue({
                        'vpp': FastPayVietnam2.paymentCurrencyRate.percentage,
                    })
                }
            }
            else if (name === 'FastPayVietnam1') {
                if (FastPayVietnam1.collectCurrencyRate) {
                    form.setFieldsValue({
                        "f1cg": FastPayVietnam1.collectCurrencyRate.gateway,
                        "f1cm": FastPayVietnam1.collectCurrencyRate.momo,
                    })
                }
                if (FastPayVietnam1.paymentCurrencyRate) {
                    form.setFieldsValue({
                        "f1pa": FastPayVietnam1.paymentCurrencyRate.amount
                    })
                }
            }
            else if (name === 'FastPayIndia') {
                if (FastPayIndia.collectCurrencyRate) {
                    form.setFieldsValue({
                        "fcp": FastPayIndia.collectCurrencyRate.percentage,
                    })
                }
                if (FastPayIndia.paymentCurrencyRate) {
                    form.setFieldsValue({
                        'fpp': FastPayIndia.paymentCurrencyRate.percentage,
                        "fpa": FastPayIndia.paymentCurrencyRate.amount
                    })
                }
            }
        }

    }

    const isCheckFn = (name) => {
        return collect.find(el => el === name) ? true : false
    }


    const isChecnkPFn = (name) => {
        return payment.find(el => el === name) ? true : false
    }

    const getUserInfo = async (ag) => {

    }


    const optionsTemp = [
        {
            label: '网关扫码+Momo',
            value: 'gateway_momo',
        },
        {
            label: '网关扫码',
            value: 'gateway',
        },
        {
            label: 'Momo',
            value: 'momo',
        }
    ];

    const onChange3 = ({ target: { value } }) => {
        setValue3(value);
    };
    const onChange4 = ({ target: { value } }) => {
        setValue4(value);
    };
    const [value3, setValue3] = useState();
    const [value4, setValue4] = useState();
    const [options3, setOptions3] = useState(optionsTemp);
    const [options4, setOptions4] = useState(optionsTemp);

    const handleSearch = (newValue) => {
        if (newValue) {
            findAgentInfoPageFn({ agentName: newValue })
        } else {
            setAgentOpts([])
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentId, setId] = useState()
    const deleteCurrent = () => {
        setIsModalOpen(false)
        if (currentId) delFun(currentId)
    }




    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    >
                        <Input placeholder="请输入商户名称" allowClear value={account} onChange={serarchFun} />
                    </Form.Item>
                    {/* <Form.Item name="status" >
                        <Select
                            style={{
                                width: 180,
                            }}  
                            allowClear
                            placeholder="选择用户状态"
                        >
                            <Option value="0">正常</Option>
                            <Option value="1">停用</Option>
                        </Select>
                    </Form.Item>   */}

                    <Form.Item
                    >
                        <Button type="primary" htmlType="submit" >
                            搜索
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" onClick={() => { showModal() }}>
                            新增
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
                pagination={pagination} loading={loading} onChange={handleTableChange} />

            <Drawer forceRender title={title} width={'60%'} visible={isModalVisible} onClose={handleCancel} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={handleOk} type="primary" >
                            确定
                        </Button>
                        <Button onClick={handleCancel} htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    autoComplete="off"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    <div className='tag-box'>基本信息</div>
                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="商户名称"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: '商户名称为空!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="所属代理"
                                name="agentName"
                                style={{ textAlign: 'left' }}
                            >
                                <Select allowClear placeholder="请输入所属代理" onChange={handleChange}>
                                    {
                                        agentOptions.map((d) => <Option key={d.value}>{d.text}</Option>)
                                    }
                                </Select>

                                {/* <SearchInput
                                    value={79}
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={handleChange}
                                /> */}
                            </Form.Item>
                        </Col>
                    </Row>


                    <div>
                        <Row type="flex">

                            <Col span='12'>
                                <Form.Item
                                    label="用户登录名"
                                    name="account"
                                    rules={[
                                        {
                                            required: true,
                                            message: '用户登录名为空',
                                        },
                                    ]}
                                >
                                    <Input disabled={title === '修改' ? true : false} />
                                </Form.Item>
                            </Col>
                            {
                                title === '新增' ?

                                    <Col span='12'>
                                        <Form.Item
                                            label="用户密码"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '用户密码为空',
                                                },
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    : null
                            }
                            <Col span='12'>
                                <Form.Item
                                    label="联系方式"
                                    name="contact"
                                    rules={[
                                        {
                                            required: true,
                                            message: '联系方式为空',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    <div className='tag-box'>分控配置</div>
                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="单笔最大提现金额"
                                name="singleTransactionMaxWithd"
                                rules={[
                                    {
                                        required: true,
                                        message: '单笔最大提现金额为空',
                                    },
                                ]}>
                                <InputNumber style={{ width: '100%' }} placeholder='请输入最大提现金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="单笔最小提现金额"
                                name="singleTransactionMinWithd"
                                rules={[
                                    {
                                        required: true,
                                        message: '单笔最小提现金额为空',
                                    },
                                ]}>
                                <InputNumber style={{ width: '100%' }} placeholder='请输入最小提现金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row type="flex">
                        <Col span='12'>
                            <Form.Item
                                label="单日提现最大金额"
                                name="singleTransactionDayWithd"
                                rules={[
                                    {
                                        required: true,
                                        message: '单日提现最大金额为空',
                                    },
                                ]}>
                                <InputNumber style={{ width: '100%' }} placeholder='请输入单日提现最大金额!' formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                            </Form.Item>
                        </Col>
                        <Col span='12'>
                            <Form.Item
                                label="提现费率(%)"
                                name="withdrawalExpense"
                                rules={[
                                    {
                                        required: true,
                                        message: '提现费率为空',
                                    },
                                ]}>
                                <InputNumber style={{ width: '100%' }} placeholder='请输入提现费率!' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            <Modal visible={isModalOpen} onOk={deleteCurrent} onCancel={() => setIsModalOpen(false)}>
              <p>确认删除该商户账号?</p>
            </Modal>
        </div>
    )
}
