import SearchInput from '../SearchInput';
import moment from 'moment';
import md5 from 'md5';
import { selectUser} from "../../app/store";
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useImperativeHandle, useCallback, useRef, useMemo } from 'react';

import { withdrawAddressFindPage, merchantDel, withdrawAddressUpdate, withdrawAddressAdd, withdrawAddressDel } from '../../public/js/api'

import {
    Space, Table, Radio, Button, Form, Input, message, Modal, Drawer, Select, Row, Col, Spin, Switch,
    DatePicker,
    Tag,
    Tooltip
} from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;








export const BankList = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(useSelector(selectUser));
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true,
        position: ['bottomCenter']
    });

    const [title, setTitle] = useState()


    const [agentId, setAgentId] = useState()

    const [etcRate, setEtcRate] = useState()


    const [params, setParams] = useState({
        uuid: null,
        name: null,
        types: 1,
    })

    const [types, setTypes] = useState(1)

    const getData = useCallback(async (params = {}) => {
        setLoading(true)
        let rq = await withdrawAddressFindPage({
            page: params.current,
            limit: params.pageSize,
            name: params.name
         })
        if (rq.code == '00000') {
            setData(rq.data.records)
            setPagination(Object.assign(pagination, {
                current: rq.data.current,
                pageSize: rq.data.size,
                total: rq.data.total
            }))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getData(pagination)
    }, [getData])




    const onFinish = (values) => {
        let params = {
          current: 1,
          pageSize: 10,
          name: values.name
        }
        getData(params)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const changeFn = (params) => {
        form.setFieldsValue({
            id: params.id,
            bankCard: params.bankCard,
            branchCode: params.branchCode,
            contact: params.contact,
            name: params.name,
            payeeAccount: params.payeeAccount,
            remarks: params.remarks,
            types: params.types,
        })
        setTypes(params.types)
        setIsModalVisible(true);
        setTitle('修改')
    }


    const columns = [
        {
            title: '角色',
            dataIndex: 'role',
            key: 'role',
            render: (text) => (
                <Space size="middle">
                    {
                        text === 0 ? <Tag>超管</Tag> :
                        text === 2 ? <Tag>客服</Tag> :
                        text === 3 ? <Tag>财务</Tag> :
                        text === 4 ? <Tag>代理</Tag> :
                            text === 5 ? <Tag>商户</Tag> : null
                    }
                </Space>
            ),
        },
        // {
        //     title: '代理名称',
        //     key:'agent'
        // },
        // {
        //     title: '商户名称',
        //     dataIndex: 'name',
        //     key: 'name',
        // },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '账号类型',
            dataIndex: 'types',
            key: 'types',
            render: (text) => (
                <Space size="middle">
                    {
                        text === 1 ? <Tag>银行卡</Tag> :
                            text === 2 ? <Tag>区块链钱包</Tag> : null
                    }
                </Space>
            ),
        },
        {
            title: '联系方式',
            dataIndex: 'contact',
            key: 'contact',
        },
        {
            title: '收款账号',
            dataIndex: 'payeeAccount',
            key: 'payeeAccount',
            width: 200,
            render: (text,record) => (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {
                        record.types === 1 ?
                            <Tooltip title={text} placement="topLeft">
                                {text}
                            </Tooltip>
                            :  <Tag>-</Tag>
                    }
              </div>
            ),
        },
        {
            title: '银行卡号',
            dataIndex: 'bankCard',
            key: 'bankCard',
            width: 200,
            render: (text) => (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                <Tooltip title={text} placement="topLeft">
                { text }
               </Tooltip>
              </div>
            ),
        },
        {
            title: '分行代码',
            dataIndex: 'branchCode',
            key: 'branchCode',
            align: 'center',
            render: (text) => (
                <Space size="middle" align="center">
                    {text ? text : '-'}
                </Space>
            ),
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '钱包地址',
            dataIndex: 'payeeAccount',
            key: 'payeeAccount',
            width: 200,
            render: (text,record) => (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {
                        record.types === 2 ?
                            <Tooltip title={text} placement="topLeft">
                                {text}
                            </Tooltip>
                            : <Tag>-</Tag>
                    }
              </div>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => (
                <Space size="middle">
                    <Switch checked={record.status === 0 ? true : false} onChange={() => changeStatesFun(record, record.status)} />
                </Space>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, id) => (
                <Space size="middle">
                    <a onClick={() => changeFn(record)}>编辑</a>
                    <a onClick={() => delFun(record.id)}>删除</a>
                </Space>
            ),
        },
    ];


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setTitle('新增')
        form.setFieldsValue({
            contact: null,
            bankCard: null,
            branchCode: null,
            status: 0,
            name: null,
            userId: userInfo.id,
            types:1, // 默认银行卡
            payeeAccount: null,
            remarks: null
        })
        setAgentId(null)
        setIsModalVisible(true);
    };

    const handleOk = async (e) => {
        let temp = form.getFieldValue()
        setLoading(true)
        let rq = null
        
        if (title === '新增') {
            rq = await withdrawAddressAdd(temp)
        } else {
            rq = await withdrawAddressUpdate(temp)
        }
        if (rq.code == "00000") {
            message.success({ content: `${title}成功` })
            getData({ current: pagination.current,pageSize: 10 })
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        // form.resetFields();
        setIsModalVisible(false);
    };

 

    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        getData(newPagination)
    };


    const changeStatesFun = async (item, status) => {
        setLoading(true)
        let temp = status == 0 ? 1 : 0
        let tempObj = Object.assign(item, { status: temp })
        let rq = await withdrawAddressUpdate(tempObj)
        if (rq.code == "00000") {
            getData({ current: pagination.current, pageSize: 10 })
        }
    }


    const delFun = async (id) => {
        setLoading(true)
        let rq = await withdrawAddressDel({ id: id })
        if (rq.code == "00000") {
            message.success({content:'删除成功!'})
            getData({ current: pagination.current, pageSize: 10 })
        }
    }



    const onTypesChange = (e) => {
        setTypes(e.target.value)
    };

    const serarchFun = (e) => {
        setAccount(e.target.value)
    }

    const handleChange = (newValue) => {
        setAgentId(newValue)
    };


    const onStatusChange = (value) => { };

    return (
        <div className='agent-page'>
            <div className='agent-search-box'>
                <Form
                    name="basic"
                    layout="inline"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name='name'>
                        <Input placeholder="请输入姓名"  allowClear />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >
                            搜索
                        </Button>
                    </Form.Item>
                    {/* <Form.Item>
                        <Button htmlType="submit"  onClick={()=>showModal()}>
                            新增
                        </Button>
                    </Form.Item> */}
                </Form>
            </div>
            <Table columns={columns} dataSource={data} rowKey={(res) => res.id}
                pagination={pagination} loading={loading} onChange={handleTableChange} />

            <Drawer title={title} width={'50%'} visible={isModalVisible} onClose={handleCancel} destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={handleOk} type="primary" >
                            确定
                        </Button>
                        <Button onClick={handleCancel}  htmlType='submit'>
                            取消
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    autoComplete="off"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    hideRequiredMark
                >
                    <Form.Item
                        label="账号类型"
                        name="types" style={{ textAlign: 'left' }} >
                        <Radio.Group onChange={onTypesChange}>
                            <Radio value={1}>银行卡</Radio>
                            <Radio value={2}>钱包地址</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="name">
                        <Input  />
                    </Form.Item>
                    <Form.Item
                        label="联系方式"
                        name="contact">
                        <Input  />
                    </Form.Item>
                    {
                        types === 1 ?
                            <div>
                                <Form.Item
                                    label="银行卡号"
                                    name="bankCard">
                                    <Input  />
                                </Form.Item>
                                <Form.Item
                                    label="分行代码"
                                    name="branchCode">
                                    <Input  />
                                </Form.Item>
                                <Form.Item
                                    label="收款账号"
                                    name="payeeAccount">
                                    <Input  /> 
                                </Form.Item>
                            </div>
                            : types === 2 ?

                                <Form.Item
                                    label="钱包地址"
                                    name="payeeAccount">
                                    <Input  />
                                </Form.Item>
                                : null
                    }
                    <Form.Item
                        label="转账描述"
                        name="remarks">
                         <TextArea rows={4} placeholder="请输入转账描述" />
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    )
}
